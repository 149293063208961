import {
  IconBathrooms,
  IconBedrooms,
  IconCentralAc,
  IconCoin,
  IconGarage,
  IconHeating,
  IconHomeType,
  IconParking,
  IconPriceRange,
  IconYearBuilt
} from "components/Svgs";
import { CRITERIA_MAP } from "containers/Dashboard/constants";

const COLUMN_SPAN = [8, 10];

const PROPERTY_INFO_FIRST_COLUMN_CRITERIA = [
  {
    key: CRITERIA_MAP.prop_type,
    criteriaTitle: "houseDetails.propertyType",
    icon: IconHomeType,
  },
  {
    key: CRITERIA_MAP.bedrooms,
    criteriaTitle: "houseDetails.bedrooms",
    icon: IconBedrooms,
    valueKey: "bedrooms.value",
  },
  {
    key: CRITERIA_MAP.bathrooms,
    criteriaTitle: "houseDetails.bathrooms",
    icon: IconBathrooms,
    valueKey: "bathrooms.value",
  },
  {
    key: CRITERIA_MAP.price,
    criteriaTitle: "houseDetails.price",
    icon: IconPriceRange,
    valueKey: "price.value",
  },
  {
    key: "price_per_sqft",
    criteriaTitle: "houseDetails.squareFootage",
    icon: IconCoin,
  },
];

const PROPERTY_INFO_SECOND_COLUMN_CRITERIA = [
  {
    key: "year_built",
    criteriaTitle: "houseDetails.yearBuilt",
    icon: IconYearBuilt,
  },
  {
    key: "cooling",
    criteriaTitle: "houseDetails.cooling",
    icon: IconCentralAc,
  },
  {
    key: "heating",
    criteriaTitle: "houseDetails.heating",
    icon: IconHeating,
  },
  {
    key: CRITERIA_MAP.parking,
    criteriaTitle: "houseDetails.parking",
    icon: IconParking,
    valueKey: "parking_features",
  },
  {
    key: CRITERIA_MAP.garage,
    criteriaTitle: "houseDetails.garage",
    icon: IconGarage,
    valueKey: "garage_features"
  },
];

const CRITERIA_COLUMNS = [
  PROPERTY_INFO_FIRST_COLUMN_CRITERIA,
  PROPERTY_INFO_SECOND_COLUMN_CRITERIA
];

export {
  COLUMN_SPAN,
  CRITERIA_COLUMNS,
};
