import React from "react";
import { Button } from "antd";
import classNames from "classnames";
import PropTypes from "prop-types";
import { IconCheckCriteria } from "components/Svgs";
import "../styles.scss";

const IconButton = props => {
  const { customClassName, isActive, isFill, leftIcon, highlightMode, message } = props;
  const isFilledButton = highlightMode === "button" && (isFill || isActive);
  const isFilledIcon = highlightMode === "icon" && isFill;
  return (
    <div className={customClassName || "icon-button-wrapper"}>
      <Button
        className={classNames("icon-button", { "button-fill": isFilledButton })}
        {...props}
      >
        <div className={classNames("icon-button-content", { "icon-check": highlightMode === "icon" && isActive })}>
          <div className={classNames("icon-button-content__icon", { "icon-fill": isFilledIcon })}>
            {leftIcon}
          </div>
          {isFill && (
            <div className="icon-button-content__badge-icon">
              <IconCheckCriteria />
            </div>
          )}
          {message && (
            <div className="icon-button-content__message">
              {message}
            </div>
          )}
        </div>
      </Button>
    </div>
  );
};

IconButton.propTypes = {
  customClassName: PropTypes.string,
  highlightMode: PropTypes.string,
  isActive: PropTypes.bool,
  isFill: PropTypes.bool,
  leftIcon: PropTypes.any,
  message: PropTypes.any,
};

IconButton.defaultProps = {
  highlightMode: "icon",
  isActive: false,
  isFill: false,
};

export default IconButton;
