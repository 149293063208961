import React, {useEffect, useState} from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  get as lodashGet,
  isEmpty
} from "lodash";
import { CRITERIA_MAP } from "containers/Dashboard/constants";
import "../styles.scss";
import {FormattedMessage} from "react-intl";


const GooglePlaces = ({isDisabled, isSaveInput, handleSelect, value, placeholder, types}) => {
  const [inputValue, setInputValue] = useState();

  useEffect(() => value && onSelect(value), [])

  const onChange = (value) => {
    setInputValue(value);
  };

  const onSelect = async (value) => {
    const geocode = await geocodeByAddress(value);
    const latLngCoordinates = await getLatLng(geocode[0]);
    const address = lodashGet(geocode[0], "formatted_address");
    const getLocationData = (dataToFind, geocode) => (
      geocode[0].address_components.filter(item => item.types.includes(dataToFind))
    );
    const city = getLocationData('locality', geocode);
    const state = getLocationData('administrative_area_level_1', geocode);
    const zip_code = getLocationData('postal_code', geocode);

    const location = {
      kind: CRITERIA_MAP.location,
      latitude: latLngCoordinates.lat,
      longitude: latLngCoordinates.lng,
      city: !isEmpty(city) ? city[0].short_name : null,
      state: !isEmpty(state) ? state[0].long_name : null,
      postal_code: !isEmpty(zip_code) ? zip_code[0].short_name : null,
      address,
    };
    handleSelect(location);
    setInputValue(isSaveInput ? address : '');
  };

  const renderSuggestion = (suggestion, getSuggestionItemProps) => {
    const className = suggestion.active
      ? "suggestion-item--active"
      : "suggestion-item";
    const style = suggestion.active
      ? { backgroundColor: "#fafafa", cursor: "pointer" }
      : { backgroundColor: "#ffffff", cursor: "pointer" };
    return (
      <div
        {...getSuggestionItemProps(suggestion, {
          className,
          style,
        })}
      >
        <span>{suggestion.description}</span>
      </div>
    );
  }

  return (
    <PlacesAutocomplete
      value={inputValue}
      onChange={onChange}
      onSelect={onSelect}
      searchOptions={{
        types,
        componentRestrictions: { country: ['us'] },
      }}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading,
      }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: placeholder,
              className: "location-search-input",
            })}
            disabled={isDisabled}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div><FormattedMessage id="dashboard.form.loadingMessage" /></div>}
            {suggestions.map((suggestion) => renderSuggestion(suggestion, getSuggestionItemProps))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default GooglePlaces;