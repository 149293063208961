import React from "react";
import { Form } from "antd";
import PropTypes from 'prop-types';
import InputField from "components/Fields/InputField";

import "./styles.scss";

const FIELDS_DATA = [
  { name: "first_name", maxLength: 50, titleMessage: "profile.form.firstName" },
  { name: "last_name", maxLength: 50, titleMessage: "profile.form.lastName" },
  { name: "email", maxLength: 50, titleMessage: "signUp.form.emailAddress" },
];

const NameForm = ({ formName, initialValues, onValuesChange, onFinish }) => {
  const [form] = Form.useForm();
  return (
    <Form
      autoComplete="off"
      className="name-form"
      form={form}
      initialValues={initialValues}
      name={formName}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
    >
      {FIELDS_DATA.map((field, index) => (
        <div className="name-field" key={`name-field-${index}`}>
          <InputField
            maxLength={field.maxLength}
            name={field.name}
            placeholderMessage={field.titleMessage}
            titleMessage={field.titleMessage}
          />
        </div>
      ))}
    </Form>
  );
};

NameForm.propTypes = {
  formName: PropTypes.string,
  initialValues: PropTypes.object,
  onFinish: PropTypes.func,
  onValuesChange: PropTypes.func,
};

export default NameForm;
