import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Tag, Tooltip, AutoComplete, Input } from "antd";
import PropTypes from "prop-types";
import {
  map as lodashMap,
  find as lodashFind,
  cloneDeep as lodashCloneDeep,
  remove as lodashRemove,
  debounce,
} from "lodash";
import { GEO_SEARCH_API_URL } from 'constants/apiUrls';
import { CRITERIA_MAP } from "containers/Dashboard/constants";
import { get } from 'utils/requests';
import "../styles.scss";
import "./styles.scss";

const MIN_SYMBOLS_FOR_GEO_SEARCH = 2;
const DEBOUNCE_TIME = 500
const REQUEST_TIMEOUT = 1000 * 10


const processGeoResult = (results) =>
  results.map(item => ({ value: item.address, label: item.address, ...item }))

// Get autocomplete results
const makeSearchQuery = (q) => 
  get(`${GEO_SEARCH_API_URL}autocomplete/?q=${q}`, {timeout: REQUEST_TIMEOUT})
  .then(results => processGeoResult(results.data))
  .catch(() => [])

// Get the full information about the place
// with coordinates
const makePlaceQuery = (gid) => 
  get(`${GEO_SEARCH_API_URL}places/?ids=${gid}`, {timeout: REQUEST_TIMEOUT})
  .then(results => results.data[0])
  .catch(() => {})

// Handler outside function to support debounce effect
const handleQueryTextChange = debounce(
  (query, setOptions, setIsLoading) => {
    makeSearchQuery(query).then((results) => {
      setOptions(results);
      setIsLoading(false);
    });
  }
, DEBOUNCE_TIME, false);

const SearchLocationField = ({ value = [], form, maxItemsNumber, onValuesChange }) => {
  const [locations, setLocations] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setLocations(value);
  }, []);

  const closeTag = (e, address) => {
    e.preventDefault();
    const newLocations = lodashCloneDeep(locations) || [];
    lodashRemove(newLocations, (item) => item.address === address);
    updateLocationsValue(newLocations);
  };

  const handleSelect = (_, location) => {
    setQuery('');

    if (!lodashFind(locations, (item) => item.address === location.address)) {
      makePlaceQuery(location.gid).then(item => {
        const newLocations = lodashCloneDeep(locations) || [];
        newLocations.push({ ...item, kind: CRITERIA_MAP.location });
        updateLocationsValue(newLocations);
      })
    }
  };

  const updateLocationsValue = locations => {
    form.setFieldsValue({ locations });
    setLocations(locations);
    if (_.isFunction(onValuesChange)) {
      onValuesChange({locations});
    }
  };

  const renderTags = () => (
    <div>
      {lodashMap(locations, (item) => (
        <Tooltip placement="bottomLeft" title={item.address} className="location-tag-tooltip">
          <Tag
            className="location-tag"
            closable
            onClose={(e) => closeTag(e, item.address)}
          >
            {item.address}
          </Tag>
        </Tooltip>
        ))}
    </div>
  );

  const handleChange = (value) => {
    setQuery(value);
  }

  const handleSearch = (query) => {
    if (query && query.length > MIN_SYMBOLS_FOR_GEO_SEARCH) {
      setIsLoading(true);
      handleQueryTextChange(query, setOptions, setIsLoading);
    }

    setOptions([]);
  }

  const getNoContentMessage = () => {
    if (isLoading) {
      return "loadingMessage";
    }

    if (query.length <= 2) {
      return "searchQueryTooShort";
    }

    return "noContent";
  }


  const renderNoContent = () => {
    const message = getNoContentMessage();
    return <FormattedMessage id={`autocomplete.${message}`} />
  }

  return (
    <div className="location-search location-search-custom">
      <div>
        <div className="location-search__input">
          <span className="location-search__label">
            <FormattedMessage id="dashboard.form.enterNeighborhoodMessage" />
          </span>
          <AutoComplete
            dropdownClassName="location-search-custom"
            disabled={locations.length >= maxItemsNumber}
            options={options}
            value={query}
            onChange={handleChange}
            onSelect={handleSelect}
            onSearch={handleSearch}
            notFoundContent={renderNoContent()}
          >
            <Input />
          </AutoComplete>
          <div className="location-search__tags">{renderTags()}</div>
        </div>
      </div>
    </div>
  );
};

SearchLocationField.propTypes = {
  form: PropTypes.any,
  maxItemsNumber: PropTypes.number,
  value: PropTypes.array,
  onValuesChange: PropTypes.func,
};

export default SearchLocationField;
