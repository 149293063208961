import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import _ from 'lodash';
import { Button, Spin } from "antd";
import queryString from 'query-string';
import { SimpleButton } from "components/Controls/Buttons";
import { observer, inject } from "mobx-react";
import { LOGIN_PATH } from "constants/paths";
import { trackEvent } from "utils/helpers/userHelper";
import {
  IconArrowRight,
  IconEmailConfirm,
  IconLetter,
  // IconLogo,
  IconExclamationPoint,
  IconClose,
} from "components/Svgs";
import { hihomeLogoBeta } from "images";
import { firebaseAuth } from 'utils/firebase';
import "antd/dist/antd.css";
import { ERRORS_MESSAGES } from 'constants/messages';
import HomePageButton from "../HomePageButton";
import EmailConfirmSuccess from "../EmailConfirmSuccess";

const EmailConfirm = observer(({ authStore }) => {
  const [errorMessageId, setErrorMessageId] = useState();
  const [confirmFinished, setConfirmFinished] = useState(false);
  const [userEmail, setUserEmail] = useState(authStore.retrieveEmail());
  const history = useHistory();

  useEffect(() => {
    const { oobCode } = queryString.parse(history.location.search);
    oobCode && checkCode(oobCode);
    return () => {
      // Widget should only be shown to registered users
      window.Intercom("shutdown");
    };
  }, [])

  const handleError = (error) => {
    setErrorMessageId(ERRORS_MESSAGES[error.code]);
  }

  const checkAndSendEmailLink = () => {
    const user = firebaseAuth.currentUser;
    if (user) {
      if (!user.emailVerified) {
        authStore.sendEmailVerificationFirebaseRequest(user, _, handleError);
      } else {
        setConfirmFinished(true);
      }
    } else {
      history.push(LOGIN_PATH);
    }
  }

  const checkCode = (oobCode) => {
    const handleSuccess = (userInfo) => {
      setUserEmail(userInfo.email);
      applyCode(oobCode);
      window.Intercom("boot", {
        app_id: process.env.REACT_APP_BASE_INTERCOM_APP_ID,
        email: userInfo.email,
      });
      trackEvent("success-signup", {
        email: userInfo.email,
        method: "email"
      });
    }
    authStore.checkActionCodeFirebaseRequest(oobCode, handleSuccess, handleError);
  }

  const applyCode = oobCode => {
    const handleSuccess = () => {
      setConfirmFinished(true);
    }
    authStore.applyVerificationFirebaseRequest(oobCode, handleSuccess, handleError);
  }

  const renderEmailConfirm = () => (
    <Spin spinning={authStore.loading}>
      <section className="login">
        <HomePageButton />
        <div className="login__left">
          <IconEmailConfirm />
        </div>
        <div className="login__right">
          {errorMessageId && (
            <span className="login__error-message">
              <IconExclamationPoint className="login__error-message__icon" />
              <FormattedMessage id={errorMessageId} />
              <Button onClick={() => setErrorMessageId(null)}>
                <IconClose />
              </Button>
            </span>
          )}
          <div className="logo">
            <Link to="/"><img src={hihomeLogoBeta} alt="hihome-logo-beta" width={186} height={57} /></Link>
            {/* <IconLogo width="170" height="52" /> */}
          </div>
          <div className="email-confirm-message">
            <FormattedMessage id="signUp.pleaseConfirmEmail" />
          </div>
          <div className="email-confirm-description">
            <FormattedMessage id="signUp.pleaseConfirmEmailDescription" values={{email: userEmail}} />
          </div>

          <div className="email-confirm-image">
            <IconLetter />
          </div>

          <div>
            <SimpleButton
              customClass="login__continue"
              onClick={checkAndSendEmailLink}
            >
              <FormattedMessage id="signUp.resendLink" />
              <IconArrowRight />
            </SimpleButton>
          </div>
        </div>
      </section>
    </Spin>
  )

  return confirmFinished ? <EmailConfirmSuccess userEmail={userEmail} /> : renderEmailConfirm();
});

export default inject("authStore")(EmailConfirm);
