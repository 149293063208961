import React from 'react';
import { Marker, OverlayView } from '@react-google-maps/api'
import { inject, observer } from "mobx-react";
import _, {
  get as lodashGet,
  map as lodashMap,
} from "lodash";
import { getColorForScore, getImageByScore } from "utils/helpers/housesHelper/common";
import { priceFormatter } from "utils/helpers/housesHelper/formatter";
import { IconMarkerFull } from "components/Svgs";
import { HOUSES_PER_PAGE } from "constants/houses";


const MapMarkers = observer(({ housesStore, handleOnClickHouse }) => {
  const housesData = housesStore.retrieveHousesData();
  const endPageIndex = housesStore.currentPage * HOUSES_PER_PAGE;

  const renderOverlayView = (item) =>
    <OverlayView
      position={{
        lat: lodashGet(item, 'address.location.lat'),
        lng: lodashGet(item, 'address.location.lon'),
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <div
        id={`marker${item.id}`}
        className="map-marker-container"
        style={{zIndex: item.total_score + 100}}
        onClick={() => handleOnClickHouse(item.id)}
        onMouseEnter={() => housesStore.setHoveredItem(item)}
        onMouseLeave={() => housesStore.setHoveredItem(null)}
      >
         <IconMarkerFull score={item.total_score} color={getColorForScore(item.total_score)} price={priceFormatter(item.price.value)} />
      </div>
    </OverlayView>


  const renderMarker = (item) =>
    <Marker
      id={item.id}
      icon={getImageByScore(item.total_score)}
      position={{
        lat: lodashGet(item, 'address.location.lat'),
        lng: lodashGet(item, 'address.location.lon'),
      }}
      onClick={() => handleOnClickHouse(item.id)}
      onMouseOver={() => housesStore.setHoveredItem(item)}
      onMouseOut={() => housesStore.setHoveredItem(null)}
      zIndex={item.total_score}
    />

  if (_.isEmpty(housesData.data)) {
    return null
  }

  return (
    <>
      {lodashMap(housesData.data.slice(endPageIndex - HOUSES_PER_PAGE, endPageIndex), item => renderOverlayView(item))}
      {lodashMap(housesData.data.slice(0, endPageIndex - HOUSES_PER_PAGE), item => renderMarker(item))}
      {lodashMap(housesData.data.slice(endPageIndex, housesData.length), item => renderMarker(item))}
    </>
  );
})

export default inject('housesStore')(MapMarkers);
