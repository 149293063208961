import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import { NameForm } from "components/Forms";
import { ONBOARDING_DATA_KEY } from "constants/localStorage";
import { getInitialUserValues } from "utils/helpers/userHelper";
import { onboardingFirstStepPhoto } from "images";

import "./styles.scss";

const WelcomeStep = (
  {
    formName,
    stepData,
    handleFormChange,
    onSubmitForm
  }
) => {
  const getInitialValues = () => {
    let initialValues;
    if (_.isEmpty(stepData)) {
      initialValues = getInitialUserValues(["first_name", "last_name", "email"], ONBOARDING_DATA_KEY, "data");
      handleFormChange(initialValues);
    } else {
      initialValues = { first_name: stepData.first_name, last_name: stepData.last_name, email: stepData.email };
    }
    return initialValues;
  };

  const renderImage = () => (
    <div className="welcome-step__image">
      <img
        src={onboardingFirstStepPhoto}
      />
    </div>
  );
  const onSubmitFormWrapper = (values) => {
    window.Intercom('update', {'name': values.first_name + " " + values.last_name});
    onSubmitForm(values)
  }

  const renderTitle = () => (
    <div className="welcome-step__title">
      <FormattedMessage id="onboarding.welcomeTitle" />
    </div>
  );

  return (
    <div className="welcome-step-container">
      {renderImage()}
      {renderTitle()}
      <NameForm
        formName={formName}
        initialValues={getInitialValues()}
        onFinish={onSubmitFormWrapper}
        onValuesChange={handleFormChange}
      />
    </div>
  );
};

WelcomeStep.propTypes = {
  formName: PropTypes.string,
  stepData: PropTypes.object,
  handleFormChange: PropTypes.func,
  onSubmitForm: PropTypes.func,
};

export default WelcomeStep;
