import {FormattedMessage} from "react-intl";
import React from "react";


export const CARD_TAG_MAP = {
  baths: <FormattedMessage id="houses.card.bath" />,
  beds: <FormattedMessage id="houses.card.beds" />,
  garage: <FormattedMessage id="houses.card.garage" />,
  parking: <FormattedMessage id="houses.card.parking" />,
  parks: <FormattedMessage id="houses.card.parks" />,
  central_ac: <FormattedMessage id="houses.card.ac" />,
  building_size: <FormattedMessage id="houses.card.building" />,
  school_quality: <FormattedMessage id="houses.card.school" />,
  basement: <FormattedMessage id="houses.card.basement" />,
  single_story: <FormattedMessage id="houses.card.single" />,
  newly_built: <FormattedMessage id="houses.card.newly" />,
  solar_power: <FormattedMessage id="houses.card.solar" />,
  commute: <FormattedMessage id="houses.card.commute" />,
  custom_distance: <FormattedMessage id="houses.card.distance" />,
  grocery: <FormattedMessage id="houses.card.grocery" />,
  low_crime: <FormattedMessage id="houses.card.crime" />,
  walkability: <FormattedMessage id="houses.card.walk" />,
  quietness: <FormattedMessage id="houses.card.quietness" />,
  flood_risk: <FormattedMessage id="houses.card.flood" />,
  transit: <FormattedMessage id="houses.card.transit" />,
  bikeability: <FormattedMessage id="houses.card.bike" />,
}
