import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Spin } from "antd";
import { HOUSE_VIEWS } from "constants/houses";
import ListCardView from "./ListHouseCard";
import MapMarkers from "./MapMarkers";
import MapRegions from './MapRegions';
import { inject, observer } from "mobx-react";
import { formatSearchParams } from "utils/helpers/searchHelper";
import _ from 'lodash';
import MapInfoWindow from "./MapInfoWindow";
import MapIsochrones from "./MapIsochrones";
import "../../styles.scss";


const GET_HOUSES_METHODS = {
  [HOUSE_VIEWS.topMatches]: 'getHousesMapRequest',
  [HOUSE_VIEWS.favorites]: 'getHousesFavoritesMapRequest'
};

// const DEFAULT_CENTER = new window.google.maps.LatLng(42.407211, -71.382439); // center of MA USA
const DEFAULT_ZOOM = 8;

const MapView = observer(({ getHousesData, criteriaStore, housesStore, handleFavorites, handleOnClickHouse, checkResultsExistence }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const DEFAULT_CENTER = new window.google.maps.LatLng(42.407211, -71.382439); // center of MA USA
  const [center, setCenter] = useState(DEFAULT_CENTER);

  useEffect(() => {
    getHousesData(_, true);
  }, []);

  const mapRef = useRef(null);
  const housesData = housesStore.retrieveHousesData();

  const getMapPosition = () => ({
    north_east: {
      lat: mapRef.current.getBounds().getNorthEast().lat(),
      lng: mapRef.current.getBounds().getNorthEast().lng(),
    },
    south_west: {
      lat: mapRef.current.getBounds().getSouthWest().lat(),
      lng: mapRef.current.getBounds().getSouthWest().lng(),
    }
  });

  const handleGetHouses = () => {
    if (mapRef.current && !housesStore.loading) {
      const criteriaData = criteriaStore.retrieveCriteriaData();
      const searchParams = { filters: formatSearchParams(criteriaData) };
      searchParams["filters"]["position"] = getMapPosition();
      const getHousesMethodName = GET_HOUSES_METHODS[housesStore.view]
      housesStore[getHousesMethodName](searchParams);
      housesStore.setCurrentPage(1);
    }
  };

  const handleLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (!_.isEmpty(housesData)) {
      housesData.data.forEach(({address}) =>
          bounds.extend({lat: address.location.lat, lng: address.location.lon})
      );
      map.fitBounds(bounds);
    }
    map.setOptions(
      {
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      }
    ) 
    mapRef.current = map;
  };

  const onDragAndZoom = () => {
    housesStore.handleTimeout(mapRef.current, handleGetHouses, isLoaded, setIsLoaded);
    if (mapRef.current){
      setCenter(mapRef.center || center);
      setZoom(mapRef.current.zoom || zoom);
    }
  }

  if (_.isEmpty(housesData) && housesStore.loading) {
    return null;
  }

  return (
    <div className="map-view-wrapper">
      <Spin spinning={criteriaStore.loading || housesStore.loading}>
        <div className="map-view" >
          <ListCardView
            criteriaStore={criteriaStore}
            handleFavorites={handleFavorites}
            handleOnClickHouse={handleOnClickHouse}
            checkResultsExistence={checkResultsExistence}
          />
          <div className="google-container-wrapper">
            <GoogleMap
              mapContainerClassName="google-container"
              onLoad={handleLoad}
              onDragEnd={onDragAndZoom}
              onZoomChanged={onDragAndZoom}
              options={{ mapId: process.env.REACT_APP_GOOGLE_MAP_ID }}
              zoom={zoom}
              center={center}
            >
              <MapMarkers handleOnClickHouse={handleOnClickHouse} />
              <MapInfoWindow />
              <MapRegions />
              <MapIsochrones />
            </GoogleMap>
          </div>
        </div>
      </Spin>
    </div>
  );
})

export default inject("criteriaStore", "housesStore", )(MapView);
