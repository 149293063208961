import {
  BathroomsForm,
  BedroomsForm,
  DistanceForm,
  GroceryForm,
  HomeTypeForm,
  LotSizeForm,
  ParksForm,
  PriceRangeForm,
  SquareFootageForm
} from "components/Forms";
import { CRITERIA_MAP } from "containers/Dashboard/constants";

const BASIC_CRITERIA_MODAL_DATA = {
  [CRITERIA_MAP.bathrooms]: {
    defaultValue: {},
    form: BathroomsForm,
    formName: "OnboardingBathroomsForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.bathroomsTitle"
  },
  [CRITERIA_MAP.bedrooms]: {
    defaultValue: {},
    form: BedroomsForm,
    formName: "OnboardingBedroomsForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.bedroomsTitle"
  },
  [CRITERIA_MAP.price]: {
    defaultValue: {},
    form: PriceRangeForm,
    formName: "OnboardingPriceForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.priceTitle"
  },
  [CRITERIA_MAP.prop_type]: {
    defaultValue: {},
    form: HomeTypeForm,
    formName: "OnboardingPropTypeForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.propTypeTitle"
  },
};

const OTHER_CRITERIA_MODAL_DATA = {
  [CRITERIA_MAP.building_size]: {
    defaultValue: {},
    form: SquareFootageForm,
    formName: "OnboardingBuildingSizeForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.criteriaModalCommonTitle",
    titleValue: "dashboard.form.squareFootage"
  },
  [CRITERIA_MAP.commute]: {
    defaultValue: {},
    form: DistanceForm,
    formName: "OnboardingCommuteForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.criteriaModalCommonTitle",
    titleValue: "dashboard.form.commute"
  },
  [CRITERIA_MAP.custom_distance]: {
    defaultValue: {},
    form: DistanceForm,
    formName: "OnboardingCustomDistanceForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.criteriaModalCommonTitle",
    titleValue: "dashboard.form.customDistanceTo"
  },
  [CRITERIA_MAP.grocery]: {
    defaultValue: {},
    form: GroceryForm,
    formName: "OnboardingGroceryForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.criteriaModalCommonTitle",
    titleValue: "dashboard.form.proximityToGrocery"
  },
  [CRITERIA_MAP.lot_size]: {
    defaultValue: {},
    form: LotSizeForm,
    formName: "OnboardingLotSizeForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.criteriaModalCommonTitle",
    titleValue: "dashboard.form.lotSize"
  },
  [CRITERIA_MAP.parks]: {
    defaultValue: {},
    form: ParksForm,
    formName: "OnboardingParksForm",
    subTitle: "onboarding.formSubTitle",
    title: "onboarding.criteriaModalCommonTitle",
    titleValue: "dashboard.form.proximityToParks"
  }
};

export { BASIC_CRITERIA_MODAL_DATA, OTHER_CRITERIA_MODAL_DATA };
