import React from 'react';
import { InfoWindow } from '@react-google-maps/api'
import { inject, observer } from "mobx-react";
import {
  get as lodashGet,
  isEmpty as lodashIsEmpty,
} from "lodash";
import HouseCard from "components/DataDisplay/HouseCard";


const MapInfoWindow = observer(({ housesStore }) => {
  const data = housesStore.retrieveHoveredItem();

  if (lodashIsEmpty(data)) {
    return null;
  }

  return (
    <div>
      <InfoWindow
        position={{
          lat: lodashGet(data, 'address.location.lat'),
          lng: lodashGet(data, 'address.location.lon'),
        }}
      >
        <HouseCard houseData={data} highestCriteria={[]} isMarker />
      </InfoWindow>
    </div>
  );
})

export default inject('housesStore')(MapInfoWindow);
