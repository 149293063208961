import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Tooltip, Spin } from "antd";
import classNames from "classnames";
import { IconHiHomeSmall, IconHeart } from "components/Svgs";
import _, {
  get as lodashGet,
  find as lodashFind,
  map as lodashMap,
} from "lodash";
import { SimpleButton } from "components/Controls/Buttons";
import CustomTable from "components/DataDisplay/CustomTable";
import ImageWithPreview from "components/DataDisplay/ImageWithPreview";
import { HOUSES_PER_PAGE, HOUSE_VIEWS } from "constants/houses";
import { TOKEN_KEY } from "constants/localStorage";
import { LOGIN_PATH } from "constants/paths";
import { getClassnameForScore } from "utils/helpers/housesHelper/common";
import {
  getAmountOfDaysOnHiHome,
  getClassnameForStatus,
  getFullAddress,
  getHouseParameterValue,
  getMethodForScore,
  getStatusValue
} from "utils/helpers/housesHelper/getter";
import { formatSearchParams } from "utils/helpers/searchHelper";
import { getCurrentPageNumber } from "utils/helpers/favoritesHelper";
import FavoritesHeader from "./FavoritesHeader";
import TopMatchesHeader from "./TopMatchesHeader";
import { COLUMNS, COLUMNS_TITLE_IN_ALPHABET_ORDER } from "../../constants";
import "../../styles.scss";
import {HOUSE_DETAILS_LOCATION} from "constants/paths";

const HEADERS = {
  [HOUSE_VIEWS.topMatches]: TopMatchesHeader,
  [HOUSE_VIEWS.favorites]: FavoritesHeader,
}

const GET_HOUSES_METHODS = {
  [HOUSE_VIEWS.topMatches]: 'getHousesRequest',
  [HOUSE_VIEWS.favorites]: 'getHousesFavoritesRequest'
}

const ListView = observer((
  { checkResultsExistence, criteriaStore, getHousesData, housesStore, handleFavorites, handleOnClickHouse }
) => {
  const [pagination, setPagination] = useState({});
  const housesData = housesStore.retrieveHousesData();
  const history = useHistory();

  useEffect(() => {
    const handleSuccess = responseData => {
      if (responseData.total_results === 0) {
        checkResultsExistence(false);
      } else {
        checkResultsExistence(true);
        setPagination({
          current: lodashGet(responseData, 'page'),
          pageSize: HOUSES_PER_PAGE,
          total: lodashGet(responseData, 'total_results'),
        });
      }
    };
    getHousesData(handleSuccess);
  }, []);

  const getSortedColumns = () => {
    let criteriaImportance = lodashGet(housesData, "criteria_importance", []);
    const criteriaData = criteriaStore.retrieveCriteriaData();
    // get the list with the info about criterion key, priority number, alphabet order number of the criterion
    let orderedCriteriaData = criteriaImportance.map(key => ({
      key,
      priority: lodashGet(criteriaData[key === "low_crime" ? "crime" : key], "priority", 0),
      alphabetOrderIndex: _.indexOf(COLUMNS_TITLE_IN_ALPHABET_ORDER, key)
    }));
    // sort criteria by: 1) priority (from the highest to the lowest) 2) alphabet (from A to Z if priorities are equal)
    orderedCriteriaData = orderedCriteriaData.sort((firstCriteria, secondCriteria) => {
      const isPriorityHigher = secondCriteria.priority - firstCriteria.priority;
      const isEarlierByAlphabet = firstCriteria.alphabetOrderIndex - secondCriteria.alphabetOrderIndex;
      return isPriorityHigher || isEarlierByAlphabet;
    });
    // get the list of the ordered criteria keys
    criteriaImportance = orderedCriteriaData.map(item => item.key);
    // get table columns according to the ordered criteriaImportance list
    const columns = COLUMNS(renderFirstColumn, renderColoredRow);
    const result = lodashMap(criteriaImportance, (item) =>
      lodashFind(columns, (column) => column.dataIndex === item)
    );
    result.unshift(columns[0]);
    return result;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const criteriaData = criteriaStore.retrieveCriteriaData();
    const searchFilters = formatSearchParams(criteriaData);
    const params = {
      filters: searchFilters,
      page: pagination.current,
      sorting: { field: sorter.field, order: sorter.order},
    };
    setPagination(pagination);
    const getHousesMethodName = GET_HOUSES_METHODS[housesStore.view];
    housesStore[getHousesMethodName](params);
  };

  const onClickFavorite = propertyId => {
    const handleSuccessUpdate = (isLastItemOnPage, isRemovedFromFavorites) => {
      const newPagination = _.cloneDeep(pagination);
      newPagination.current = getCurrentPageNumber(newPagination.current, housesData, isLastItemOnPage);
      if (isRemovedFromFavorites) {
        newPagination.total -= 1;
      }
      if (newPagination.total === 0) {
        const houseData = housesStore.retrieveHousesData();
        houseData.total_results = 0;
      }
      handleTableChange(newPagination, _, {});
    }
    if (!localStorage.getItem(TOKEN_KEY)) {
      history.push(LOGIN_PATH);
    } else {
      handleFavorites(propertyId, housesStore, handleSuccessUpdate);
    }
  };

  const renderAddToFavoritesButton = record => (
    <div className="favorites" onClick={e => e.stopPropagation()}>
      <Tooltip
        placement="bottomRight"
        title={<FormattedMessage id={record.is_favorite ? "houses.removeFavourite" : "houses.addToFavourite"} />}
      >
        <SimpleButton
          className={classNames("favorites-button", {
            active: record.is_favorite
          })}
          icon={<IconHeart />}
          onClick={() => onClickFavorite(record.id)}
        />
      </Tooltip>
    </div>
  );

  const renderAmountOfDaysOnHiHome = record => {
    const amountOfDaysOnHiHome = getAmountOfDaysOnHiHome(record);
    return (
    <div className="house-info__status-days">
      <Tooltip
        placement="bottomRight"
        title={
          <FormattedMessage
            id="houses.list.listedOnHiHome"
            values={{ number: amountOfDaysOnHiHome }}
          />
        }
      >
        <IconHiHomeSmall className="house-info__status-days-icon" />
        <FormattedMessage
          id="houses.list.daysMessage"
          values={{ number: amountOfDaysOnHiHome }}
        />
      </Tooltip>
    </div>);
  };

  const renderFirstColumn = record => {
    const colorClassname = getClassnameForScore(record.total_score);
    const statusClassname = getClassnameForStatus(record.listing_status);

    return (
      <div className="hiscore-column">
        <div className="hiscore-column__score">
          <div className={`score-container ${colorClassname}`}>
            <span>{record.total_score} </span>
          </div>
        </div>
        <div className="hiscore-column__house">
          <div className="house-photo" onClick={e => e.stopPropagation()}>
            <ImageWithPreview data={record} height={80} width={120} withRadius />
          </div>
          <div className="house-info">
            <a
              className="house-info__address"
              target="_blank"
              href={HOUSE_DETAILS_LOCATION(record.id)}
              rel="noopener noreferrer"
            >
              <span>{getFullAddress(record.address)}</span>
            </a>
            <div className="house-info__status">
              <div className={`status-item ${statusClassname}`}>
                {getStatusValue(record)}
              </div>
              {renderAmountOfDaysOnHiHome(record)}
            </div>
          </div>
        </div>
        {renderAddToFavoritesButton(record)}
      </div>
    );
  };


  const renderColoredRow = (value, criteria_values) => {
    const colorClassname = getMethodForScore(criteria_values[0], criteria_values[1], criteria_values[2])

    return (
      <div className={`row-value ${colorClassname}`}>
        {getHouseParameterValue(value)}
      </div>
    )
  };

  const renderHeader = () => {
    if (housesStore.view) {
      const HeaderComponent = HEADERS[housesStore.view];
      return <HeaderComponent />
    }
  };

  const renderTable = () => (
    <CustomTable
      className="list-view__table"
      dataSource={housesData.data}
      columns={getSortedColumns()}
      pagination={{
        ...pagination,
        showSizeChanger: false
      }}
      onChange={handleTableChange}
      scroll={{x: "max-content"}}
      onRow={(record) => ({onClick: () => handleOnClickHouse(record.id) })}
    />
  )

  return (
    <div className="list-view-wrapper">
      {renderHeader()}
      <div className="list-view">
        <Spin spinning={housesStore.loading}>
          {_.has(housesData, "data") && !_.isEmpty(housesData.data) && !housesStore.loading ? renderTable() : null}
        </Spin>
      </div>
    </div>
  );
});

export default inject("criteriaStore", "housesStore")(ListView);
