import React from "react";
import { FormattedMessage } from "react-intl";
import { TimelineForm } from "components/Forms";
import { ONBOARDING_DATA_KEY } from "constants/localStorage";
import { getInitialUserValues } from "utils/helpers/userHelper";

import "./styles.scss";

const TimelineStep = (
  {
    formName,
    stepData,
    handleFormChange,
    onSubmitForm
  }
) => {
  const getInitialValues = () => {
    let initialValues;
    if (_.isEmpty(stepData)) {
      initialValues = getInitialUserValues(["timeline"], ONBOARDING_DATA_KEY, "data");
      handleFormChange(initialValues);
    } else {
      initialValues = { timeline: stepData.timeline };
    }
    return initialValues;
  };

  const onSubmitFormWrapper = (values) => {
    onSubmitForm(values)
  }

  const renderTitle = () => (
    <div className="timeline-step__title">
      <div className="timeline-step__title__text">
        Timeline
      </div>
      <div className="timeline-subtitle">
        <div className="timeline_step-subtitle__text">
          In an ideal case, within what time frame would you like to move into your new home?
        </div>
      </div>
    </div>
  );

  return (
    <div className="timeline-step-container">
      {renderTitle()}
          <TimelineForm
              formName={formName}
              initialValues={getInitialValues()}
              onFinish={onSubmitFormWrapper}
              onValuesChange={handleFormChange}
            />
    </div>
  );
};

export default TimelineStep;
