import React from 'react';
import { FormattedMessage } from "react-intl";
import { inject } from "mobx-react";
import { SwitchButton } from "components/Controls/Buttons";
import { SWITCH_SETTINGS } from "containers/Houses/constants";
import "containers/Houses/styles.scss";

const Switcher = ({ handleActiveView, activeView }) => (
  <div className="switcher-wrapper">
    <div className="switcher">
      {SWITCH_SETTINGS.map(item => (
        <SwitchButton
          icon={item.icon}
          isActive={item.key === activeView}
          onClick={() => handleActiveView(item.key)}
        >
          <FormattedMessage id={item.message} />
        </SwitchButton>
      ))}
    </div>
  </div>
)

export default inject('criteriaStore')(Switcher);
