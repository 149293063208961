import React from 'react';
import { FormattedMessage } from "react-intl";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classNames from "classnames";
import { get as lodashGet, map as lodashMap } from "lodash";
import { Tooltip } from "antd";
import { SimpleButton } from "components/Controls/Buttons";
import ImageWithPreview from "components/DataDisplay/ImageWithPreview";
import { IconHeart, IconHiHome } from "components/Svgs";
import { getClassnameForScore, getValueClassnameByScoreRangeType } from "utils/helpers/housesHelper/common";
import { nFormatter, priceFormatter } from "utils/helpers/housesHelper/formatter";
import {
  getAmountOfDaysOnHiHome,
  getClassnameForStatus,
  getFullAddress,
  getMethodForScore,
  getStatusValue,
} from "utils/helpers/housesHelper/getter";
import { CARD_TAG_MAP } from "./constants";

import "./styles.scss";

const HouseCard = (props) => {
  const {
    activeFavorite,
    criteriaData,
    isActive,
    isMarker,
    highestCriteria,
    houseData,
    handleFavorites,
    handleOnClickHouse,
    setIsActive
  } = props;

  const getFirstRowCriteria = () => (
    <>
      <div className={`card-tag ${getValueClassnameByScoreRangeType(lodashGet(houseData, 'price.score'),lodashGet(houseData, 'price.value'))}`}>
        {"$" + priceFormatter(lodashGet(houseData, 'price.value'))}
      </div>
      <div className={`card-tag ${getValueClassnameByScoreRangeType(lodashGet(houseData, 'bedrooms.score'), lodashGet(houseData, 'bedrooms.value'))}`}>
        {lodashGet(houseData, 'bedrooms.value')} <FormattedMessage id="houses.card.beds" />
      </div>
      <div className={`card-tag ${getValueClassnameByScoreRangeType(lodashGet(houseData, 'bathrooms.score'),lodashGet(houseData, 'bathrooms.value'))}`}>
        {lodashGet(houseData, 'bathrooms.value')} <FormattedMessage id="houses.card.bath" />
      </div>
      {getSquareFootageValue()}
    </>
  );

  const getSecondRowCriteria = () => lodashMap(highestCriteria.slice(0,8), item => {
    const data = lodashGet(houseData, item, {});
    const criteriaKey = item === 'low_crime' ? 'crime' : item ;
    const priorityValue = _.has(criteriaData, criteriaKey) ? criteriaData[criteriaKey].priority : null;
    if (priorityValue) {
      if (!_.isNull(data)) {
        const dataClassname = (
          getMethodForScore(criteriaKey, data.score, data.value)
        );
        return (
          <div className={`card-tag ${dataClassname}`}>
            {CARD_TAG_MAP[item]}
          </div>
        );
      }
    }
    return null;
  });

  const getSquareFootageValue = () => {
    const buildingSizeValue = lodashGet(houseData, 'building_size.value');
    return (
      <div className={`card-tag ${getValueClassnameByScoreRangeType(lodashGet(houseData, 'building_size.score'),buildingSizeValue)}`}>
        {_.isNil(buildingSizeValue) ? (
          <FormattedMessage id="general.noData" />
        ) : (
          <>
            {nFormatter(buildingSizeValue, 1, false)}
            &nbsp;
            <span style={{ textTransform: "capitalize" }}>
              {`${lodashGet(houseData, 'building_size.units')}`}
            </span>
          </>
        )}
      </div>
    );
  };

  const onClickAccordion = e => {
    e.stopPropagation();
    setIsActive(!isActive);
  };

  const renderDays = () => {
    const amountOfDaysOnHiHome = getAmountOfDaysOnHiHome(houseData);
    return (
      <div className="status-days">
        <Tooltip
          placement="bottomRight"
          title={
            <FormattedMessage
              id="houses.list.listedOnHiHome"
              values={{ number: amountOfDaysOnHiHome }}
            />
          }
        >
          <div className="status-days__container">
            <IconHiHome />
            <div className="message">
              <FormattedMessage
                id="houses.list.daysMessage"
                values={{ number: amountOfDaysOnHiHome }}
              />
            </div>
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className="card-view-wrapper">
      <div className="card-view" onClick={() => handleOnClickHouse(houseData.id)}>
        <div className="card-view__score-inner">
          <div className="card-view__score">
            <div className={`score-container ${getClassnameForScore(houseData.total_score)}`}>
              <span>{houseData.total_score} </span>
            </div>
          </div>
        </div>
        <div className="card-view__photo" onClick={(e) => e.stopPropagation()}>
          <ImageWithPreview data={houseData} height={175} />
        </div>
        <div className="card-view__bottom-content">
          <div className="address">
            <div className="address-content bold">
              {getFullAddress(houseData.address)}
            </div>
            {!isMarker && (
              <div className="favorites" onClick={(e) => e.stopPropagation()}>
                <SimpleButton
                  className={classNames("favorites-button", { active: activeFavorite })}
                  icon={<IconHeart />}
                  onClick={handleFavorites}
                />
              </div>
            )}
          </div>
          {!isMarker && (
            <div className="status">
              <div className={`status-item ${getClassnameForStatus(houseData.listing_status)}`}>
                {getStatusValue(houseData)}
              </div>
              {renderDays()}
            </div>
          )}
          <div className="criteria">
            <div className={isActive ? "accordion is-active" : "accordion"}>
              <div className="accordion__top" onClick={onClickAccordion}>
                <div className="accordion__text">
                  {getFirstRowCriteria()}
                </div>
                {!isMarker && (
                  <>
                    <div className="accordion__block">
                      {lodashMap(getSecondRowCriteria(), item=>item)}
                    </div>
                    <div className="accordion__arrow">
                      <KeyboardArrowDownIcon />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HouseCard;
