import React, { useState } from "react";
import { Image } from 'antd';
import _ from "lodash";
import { formatPhotoURL } from "utils/helpers/housesHelper/formatter";
import CustomImage from "../CustomImage";
import "./styles.scss";

const ImageWithPreview = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const { data, ...otherProps } = props;

  return (
    <>
      <CustomImage
        image={_.get(data, "photos[0].href")}
        preview={{ visible: false }}
        onClick={() => setPreviewVisible(data.id)}
        {...otherProps}
      />
        <div style={{ display: 'none' }}>
          <Image.PreviewGroup
            preview={{
              visible: previewVisible === data.id,
              onVisibleChange: isVisibleValue => setPreviewVisible(isVisibleValue)
            }}
          >
            {_.map(data.photos, item => (
              <Image src={formatPhotoURL(item.href)} />
            ))}
          </Image.PreviewGroup>
        </div>
    </>
  );
};

export default ImageWithPreview;
