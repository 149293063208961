import React from "react";
import { Form, Radio, Space } from "antd";
import "../../styles.scss";

const OPTIONS = [
    { "value": "3 months", "label": "3 months" },
    { "value": "6 months", "label": "6 months" },
    { "value": "12 months", "label": "12 months" },
    { "value": "not sure", "label": "Not sure" },
]

const TimelineForm = ({ formName, initialValues, onValuesChange, onFinish }) => {
  const [form] = Form.useForm();
  return (
    <Form
      autoComplete="off"
      className="timeline-form"
      form={form}
      initialValues={initialValues}
      name={formName}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      >
        <Form.Item name="timeline">
          <Radio.Group name="radiogroup">
            <Space direction="vertical">
            {OPTIONS.map( (item, id) => (
                <Radio key={id} value={item.value}>
                {item.label}
              </Radio>
              ))}
              </Space>
          </Radio.Group>
        </Form.Item>
    </Form>
  );
};

export default TimelineForm;