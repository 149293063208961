import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "antd";
import classNames from "classnames";
import { FavoritesButton, SimpleButton } from "components/Controls/Buttons";
import { HouseScore } from "components/DataDisplay";
import { ScheduleTourButtonView } from "containers/HouseDetails/Views";
import { IconAngleLeft, IconHiHome } from "components/Svgs";
import { convertSqftToAcres } from "utils/helpers/criteriaHelper";
import { getValueClassnameByScore } from "utils/helpers/housesHelper/common";

import {
  getAmountOfDaysOnHiHome,
  getClassnameForStatus,
  getFullAddress,
  getPricePerSqftAndPrice,
  getPropertyTypeValue,
  getStatusValue
} from "utils/helpers/housesHelper/getter";
import "./styles.scss";
import {TOKEN_KEY} from "../../../../constants/localStorage";

const CRITERIA_PILLS_FOR_CARD = houseDetails => ([
  {key: 'price', message_id: null, score: _.get(houseDetails, "price.score"), valuesNumber: null, renderMethod: getPricePerSqftAndPrice },
  {key: 'bedrooms', message_id: 'houses.card.bedsValue', score: _.get(houseDetails, "bedrooms.score"), valuesNumber: _.get(houseDetails, "bedrooms.value"), renderMethod: null },
  {key: 'bathrooms', message_id: 'houses.card.bathValue', score: _.get(houseDetails, "bathrooms.score"), valuesNumber: _.get(houseDetails, "bathrooms.value"), renderMethod: null },
  {key: 'building_size', message_id: 'houses.card.buildingValue', score: _.get(houseDetails, "building_size.score"), valuesNumber: _.get(houseDetails, "building_size.value"), renderMethod: null },
  {key: 'lot_size', message_id: 'houses.card.lotSizeValue', score: _.get(houseDetails, "lot_size.score"), valuesNumber: convertSqftToAcres(_.get(houseDetails, "lot_size.value")), renderMethod: null },
]);

const TopBarView = ({ houseDetails, housesStore, isFixed, isPhotoCollageOpened, onReturnButtonClick }) => {
  const colorListingStatus = getClassnameForStatus(houseDetails.listing_status)

  const renderCriteriaPills = () => (
    <>
      {CRITERIA_PILLS_FOR_CARD(houseDetails).map(item => (
        <div className={`card-tag details-tag ${getValueClassnameByScore(item.score)}`}>
          {_.isNull(item.renderMethod) ? (
            <FormattedMessage id={item.message_id} values={{ number: item.valuesNumber }} />
          ) : (
            item.renderMethod(houseDetails)
          )}
        </div>
      ))}
    </>
  );

  const renderDays = () => {
    const amountOfDaysOnHiHome = getAmountOfDaysOnHiHome(houseDetails);
    return (
      <div className="days">
        <Tooltip
          placement="bottomRight"
          title={
            <FormattedMessage
              id="houses.list.listedOnHiHome"
              values={{ number: amountOfDaysOnHiHome }}
            />
          }
        >
          <IconHiHome />
          <FormattedMessage
            id="houses.list.daysMessage"
            values={{ number: amountOfDaysOnHiHome }}
          />
        </Tooltip>
      </div>
    )
  };

  const renderHouseInfo = () => (
    <div className="house-info-container">
      <div className="house-address">
        {getFullAddress(houseDetails.address)}
      </div>
      {renderMainInfo()}
    </div>
  );

  const renderMainInfo = () => (
    <div className="house-main-info-container" >
      {renderCriteriaPills()}
      <div className="property property__type">
        {getPropertyTypeValue(houseDetails.prop_type)}
      </div>
      <div className={classNames("property property__status", colorListingStatus)} >
        {getStatusValue(houseDetails)}
      </div>
      {renderDays()}
    </div>
  );

  const renderReturnButton = () => (
    <SimpleButton customClass="return-button" onClick={onReturnButtonClick}>
      <div className="return-button__icon">
        <IconAngleLeft />
      </div>
      <FormattedMessage id="houseDetails.returnToHomeListing" />
    </SimpleButton>
  );

  if (_.isNil(houseDetails)) {
    return null;
  }

  return (
    <div className={classNames("top-bar-container", { is_fixed: isFixed })}>
      <div className="top-bar-wrapper">
        {isPhotoCollageOpened && renderReturnButton()}
        <div className="top-bar">
          <div className="top-bar__left">
            <HouseScore score={localStorage.getItem(TOKEN_KEY) ? (
              houseDetails.total_score
            ) : (
              houseDetails.total_score || <FormattedMessage id="general.noData" />
            )} />
            {renderHouseInfo()}
          </div>
          <div className="top-bar__right">
            <div className="tour-btn-top-bar">
              <ScheduleTourButtonView houseDetails={houseDetails} />
            </div>
            <div className="favorites-btn">
              <FavoritesButton
                houseId={houseDetails.id}
                housesStore={housesStore}
                isFavorite={houseDetails.is_favorite}
                size="medium"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TopBarView.propTypes = {
  houseDetails: PropTypes.object,
  housesStore: PropTypes.any,
  isFixed: PropTypes.bool,
  isPhotoCollageOpened: PropTypes.bool,
  onReturnButtonClick: PropTypes.func,
};

export default TopBarView;
