import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

const CustomModal = props => {
  const { customClassName, footer, isClosable, isVisible, width, onCancel } = props;
  return (
    <Modal
      className={customClassName}
      closable={isClosable}
      footer={footer}
      visible={isVisible}
      width={width}
      onCancel={onCancel}
    >
      {props.children}
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.any,
  customClassName: PropTypes.string,
  footer: PropTypes.any,
  isClosable: PropTypes.bool,
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  width: PropTypes.number,
};

CustomModal.defaultProps = {
  footer: null,
  isClosable: false,
  isVisible: false,
  width: 400,
};

export default CustomModal;
