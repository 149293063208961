import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import _ from "lodash";
import { CRITERIA_MAP } from "containers/Dashboard/constants";
import {
  OTHER_CRITERIA_FIRST_ROW,
  // OTHER_CRITERIA_SECOND_ROW,
  // OTHER_CRITERIA_THIRD_ROW
} from "containers/Onboarding/config/buttonsConfig";
import { OTHER_CRITERIA_MODAL_DATA } from "containers/Onboarding/config/modalsConfig";
import { CriteriaButtonView, CriteriaModalView } from "containers/Onboarding/Views";
import "./styles.scss";

// CRITERIA_ROWS - the list of the rows which contain criteria buttons
const CRITERIA_ROWS = OTHER_CRITERIA_FIRST_ROW;

// CRITERIA_WITH_MODAL - the list of criteria for which the modal should appear to enable filling criteria form
const CRITERIA_WITH_MODAL = [
  CRITERIA_MAP.building_size,
  CRITERIA_MAP.commute,
  CRITERIA_MAP.custom_distance,
  CRITERIA_MAP.grocery,
  CRITERIA_MAP.lot_size,
  CRITERIA_MAP.parks
];

// ROW_CLASS_NAMES - the list of classNames for each row to enable setting the specific styles for each criteria row
const ROW_CLASS_NAMES = ["first-row", "second-row", "third-row"];

const OtherCriteriaStep = ({ dataKind, stepData, handleFormChange }) => {
  const [activeCriteria, setActiveCriteria] = useState(null);

  // This logic is not relevant now. Continue to process this criteria after customers clarification
  // const checkIsSaveDisabled = activeCriteria => {
  //   if ([CRITERIA_MAP.commute, CRITERIA_MAP.custom_distance].includes(activeCriteria)) {
  //     if (_.has(stepData, activeCriteria)) {
  //       if (_.has(stepData[activeCriteria], "location")) {
  //         return _.isEmpty(stepData[activeCriteria].location);
  //       }
  //       return true;
  //     }
  //     return true;
  //   }
  //   return false;
  // };

  const getModalTitle = (data, key, valueKey = null) => {
    const value = valueKey ? { value: <FormattedMessage id={data[valueKey]} /> } : null;
    return _.has(data, key) ? <FormattedMessage id={data[key]} values={value} /> : null;
  };

  const isShowModal = () => activeCriteria && CRITERIA_WITH_MODAL.includes(activeCriteria);

  const onClickCriteriaButton = (newActiveCriteria, criteriaKey) => {
    if (CRITERIA_WITH_MODAL.includes(criteriaKey)) {
      setActiveCriteria(newActiveCriteria);
    } else {
      const criteriaValue = _.isNil(stepData[criteriaKey]) ? { kind: criteriaKey, priority: 0 } : null;
      handleFormChange({ [criteriaKey]: criteriaValue });
    }
  };

  const onClose = () => setActiveCriteria(null);

  const onSave = formValues => {
    handleFormChange({ [activeCriteria]: formValues });
    onClose();
  };

  // const onValuesChange = formValues => handleFormChange(formValues);

  const renderCriteriaModal = () => {
    const modalData = OTHER_CRITERIA_MODAL_DATA[activeCriteria];
    const title = getModalTitle(modalData, "title", "titleValue");
    const subTitle = getModalTitle(modalData, "subTitle");
    return (
      <CriteriaModalView
        bodyContent={() => renderCriteriaModalBody(modalData)}
        formName={modalData.formName}
        // isSaveDisabled={checkIsSaveDisabled(activeCriteria)}
        isVisible={!_.isNull(activeCriteria)}
        title={title}
        subTitle={subTitle}
        onClose={onClose}
      />
    );
  };

  const renderCriteriaModalBody = criteriaData => {
    const Form = criteriaData.form;
    const initialData = stepData[activeCriteria] || criteriaData.defaultValue;
    return (
      <Form
        dataKind={dataKind}
        formName={criteriaData.formName}
        initialData={initialData}
        kind={activeCriteria}
        withPriority={false}
        onSubmitForm={onSave}
        // onValuesChange={onValuesChange}
      />
    );
  };

  const renderTitle = () => (
    <div className="other-criteria__title">
      <div className="other-criteria__title__text">
        <FormattedMessage id="onboarding.additionalCriteriaTitle" />
      </div>
      <div className="other-criteria-subtitle__text">
        <FormattedMessage id="onboarding.additionalCriteriaSubTitle" />
      </div>
    </div>
  );

  const renderOtherCriteria = () => (
    <div className="other-criteria-step__content">
      {CRITERIA_ROWS.map((item) => (
              <CriteriaButtonView
                activeCriteriaKey={activeCriteria}
                currentCriteria={item}
                stepData={stepData}
                changeActiveCriteria={onClickCriteriaButton}
              />
      ))}
    </div>
  );

  return (
    <div className="other-criteria-step-container">
      {renderTitle()}
      {renderOtherCriteria()}
      {isShowModal() && renderCriteriaModal()}
    </div>
  );
};

OtherCriteriaStep.propTypes = {
  dataKind: PropTypes.string,
  stepData: PropTypes.object,
  handleFormChange: PropTypes.func,
};

export default OtherCriteriaStep;
