import React from "react";
import { FormattedMessage } from "react-intl";
import CustomTooltip from "components/DataDisplay/CustomTooltip";
import { IconList, IconMap } from "components/Svgs";
import { CRITERIA_MAP } from "containers/Dashboard/constants";
import { normalizeNumber, convertSqftToAcres } from "utils/helpers/criteriaHelper";
import { getPriceValue, getValueOrNull, getFormattedValueOrNull, getBooleanValue, getBathsAndBeds } from "utils/helpers/housesHelper/getter";
import { checkItemPill } from "utils/helpers/housesHelper";

const COLUMNS_TITLE_IN_ALPHABET_ORDER = [
  CRITERIA_MAP.basement,
  CRITERIA_MAP.bathrooms,
  CRITERIA_MAP.bedrooms,
  CRITERIA_MAP.bikeability,
  CRITERIA_MAP.central_ac,
  CRITERIA_MAP.commute,
  CRITERIA_MAP.custom_distance,
  CRITERIA_MAP.garage,
  CRITERIA_MAP.lot_size,
  "low_crime",
  CRITERIA_MAP.flood_risk,
  CRITERIA_MAP.newly_built,
  CRITERIA_MAP.parking,
  CRITERIA_MAP.parks,
  CRITERIA_MAP.price,
  CRITERIA_MAP.grocery,
  CRITERIA_MAP.transit,
  CRITERIA_MAP.quietness,
  CRITERIA_MAP.school_quality,
  CRITERIA_MAP.single_story,
  CRITERIA_MAP.solar_power,
  CRITERIA_MAP.building_size,
  CRITERIA_MAP.walkability,
];

const SWITCHER_VIEW = {
  list: 'list',
  map: 'map',
  card: 'card',
}

const LISTING_STATUS_MESSAGES_MAPPINGS = {
  active: "houses.list.activeMessage",
  off_market: "houses.list.offMarketMessage",
  sold: "houses.list.soldMessage",
  under_contract: "houses.list.underContractMessage"
};


const SWITCH_SETTINGS = [
  { key: 'list', icon: <IconList />, message: "houses.switcher.listViewMessage" },
  { key: 'map', icon : <IconMap />, message: "houses.switcher.mapViewMessage" },
  // { key: 'card', icon: <IconCards />, message: "houses.switcher.cardViewMessage" },
];

const COLUMNS = (renderFirstColumn, renderColoredRow, address) => [
  {
    title:(
      <CustomTooltip title={<FormattedMessage id="houses.list.hiScoreTooltipMessage" />}>
        <span>
          <FormattedMessage id="houses.list.hiScoreMessage" />
        </span>
      </CustomTooltip>
    ),
    dataIndex: "score",
    className: "hiscore-column__col",
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    showSorterTooltip: {},
    render: (_, record) => renderFirstColumn(record),
  },
  {
    title: <FormattedMessage id="houses.list.priceMessage" />,
    dataIndex: "price",
    className: "price-column",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 160,
    render: (item) => {
      const value = getValueOrNull(item) ? getPriceValue(item.value)
      : '-';
      const pillData = checkItemPill(item, CRITERIA_MAP.price);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.bedroomsMessage" />,
    dataIndex: "bedrooms",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getBathsAndBeds(item, 'bedrooms');
      const pillData = checkItemPill(item, CRITERIA_MAP.bikeability);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.bathroomsMessage" />,
    dataIndex: "bathrooms",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getBathsAndBeds(item, 'bathrooms')
      const pillData = checkItemPill(item, CRITERIA_MAP.bathrooms);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.squareFeetMessage" />,
    dataIndex: "building_size",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) && <FormattedMessage id="houses.card.buildingValue" values={{number: normalizeNumber(item.value)}}/>;
      const pillData = checkItemPill(item, CRITERIA_MAP.building_size);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.lotSizeMessage" />,
    dataIndex: "lot_size",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) && <FormattedMessage id="dashboard.form.acreShort" values={{number: convertSqftToAcres(item.value)}} />;
      const pillData = checkItemPill(item, CRITERIA_MAP.lot_size);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.garageMessage" />,
    dataIndex: "garage",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.garage);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.parkingMessage" />,
    dataIndex: "parking",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.parking);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.centralACMessage" />,
    dataIndex: "central_ac",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.central_ac);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.basementMessage" />,
    dataIndex: "basement",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.basement);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.singleStoryMessage" />,
    dataIndex: "single_story",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
   render: (item) => {
      const value = item && getFormattedValueOrNull(item.value, getBooleanValue);
      const pillData = checkItemPill(item, CRITERIA_MAP.single_story);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.solarPowerPotentialMessage" />,
    dataIndex: "solar_power",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    // temporary show N/A for this column
    render: () => renderColoredRow(null, -1, CRITERIA_MAP.solar_power, null)
    // render: (item) => item && renderColoredRow(item.value, item.score)

  },
  {
    title: <FormattedMessage id="houses.list.newlyBuiltMessage" />,
    dataIndex: "newly_built",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) ? <FormattedMessage id="houses.list.yesMessage" /> : <FormattedMessage id="houses.list.noMessage" />;
      const pillData = checkItemPill(item, CRITERIA_MAP.newly_built);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.commuteMessage" />,
    dataIndex: "commute",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) && <FormattedMessage
            id="houses.list.proximity_criteria"
            values={{ number: item.value }}
          />;
      const pillData = checkItemPill(item, CRITERIA_MAP.commute);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.distanceToAddressMessage" values={{address: address}} />,
    dataIndex: "custom_distance",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) && <FormattedMessage
            id="houses.list.proximity_criteria"
            values={{ number: item.value }}
          />;
      const pillData = checkItemPill(item, CRITERIA_MAP.custom_distance);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.proximityToGroceryMessage" />,
    dataIndex: "grocery",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) && <FormattedMessage
            id="houses.list.proximity_criteria"
            values={{ number: ~~(item.value / 60) }}
          />;
      const pillData = checkItemPill(item, CRITERIA_MAP.grocery);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.parksMessage" />,
    dataIndex: "parks",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) && <FormattedMessage
            id="houses.list.proximity_criteria"
            values={{ number: ~~(item.value / 60) }}
          />;
      const pillData = checkItemPill(item, CRITERIA_MAP.parks);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.schoolQualityMessage" />,
    dataIndex: "school_quality",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item);
      const pillData = checkItemPill(item, CRITERIA_MAP.school_quality);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.LowCrimeMessage" />,
    dataIndex: "low_crime",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) && item.label;
      const pillData = checkItemPill(item, CRITERIA_MAP.crime);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.walkabilityMessage" />,
    dataIndex: "walkability",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item);
      const pillData = checkItemPill(item, CRITERIA_MAP.walkability);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.quietnessMessage" />,
    dataIndex: "quietness",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item) && item.value;
      const pillData = checkItemPill(item, CRITERIA_MAP.quietness);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.lowFloodRiskMessage" />,
    dataIndex: "flood_risk",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
     // temporary show N/A for this column
    render: () => renderColoredRow(null, -1, CRITERIA_MAP.flood_risk, null)
    // temporary show N/A for this column

  },
  {
    title: <FormattedMessage id="houses.list.publicTransitScoreMessage" />,
    dataIndex: "transit",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item);
      const pillData = checkItemPill(item, CRITERIA_MAP.transit);
      return renderColoredRow(value, pillData);
    }
  },
  {
    title: <FormattedMessage id="houses.list.bikeabilityMessage" />,
    dataIndex: "bikeability",
    align: 'center',
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: true,
    width: 130,
    render: (item) => {
      const value = getValueOrNull(item);
      const pillData = checkItemPill(item, CRITERIA_MAP.bikeability);
      return renderColoredRow(value, pillData);
    }
  },
];

export {
  COLUMNS,
  COLUMNS_TITLE_IN_ALPHABET_ORDER,
  LISTING_STATUS_MESSAGES_MAPPINGS,
  SWITCH_SETTINGS,
  SWITCHER_VIEW,
};
