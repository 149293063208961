import React from 'react';
import { FormattedMessage } from "react-intl";
import { inject } from "mobx-react";
import { get as lodashGet } from "lodash";
import "containers/Houses/styles.scss";

const TopMatchesHeader = ({ housesStore }) => {
  const numberOfHouse = lodashGet(housesStore.retrieveHousesData(), "total_results");
  return (
    <div style={{display: 'flex'}}>
      <div className="main-header__title fs-24 bold">
        <FormattedMessage id="houses.topHomeMatchesTitle"/>
      </div>
      <div className="main-header__results fs-16">
        <FormattedMessage id="houses.resultsMessage" values={{number: numberOfHouse}} />
      </div>
    </div>
  )
}

export default inject('housesStore')(TopMatchesHeader);
