import React, { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Divider } from 'antd';
import _, {
  get as lodashGet,
  map as lodashMap,
} from "lodash";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { getColorForScore } from "utils/helpers/housesHelper/common";
import { getHighestCriteria } from "utils/helpers/housesHelper/getter";
import HouseCard from "components/DataDisplay/HouseCard";
import CustomPagination from "components/Controls/CustomPagination";
import { TOKEN_KEY } from "constants/localStorage";
import { LOGIN_PATH } from "constants/paths";
import { HOUSES_PER_PAGE } from "constants/houses";
import { getCurrentPageNumber } from "utils/helpers/favoritesHelper";
import "../styles.scss";

const SORT_ORDER = {
  asc: 'asc',
  desc: 'desc',
};

const ListHouseCard = observer(({ criteriaStore, housesStore, handleFavorites, handleOnClickHouse, checkResultsExistence }) => {
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.desc);
  const [isActive, setIsActive] = useState(false);
  const housesData = housesStore.retrieveHousesData();
  const criteriaData = criteriaStore.retrieveCriteriaData();
  const highestCriteria = getHighestCriteria(housesData);
  const endPageIndex = housesStore.currentPage * HOUSES_PER_PAGE;
  const history = useHistory();

  useEffect(() => {
    setIsActive(false);
  }, [housesStore.currentPage])

  const onSortByScore = () => {
    const newSortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
    setSortOrder(newSortOrder);
    housesStore.setHouses({
      data: housesData.data.sort((item1, item2) => newSortOrder === SORT_ORDER.asc ? item1.total_score - item2.total_score : item2.total_score - item1.total_score)
    })
  }

  const onMouseHoverIcon = (item) => {
    const marker = document.getElementById(`marker${item.id}`);
    if (marker) {
      marker.style.zIndex = '201';
      marker.style.background = getColorForScore(item.total_score);
      housesStore.setHoveredItem(item);
    }
  }

  const onMouseLeaveIcon = (item) => {
    const marker = document.getElementById(`marker${item.id}`);
    if (marker) {
      marker.style.zIndex = item.total_score + 100;
      marker.style.background = "none";
      housesStore.setHoveredItem(null);
    }
  }

  const onClickFavorites = item => {
    onMouseLeaveIcon(item);
    const handleSuccessUpdate = isLastItemOnPage => {
      const housesData = housesStore.retrieveHousesData();
      housesStore.currentPage = getCurrentPageNumber(housesStore.currentPage, housesData, isLastItemOnPage);
      if (_.isEmpty(housesData.data)) {
        checkResultsExistence(false);
      }
    }
    if (!localStorage.getItem(TOKEN_KEY)) {
      history.push(LOGIN_PATH);
    } else {
      handleFavorites(item.id, housesStore, handleSuccessUpdate);
    }
  }

  return (
    <div className="list-house-container">
      <div className="list-house-header">
        <div className="list-house-header__results bold fs-16">
           <FormattedMessage id="houses.card.results" 
              values={{number: lodashGet(housesData, 'total_results') < 1000 ? lodashGet(housesData, 'total_results') : 1000}}
            />
        </div>
        <div
          className="list-house-header__sorting"
          onClick={onSortByScore}
        >
          <div>
            <FormattedMessage id="houses.card.score" />
          </div>
          <div
            className={classNames("list-house-header__sorting-icon", {"is-active": sortOrder === SORT_ORDER.asc})}
          >
            <KeyboardArrowDownIcon />
          </div>
        </div>
      </div>
      <Divider />
      <div className="list-house">
        {lodashMap(lodashGet(housesData, 'data', []).slice(endPageIndex - HOUSES_PER_PAGE, endPageIndex), item => (
          <div
            onMouseEnter={() => onMouseHoverIcon(item)}
            onMouseLeave={() => onMouseLeaveIcon(item)}
          >
            <HouseCard
              criteriaData={criteriaData}
              houseData={item}
              isActive={isActive}
              setIsActive={setIsActive}
              handleFavorites={() => onClickFavorites(item)}
              handleOnClickHouse={handleOnClickHouse}
              highestCriteria={highestCriteria}
              activeFavorite={item.is_favorite}
            />
          </div>))}
      </div>
      <div className="list-house-pagination">
        <CustomPagination
          pageSize={HOUSES_PER_PAGE}
          total={housesData.data && housesData.data.length}
          current={housesStore.currentPage}
          onChange={page => housesStore.setCurrentPage(page)}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
})

export default inject("criteriaStore", 'housesStore')(ListHouseCard);
