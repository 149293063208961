import React from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { MAX_BATHROOMS } from "constants/criteria";
import PriorityField from "components/Fields/PriorityField";
import { SliderMinIdealInputField } from "components/Fields";
import { IconBathrooms } from "components/Svgs";
import { createHistogramSteps, getInitialRangePriorityValues, onCriteriaRangeFormFinish } from "utils/helpers/criteriaHelper";
import "../../styles.scss";

const DEFAULT_VALUES = {
  min: 1,
  max: 6,
  ideal: 5,
  step: 0.5,
};

const HISTOGRAM_STEPS = createHistogramSteps(DEFAULT_VALUES.min, DEFAULT_VALUES.max + 1, 1);

const BOTTOM_VALUES = ["1", "2", "3", "4", "5", "6", "6+"];

const BathroomsForm = ({ dataKind, formName, initialData, kind, withPriority, onSubmitForm }) => {
  const [form] = Form.useForm();
  const initialValues = getInitialRangePriorityValues(DEFAULT_VALUES, initialData, false, true, withPriority);

  const onFinish = values => onCriteriaRangeFormFinish(kind, values, withPriority, onSubmitForm);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name={formName}
      onFinish={onFinish}
    >
      <Form.Item name="range">
        <SliderMinIdealInputField
          dataKind={dataKind}
          kind={kind}
          form={form}
          min={DEFAULT_VALUES.min}
          max={DEFAULT_VALUES.max}
          step={DEFAULT_VALUES.step}
          histogramSteps={HISTOGRAM_STEPS}
          maxInputValue={MAX_BATHROOMS}
          isFloat
          bottomValues={BOTTOM_VALUES}
          icon={<IconBathrooms />}
          minMessage={<FormattedMessage id="dashboard.form.minBathroomsMessage" />}
          idealMessage={<FormattedMessage id="dashboard.form.idealBathroomsMessage" />}
        />
      </Form.Item>
      {withPriority && (
        <Form.Item name="priority">
          <PriorityField />
        </Form.Item>
      )}
    </Form>
  );
};

BathroomsForm.propTypes = {
  dataKind: PropTypes.string,
  formName: PropTypes.string,
  initialData: PropTypes.object,
  kind: PropTypes.string,
  withPriority: PropTypes.bool,
  onSubmitForm: PropTypes.func,
};

export default BathroomsForm;
