/**
 * Success Page
 *
 * This is the page we show when the user finishes signing up
 */

import React from 'react';
import HelmetIntl from 'components/Meta/HelmetIntl';
import { emptyFavorites } from 'images';
import "./styles.scss";

const SuccessPage = () => (
  <div>
    <HelmetIntl id="helmet.success" />
    <div className="empty-top-matches__image">
          <img src={emptyFavorites} />
    </div>
    <div className="empty-top-matches__messages-title">
      Thank you! I&apos;ll get back to you with home matches shortly.
    </div>
  </div>
);

export default SuccessPage;
