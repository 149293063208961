import _, { get as lodashGet } from "lodash";

const BATH_BED_SLIDER_DIFF = 1;
const DISTANCE_SLIDER_DIFF = 5;
const ONE_ACRE = 43560;
const PRICE_SLIDER_DIFF = 50000;
const SQUARE_FOOTAGE_SLIDER_DIFF = 500;
const SLIDER_DIFF = 0;

const addPriorityValues = (data, priority) => _.assign(data, { priority });

// checking if the icon should be filled
const checkIsFill = (criteria, criteriaData) => {
  const priority = _.get(criteriaData, `${criteria.key}.priority`);
  // check if the criteria has a priority, if there is, check it, if not, check if it has a key (that is, it is saved) or if there is a value array
  if (priority === undefined) {
    const values = _.get(criteriaData, `${criteria.key}.values`);
    if (_.isArray(values)) {
      return !_.isEmpty(values);
     }
    return _.has(criteriaData, criteria.key) && !_.isEmpty(criteriaData[criteria.key]);
  }
  return priority > 0;
};

const convertSqftToAcres = value => (value / ONE_ACRE).toFixed(2);

const formatCriteriaData = (newData, previousData) => {
  const data = _.cloneDeep(previousData);
  let kind;
  if (_.isPlainObject(newData) && _.has(newData, "kind")) {
    kind = newData.kind;
  }
  if (_.isArray(newData) && !_.isEmpty(newData)) {
    kind = _.isObject(newData[0]) ? newData[0].kind : null;
  }
  if (kind) {
    data[kind] = getUpdatedData(data[kind], newData);
    return data;
  }
  return  {};
};

const getInitialDistanceValues = (defaultValues, data, withPriority = false) => {
  let initialValues = getInitialRangePriorityValues(defaultValues, data, true, false, withPriority);
  initialValues = _.assign(initialValues, {
    location: lodashGet(data, "address", {}),
    transport_type: lodashGet(data, "transport_type") || defaultValues.transportType,
  });
  return initialValues;
};

const getInitialRangePriorityValues = (defaultValues, data, withMax, withMin, withPriority = false) => {
  let initialValues = {
    range: { ideal: _.isNil(lodashGet(data, "ideal")) ? defaultValues.ideal : lodashGet(data, "ideal") }
  };
  if (withMax) {
    initialValues.range.max = lodashGet(data, "max") || defaultValues.max;
  }
  if (withMin) {
    initialValues.range.min = lodashGet(data, "min") || defaultValues.min;
  }
  if (withPriority) {
    initialValues = addPriorityValues(initialValues, lodashGet(data, "priority"));
  }
  return initialValues;
};

const getUpdatedData = (previousData, newData) => {
  if (_.has(previousData, "id")) {
    newData.id = previousData.id;
  }
  return newData;
};

const normalizeIdeal = (min, ideal, max, diff) => {
  if (min > ideal) {
    min = Math.max(ideal - diff, 0);
  }
  if (max < ideal) {
    max = ideal + diff;
  }
  return [min, ideal, max];
};

const normalizeBathroomIdeal = (min, ideal, max, diff) => {
  if (min > ideal) {
    min = Math.max(ideal - diff, 0) === 0 ? diff :  Math.max(ideal - diff, 0);
  }
  if (max < ideal) {
    max = ideal + diff;
  }
  return [min, ideal, max];
};


const normalizeMinMax = (min, ideal, max, diff) => {
  if (max < ideal) {
     ideal = Math.max(max - diff, 0) === 0 ? diff : Math.max(max - diff, 0);
    if (ideal < min) {
      min = Math.max(ideal - diff, 0);
    }
  }
  if (min > ideal) {
    ideal = min + diff;
    if (ideal > max) {
      max = ideal + diff;
    }
  }
  return [min, ideal, max];
};

const normalizeNumber = value => value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const onCriteriaRangeFormFinish = (kind, values, withPriority, onSubmitForm) => {
  let updatedValues = { kind, ...values.range };
  if (["commute", "custom_distance"].includes(kind)) {
    const locationValues = _.omit(values.location, 'kind');
    updatedValues = _.assign(updatedValues, {
      transport_type: values.transport_type,
      ...locationValues,
    });
  }
  if (withPriority) {
    updatedValues = addPriorityValues(updatedValues, values.priority);
  }
  onSubmitForm(updatedValues);
};

const parseNumberToInt = (value, maxInputValue, isFloat) => {
  const replacedValue = value.replace(/\$\s?|(,*)/g, '');
  const parsedValue = isFloat ? parseFloat(replacedValue) : parseInt(replacedValue);
  return parsedValue > maxInputValue ? maxInputValue : parsedValue;
};

const absValue = inputValue => inputValue < 0 ? Math.abs(inputValue) : inputValue;

const createHistogramSteps = (min, max, step) => _.range(min, max + step, step);

export {
  BATH_BED_SLIDER_DIFF,
  DISTANCE_SLIDER_DIFF,
  ONE_ACRE,
  PRICE_SLIDER_DIFF,
  SQUARE_FOOTAGE_SLIDER_DIFF,
  SLIDER_DIFF,
  addPriorityValues,
  checkIsFill,
  createHistogramSteps,
  convertSqftToAcres,
  formatCriteriaData,
  getInitialDistanceValues,
  getInitialRangePriorityValues,
  getUpdatedData,
  normalizeIdeal,
  normalizeMinMax,
  normalizeNumber,
  onCriteriaRangeFormFinish,
  parseNumberToInt,
  absValue,
  normalizeBathroomIdeal,
};
