import React, { useState } from "react";
import _ from "lodash";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import { Spin } from "antd";
import { observer, inject } from "mobx-react";
import { EMAIL_CONFIRM, ONBOARDING_PATH } from "constants/paths";
import { ERRORS_MESSAGES } from 'constants/messages';
import { SignUpForm } from "components/Forms";
import {
  IconGoogle,
  ImageSignUpStart,
  IconExclamationPoint,
  IconClose,
} from "components/Svgs";
import { hihomeLogoBeta } from "images";
import { firebaseAuth } from "utils/firebase";
import { setObjectToLocalStorage } from "utils/helpers/common";
import { NON_AUTHENTICATED_USER_DATA_KEY } from "constants/localStorage";
import { trackEvent, updateIntercomWidget, initializeGTM, USER_NAME_DATA } from "utils/helpers/userHelper";
import "antd/dist/antd.css";
import HomePageButton from "./HomePageButton";
import "./styles.scss";


const SignUp = observer(({ authStore }) => {
  const [errorMessageId, setErrorMessageId] = useState();
  const history = useHistory();

  const handleSignUp = (formData) => {
    authStore.setEmail(formData.email);
    saveUserDetailToLocalStorage(formData);
    const handleSuccess = () => {
      const user = firebaseAuth.currentUser;
      if (user && !user.emailVerified) {
        authStore.sendEmailVerificationFirebaseRequest(user);
      }
      history.push(EMAIL_CONFIRM);
    };
    const handleError = (error) => {
      setErrorMessageId(ERRORS_MESSAGES[error.code]);
    };
    authStore.signUpFirebaseRequest(formData, handleSuccess, handleError);
  };

  const saveUserDetailToLocalStorage = (formData) => {
    setObjectToLocalStorage(NON_AUTHENTICATED_USER_DATA_KEY, _.pick(formData, USER_NAME_DATA));
  };

  const signUpWithGoogle = () => {
    const handleSuccess = () => {
      updateIntercomWidget();
      initializeGTM();
      let eventName = "success-login";
      const userMetadata = firebaseAuth.currentUser.metadata;
      if (parseInt(userMetadata.lastLoginAt) - parseInt(userMetadata.createdAt) <= 1000) {
        eventName = "success-signup";
      }
      trackEvent(eventName, {method: "google"});
      history.push(ONBOARDING_PATH);
    };
    authStore.signInWithGoogleRequest(handleSuccess);
  }

  return (
    <Spin spinning={authStore.loading}>
      <section className="login">
        <HomePageButton kind="sign_up" />
        <div className="login__left">
          <ImageSignUpStart />
        </div>
        <div className="login__right">
          {errorMessageId && (
            <span className="login__error-message">
              <IconExclamationPoint className="login__error-message__icon" />
              <FormattedMessage id={errorMessageId} />
              <Button onClick={() => setErrorMessageId(null)}>
                <IconClose />
              </Button>
            </span>
          )}
          <div className="logo">
            <Link to="/"><img src={hihomeLogoBeta} alt="hihome-logo-beta" width={186} height={57} /></Link>
            {/* <IconLogo width="170" height="52" /> */}
          </div>
          <button className="login__google" onClick={signUpWithGoogle}>
            <FormattedMessage id="signUp.form.signUpWithGoogle" />
            <IconGoogle className="icon-google" />
          </button>
          <span className="login__or">
            <FormattedMessage id="general.or" />
          </span>

          <SignUpForm handleSignUp={handleSignUp} isLoading={authStore.loading} />
        </div>
      </section>
    </Spin>
  );
});

export default inject("authStore")(SignUp);
