import greenCircle from "images/MapIcons/green-circle.png";
import greenLightCircle from "images/MapIcons/green-light-circle.png";
import yellowCircle from "images/MapIcons/yellow-circle.png";
import orangeCircle from "images/MapIcons/orange-circle.png";
import redCircle from "images/MapIcons/red-circle.png";

const getBooleanValueByScore = (score, value) => {
  switch(true) {
    case score === 100:
      return 'green-value';
    case score === 0 && value!== null:
      return 'red-value';
    default:
      return 'grey-value';
  }
};

const getClassnameForRating = rating => {
  switch(true) {
    case rating >= 8:
      return 'green-background';
    case rating >= 6:
      return 'green-yellow-background';
    case rating >= 4:
      return 'yellow-background';
    case rating >= 2:
      return 'orange-background';
    default:
      return 'no-background';
  }
};

const getClassnameForScore = score => {
  switch(true) {
    case score >= 80:
      return 'green-background';
    case score >= 60:
      return 'green-yellow-background';
    case score >= 40:
      return 'yellow-background';
    case score >= 20:
      return 'orange-background';
    case score >= 0:
      return 'red-background';
    default:
      return 'no-background';
  }
};

const getColorForScore = score => {
  switch(true) {
    case score >= 80:
      return '#4AB272';
    case score >= 60:
      return '#C9ED62';
    case score >= 40:
      return '#F5E237';
    case score >= 20:
      return '#FFA425';
    case score >= 0:
      return '#FB5D5D';
    default:
      return 'no-background';
  }
};

const getHouseDetailPhotoHeight = screenHeight => {
  switch (true) {
    case screenHeight < 600:
      return 200;
    case screenHeight < 750:
      return 240;
    case screenHeight < 1000:
      return 280;
    case screenHeight < 1200:
      return 320;
    default:
      return 360;
    }
};

const getImageByScore = score => {
  switch(true) {
    case score >= 80:
      return greenCircle;
    case score >= 60:
      return greenLightCircle;
    case score >= 40:
      return yellowCircle;
    case score >= 20:
      return orangeCircle;
    case score >= 0:
      return redCircle;
    default:
      return null;
  }
};

const getValueClassnameByScore = (score, value) => {
  switch(true) {
    case score >= 80:
      return 'green-value';
    case score >= 60:
      return 'green-yellow-value';
    case score >= 40:
      return 'yellow-value';
    case score >= 0 && value!== null:
      return 'orange-value';
    default:
      return 'grey-value';
  }
};

const getValueClassnameByScoreRangeType = (score, value) => {
  switch(true) {
    case score === 100:
      return 'green-value';
    case score >= 67:
      return 'green-yellow-value';
    case score >= 34:
      return 'yellow-value';
    case score >= 0 && value!== null:
      return 'orange-value';
    default:
      return 'grey-value';
  }
};

export {
  getBooleanValueByScore,
  getClassnameForRating,
  getClassnameForScore,
  getColorForScore,
  getImageByScore,
  getHouseDetailPhotoHeight,
  getValueClassnameByScore,
  getValueClassnameByScoreRangeType
};
