import React from 'react';
import { FormattedMessage } from "react-intl";
import "containers/Houses/styles.scss";

const FavoritesHeader = () => (
  <div className="main-header__title fs-24 bold">
    <FormattedMessage id="houses.favoritesTitle"/>
  </div>
)

export default FavoritesHeader;
