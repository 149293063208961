import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Form, Input, Spin } from "antd";
import { SimpleButton } from "components/Controls/Buttons";
import { observer, inject } from "mobx-react";
import { firebaseAuth } from "utils/firebase";
import { getPasswordIcon, trackEvent, updateIntercomWidget, initializeGTM } from "utils/helpers/userHelper";
import { DASHBOARD_PATH, SIGN_UP, EMAIL_CONFIRM } from "constants/paths";
import {
  IconArrowRight,
  ImagePeople,
  IconGoogle,
  IconInputMain,
  IconInputPassword,
  IconExclamationPoint,
  IconMailCheck,
  IconClose,
} from "components/Svgs";
import "antd/dist/antd.css";
import {
  CRITERIA_DATA_KEY,
  LAST_SAVED_ONBOARDING_STEP_KEY,
  NON_AUTHENTICATED_USER_DATA_KEY,
  ONBOARDING_DATA_KEY,
} from "constants/localStorage";
import HomePageButton from "containers/SignUp/HomePageButton";
import { localStorageStrToObjectOrNull } from "utils/helpers/common";
import { hihomeLogoBeta } from "images";
import "./styles.scss";

const LogIn = observer(({ authStore, criteriaStore, intl, onboardingStore }) => {
  const [email, setEmail] = useState();
  const [isEmailValidate, setIsEmailValidate] = useState(null);
  const [isError, setIsError] = useState();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    email && form.validateFields(['email'])
      .then(() => setIsEmailValidate(true))
      .catch(() => setIsEmailValidate(false));
  }, [email]);

  const updateStorage = () => {
    const criteriaData = localStorageStrToObjectOrNull(CRITERIA_DATA_KEY);
    const userData = localStorageStrToObjectOrNull(NON_AUTHENTICATED_USER_DATA_KEY);
    if (userData) {
      window.Intercom('update', {'name': userData.first_name + " " + userData.last_name});
      authStore.updateUserDetailsRequest(userData);
    }
    if (criteriaData) {
      onboardingStore.saveOnboardingData({
        data: userData,
        is_finished: true,
        step: 0
      });
      criteriaStore.postSearchCriteriaRequest({ data: criteriaData });
      localStorage.removeItem(CRITERIA_DATA_KEY);
    }
    localStorage.removeItem(NON_AUTHENTICATED_USER_DATA_KEY);
    localStorage.removeItem(ONBOARDING_DATA_KEY);
    localStorage.removeItem(LAST_SAVED_ONBOARDING_STEP_KEY);
  };

  const logIn = (formData) => {
    const handleSuccess = (data) => {
      if (data && data.emailVerified) {
        updateStorage();
        updateIntercomWidget();
        initializeGTM();
        trackEvent("success-login", { method: "email" });
        setTimeout(() => history.push(DASHBOARD_PATH), 500);
        // history.push(DASHBOARD_PATH);
      } else {
        history.push(EMAIL_CONFIRM);
      }
    };
    const handleError = () => {
      setIsError(true);
    };
    // localStorage.clear();
    authStore.logInFirebaseRequest(formData, handleSuccess, handleError);
  };

  const signUpWithGoogle = () => {
    const handleSuccess = () => {
      updateStorage();
      updateIntercomWidget();
      initializeGTM();
      let eventName = "success-login";
      const userMetadata = firebaseAuth.currentUser.metadata;
      if (parseInt(userMetadata.lastLoginAt) - parseInt(userMetadata.createdAt) <= 1000){
        eventName = "success-signup";
      }
      trackEvent(eventName, { method: "google" });
      history.push(DASHBOARD_PATH);
    }
    authStore.signInWithGoogleRequest(handleSuccess);
  }

  return (
    <Spin spinning={authStore.loading}>
      <section className="login">
        <HomePageButton kind="login" />
        <div className="login__left">
          <ImagePeople className="image-people" />
        </div>
        <div className="login__right">
          {isError && (
            <span className="login__error-message">
              <IconExclamationPoint className="login__error-message__icon" />
              <FormattedMessage id="login.message.incorrect.password" />
              <Button onClick={() => setIsError(false)}>
                <IconClose />
              </Button>
            </span>
          )}
          <div className="logo">
            <Link to="/"><img src={hihomeLogoBeta} alt="hihome-logo-beta" width={186} height={57} /></Link>
            {/* <IconLogo width="170" height="52" /> */}
          </div>

          <Form
            className="login__form"
            onFinish={logIn}
            form={form}
          >
            <button
              className="login__google"
              type="button"
              onClick={signUpWithGoogle}
            >
              <FormattedMessage id="login.with.google" />
              <IconGoogle className="icon-google" />
            </button>
            <span className="login__or">
              <FormattedMessage id="general.or" />
            </span>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: intl.formatMessage({id: "signUp.form.invalidEmail"}),
                },
              ]}
            >
              <Input
                prefix={<IconInputMain />}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={intl.formatMessage({id: "signUp.form.emailAddress"})}
                suffix={isEmailValidate && <IconMailCheck />}
              />
            </Form.Item>

            <div className="form-item">
              <Form.Item name="password" className="form-item">
                <Input.Password
                  prefix={<IconInputPassword />}
                  placeholder={intl.formatMessage({id: "signUp.form.password"})}
                  iconRender={(visible) => getPasswordIcon(visible)}
                />
              </Form.Item>
              <a href="#" className="forgot-password">
                <FormattedMessage id="login.forgon.password" />
              </a>
            </div>

            <div className="login__form__bottom">
              <p className="login__sign-up">
                <FormattedMessage id="login.not.member" />
                <a href={SIGN_UP}>
                  <FormattedMessage id="login.sign.up" />
                </a>
              </p>
              <SimpleButton
                customClass="login__continue"
                htmlType="submit"
                disabled={authStore.loading}
              >
                <FormattedMessage id="general.continue" /> <IconArrowRight />
              </SimpleButton>
            </div>
          </Form>
        </div>
      </section>
    </Spin>
  );
});

export default inject("authStore", "criteriaStore", "onboardingStore")(injectIntl(LogIn));
