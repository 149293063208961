import React from "react";
import Slider from "@mui/material/Slider";
import classNames from "classnames";
import { normalizeNumber } from "utils/helpers/criteriaHelper";
import "../styles.scss";

const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const CustomSlider = (props) => (

  <div className={classNames("custom-slider-wrapper", {"with-padding": props.withPadding, "inverted-slider": props.invertedSlider })}>
    <Slider
      track={"normal"}
      valueLabelDisplay={"auto"}
      valueLabelFormat={(value) => value >= props.max ? `${numberWithCommas(props.max)}+` : normalizeNumber(value.toString())}
      {...props}
    />
  </div>
);

export default CustomSlider;
