import React from 'react';
import { FormattedMessage } from "react-intl";
import { inject } from "mobx-react";
import { SimpleButton } from "components/Controls/Buttons";
import {
  BathroomsForm,
  BedroomsForm,
  DistanceForm,
  GroceryForm,
  HomeTypeForm,
  LotSizeForm,
  ParksForm,
  PriceRangeForm,
  PriorityForm,
  SearchLocationForm,
  SquareFootageForm,
} from "components/Forms";
import { IconClose } from "components/Svgs";
import { DATA_KIND } from "constants/common";
import { formatCriteriaData } from "utils/helpers/criteriaHelper";
import { CRITERIA_MAP, CRITERIA_MESSAGE_MAP, CRITERIA_ICON_MAP } from "../constants";
import RemoteSubmitButtonView from "../RemoteSubmitButtonView";

const FORMS = {
  [CRITERIA_MAP.bathrooms]: { form: BathroomsForm, formName: "BathroomsForm", key: "bathrooms", defaultValue: {}, withPriority: true },
  [CRITERIA_MAP.bedrooms]: { form: BedroomsForm, formName: "BedroomsForm", key: "bedrooms", defaultValue: {}, withPriority: true },
  [CRITERIA_MAP.prop_type]: { form: HomeTypeForm, formName: "HomeTypeForm", key: "prop_type", defaultValue: {} },
  [CRITERIA_MAP.location]: { form: SearchLocationForm, formName: "LocationsForm", key: "locations", defaultValue: [] },
  [CRITERIA_MAP.custom_distance]: { form: DistanceForm, formName: "CustomDistanceForm", key: "custom_distance", defaultValue: {}, withPriority: true },
  [CRITERIA_MAP.commute]: { form: DistanceForm, formName: "CommuteDistanceForm", key: "commute", defaultValue: {}, withPriority: true },
  [CRITERIA_MAP.grocery]: { form: GroceryForm, formName: "GroceryForm", key: "grocery", defaultValue: {}, withPriority: true, },
  [CRITERIA_MAP.price]: { form: PriceRangeForm, formName: "PriceRangeForm", key: "price", defaultValue: {}, withPriority: true },
  [CRITERIA_MAP.school_quality]: PriorityForm,
  [CRITERIA_MAP.crime]: PriorityForm,
  [CRITERIA_MAP.transit]: PriorityForm,
  [CRITERIA_MAP.parks]: { form: ParksForm, formName: "ParksForm", key: "parks", defaultValue: {}, withPriority: true },
  [CRITERIA_MAP.walkability]: PriorityForm,
  [CRITERIA_MAP.bikeability]: PriorityForm,
  [CRITERIA_MAP.quietness]: PriorityForm,
  [CRITERIA_MAP.garage]: PriorityForm,
  [CRITERIA_MAP.parking]: PriorityForm,
  [CRITERIA_MAP.central_ac]: PriorityForm,
  [CRITERIA_MAP.basement]: PriorityForm,
  [CRITERIA_MAP.single_story]: PriorityForm,
  [CRITERIA_MAP.solar_power]: PriorityForm,
  [CRITERIA_MAP.newly_built]: PriorityForm,
  [CRITERIA_MAP.building_size]: { form: SquareFootageForm, formName: "SquareFootageForm", key: "building_size", defaultValue: {}, withPriority: true },
  [CRITERIA_MAP.lot_size]: { form: LotSizeForm, formName: "LotSizeForm", key: "lot_size", defaultValue: {}, withPriority: true },
}

const Filters = ({ criteriaStore, activeFilter, handleActiveFilter }) => {

  const getInitialData = formData => {
    if (formData) {
      const criteriaData = criteriaStore.retrieveCriteriaData();
      const initialData = criteriaData[formData.key];
      return initialData || formData.defaultValue || null;
    }
    return null;
  };

  const handleSearchCriteria = updatedValues => {
    const handleSuccess = () => {
      // TODO add notification
      handleActiveFilter(null);
    }
    const criteriaData = criteriaStore.retrieveCriteriaData();
    const data = formatCriteriaData(updatedValues, criteriaData);
    criteriaStore.postSearchCriteriaRequest({ data }, handleSuccess);
  };

  const renderHeader = () => (
    <div>
      <div className="filters-title fs-16">
        <div className="filters-icon">
          {CRITERIA_ICON_MAP[activeFilter]()}
        </div>
        <FormattedMessage id={CRITERIA_MESSAGE_MAP[activeFilter]}/>
        <SimpleButton
          customClass="filters-close"
          onClick={() => handleActiveFilter(null)}
        >
          <IconClose />
        </SimpleButton>
      </div>
      {activeFilter === CRITERIA_MAP.location && 
      <div className="location-search__sub-title">
        <FormattedMessage id="dashboard.form.locationMA"/>
      </div>}
    </div>
  );

  const renderForm = () => {
    const formData = FORMS[activeFilter];
    const CriteriaForm = formData.form || formData;
    return (
      <CriteriaForm
        dataKind={DATA_KIND.dashboard}
        formName={_.has(formData, "formName") ? formData.formName : null}
        initialData={getInitialData(formData)}
        kind={activeFilter}
        withPriority={_.has(formData, "withPriority") ? formData.withPriority : null}
        onSubmitForm={handleSearchCriteria}
      />
    );
  };

  const renderRemoteSubmitButton = () => {
    const formData = FORMS[activeFilter];
    return !_.isNil(formData.formName) && <RemoteSubmitButtonView formName={formData.formName} />;
  };

  return (
    <div className="filters-content" key={activeFilter}>
      {renderHeader()}
      {renderForm()}
      {renderRemoteSubmitButton()}
    </div>
  )
};

export default inject("criteriaStore")(Filters);
