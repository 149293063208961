import React from "react";
import { useHistory, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import { hihomeLogoBeta } from "images";
import { CRITERIA_DATA_KEY, NON_AUTHENTICATED_USER_DATA_KEY } from "constants/localStorage";
import { SimpleButton } from "components/Controls/Buttons";
import { LOGIN_PATH } from "constants/paths";
import {
  IconArrowRight,
  ImageSignUpStart,
  IconConfetti,
} from "components/Svgs";
import HomePageButton from "../HomePageButton";
import "antd/dist/antd.css";

const EmailConfirmSuccess = ({ userEmail }) => {
  const history = useHistory();

  // Getting data from non-authenticated user to avoid deleting it.
  // The data will be used to avoid onboarding if it is already passed
  const criteriaData = localStorage.getItem(CRITERIA_DATA_KEY);
  const notSavedUser = localStorage.getItem(NON_AUTHENTICATED_USER_DATA_KEY);
  localStorage.clear();
  if (!_.isEmpty(criteriaData)) {
    localStorage.setItem(CRITERIA_DATA_KEY, criteriaData);
  }
  if (!_.isEmpty(notSavedUser)) {
    localStorage.setItem(NON_AUTHENTICATED_USER_DATA_KEY, notSavedUser);
  }

  return (
    <section className="login">
      <HomePageButton />
      <div className="login__left">
        <ImageSignUpStart />
      </div>
      <div className="login__right">
        <div className="logo">
          <Link to="/"><img src={hihomeLogoBeta} alt="hihome-logo-beta" width={186} height={57} /></Link>
        </div>
        <div className="email-confirm-message">
          <FormattedMessage id="signUp.emailUpdated" />
        </div>
        <div className="email-confirm-description">
          <FormattedMessage id="signUp.emailUpdatedDescription" values={{email: userEmail}} />
        </div>

        <div className="email-confirm-image">
          <IconConfetti />
        </div>

        <div className="login__form__bottom">
          <SimpleButton
            customClass="login__continue"
            onClick={() => history.push(LOGIN_PATH)}
          >
            <FormattedMessage id="signUp.goToLogin" />
            <IconArrowRight />
          </SimpleButton>
        </div>
      </div>
    </section>
  );
};

export default EmailConfirmSuccess;
