import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { FormattedMessage, injectIntl}  from "react-intl";
import classNames from "classnames";
import { SimpleButton } from "components/Controls/Buttons";
import { USER_KEY } from "constants/localStorage";
import { withPassword } from "hocs/withPassword";
import { firebaseAuth } from 'utils/firebase';
import { getPasswordIcon } from "utils/helpers/userHelper";
import {
  IconDoubleCheck,
  IconArrowRight,
} from "components/Svgs";
import "../../styles.scss";

const GOOGLE_PROVIDER_ID = "google.com";

const ProfileForm = (
  {
    intl,
    isError,
    handleProfileSubmit,
    handleReAuthenticate,
    passwordLength,
    passwordLetter,
    passwordsMatch,
    validatePassword,
    validateConfirmPassword,
    observeConfirmPasswordField,
    renderValidations,
  }
) => {
  const [emailActive, setEmailActive] = useState(false);
  const [passwordActive, setPasswordActive] = useState(false);
  const [currentPasswordActive, setCurrentPasswordActive] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [form] = Form.useForm();
  const userFirebase = firebaseAuth.currentUser;

  useEffect(() => {
    observeConfirmPasswordField("ProfileForm_password", "ProfileForm_confirm_password");
  }, [userFirebase, passwordActive])

  const onFinish = formValues => {
    if (emailActive || passwordActive) {
      formValues.currentPassword = currentPassword;
    }
    handleProfileSubmit(formValues);
  };

  const getInitialValues = () => {
    const user = JSON.parse(localStorage.getItem(USER_KEY)) || {};
    if (userFirebase) {
      return {
        firstName: user.first_name,
        lastName: user.last_name,
        email: userFirebase.email,
      }
    }
  }

  const validateSubmit = () => {
    const emailError = form.getFieldError("email");
    return _.isEmpty(emailError);
  }
  const passwordValidateSubmit = () => !(passwordActive && (!passwordLength || !passwordLetter || !passwordsMatch))

  const onClickCheckCurrentPassword = () => {
    if (currentPassword) {
      const handleSuccess = () => {
        setPasswordActive(true);
        setCurrentPasswordActive(true)
      }
      handleReAuthenticate(currentPassword, handleSuccess)
    }
  }

  const renderCurrentPassword = () => (
    <div className="form-input">
      <div className="form-input__message">
        <FormattedMessage id={currentPasswordActive ? "profile.form.oldPassword" : "profile.form.password"} />
      </div>
      <div className="input-with-button">
        <Form.Item
          name="currentPassword"
          rules={[
            {
              validator: (_, value) => {
                const isValidated = currentPasswordActive && validatePassword(value);
                isValidated && setCurrentPassword(value);
                return Promise.resolve("");
              },
            },
          ]}
        >
          <Input.Password
            disabled={!currentPasswordActive}
            className="simple-input"
            placeholder={intl.formatMessage({id: "profile.form.currentPassword"})}
            iconRender={(visible) => getPasswordIcon(visible)}
          />
        </Form.Item>
          <div className="input-with-button__button">
            <SimpleButton
              customClass="btn-default"
              type="button"
              onClick={onClickCheckCurrentPassword}
            >
              <IconArrowRight />
            </SimpleButton>
          </div>
      </div>
    </div>
  )

  const renderPasswords = () => (
    <>
      <div className="form-input">
        <div className="form-input__message">
          <FormattedMessage id="profile.form.newPassword" />
        </div>
        <div className="input-with-button">
          <Form.Item
            name="password"
            dependencies={["confirm_password"]}
            rules={[
              {
                validator: (_, value) => {
                  passwordActive && validatePassword(value);
                  return Promise.resolve("");
                },
              },
            ]}
          >
            <Input.Password
              disabled={!passwordActive}
              className="simple-input"
              placeholder={intl.formatMessage({id: "profile.form.password"})}
              iconRender={(visible) => getPasswordIcon(visible)}
            />
          </Form.Item>
          {!passwordActive && (
            <div className="input-with-button__button">
              <SimpleButton
                customClass="btn-default"
                type="button"
                onClick={() => {
                  setEmailActive(false);
                  setPasswordActive(true);
                }}
              >
                <FormattedMessage id="profile.form.change" />
              </SimpleButton>
            </div>
          )}
        </div>
      </div>

      <div className={classNames("form-input", {invisible: !passwordActive})}>
        <div className="form-input__message">
          <FormattedMessage id="profile.form.confirmPassword" />
        </div>
        <Form.Item
          name="confirm_password"
          className="form-item"
          dependencies={["password"]}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                passwordActive && validateConfirmPassword(getFieldValue("password"), value);
                return Promise.resolve("");
              },
            }),
          ]}
        >
          <Input.Password
            className="simple-input"
            placeholder={intl.formatMessage({id: "profile.form.confirmPassword"})}
            iconRender={() =>
              passwordsMatch ? <IconDoubleCheck /> : null
            }
          />
        </Form.Item>
      </div>
      <SimpleButton
        customClass="save_password"
        htmlType="submit"
        disabled={!passwordValidateSubmit()}
      >
        <FormattedMessage id="profile.savePassword" />
      </SimpleButton>
    </>
  );


  const renderPasswordSection = () => {
    if (!passwordActive) {
      return renderCurrentPassword();
    }
    if (passwordActive && currentPassword) {
      return renderPasswords();
    }
  }

  const renderEmailCurrentPassword = () => (
    <div className="form-input">
      <div className="form-input__message">
        <FormattedMessage id="profile.form.password" />
      </div>
      <div className="input-with-button">
        <Form.Item
          name="emailCurrentPassword"
          rules={[
            {
              validator: (_, value) => {
                const isValidate = validatePassword(value);
                if (isValidate) {
                  setCurrentPassword(value);
                  return Promise.resolve("");
                } else {
                  return Promise.reject("");
                }
              },
            },
          ]}
        >
          <Input.Password
            className="simple-input"
            placeholder={intl.formatMessage({id: "profile.form.currentPassword"})}
            iconRender={(visible) => getPasswordIcon(visible)}
          />
        </Form.Item>
      </div>
    </div>
  )

  const renderEmailField = () => (
    <>
      <div className="form-input">
        <div className="form-input__message">
          <FormattedMessage id="profile.form.email" />
        </div>
        <div className="input-with-button">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: intl.formatMessage({id: "signUp.form.invalidEmail"}),
              },
            ]}
          >
            <Input
              disabled={!emailActive}
              className="simple-input"
              placeholder={intl.formatMessage({id: "profile.form.email"})}
            />
          </Form.Item>
          {!emailActive && (
            <div className="input-with-button__button">
              <SimpleButton
                customClass="btn-default"
                type="button"
                onClick={() => {
                  setEmailActive(true);
                  setPasswordActive(false);
                  setCurrentPasswordActive(false);
                }}
              >
                <FormattedMessage id="profile.form.change" />
              </SimpleButton>
            </div>
          )}
        </div>
      </div>
      {emailActive && renderEmailCurrentPassword()}
    </>
  )

  const renderBottomFields = () => {
    if (userFirebase) {
      const providerData = _.get(userFirebase, "providerData[0].providerId")
      if (providerData !== GOOGLE_PROVIDER_ID) {
        return (
          <>
            <div className="profile-form-columns__email">
              {renderEmailField()}
              {emailActive && !passwordActive && renderValidations()}
            </div>
            <div className={classNames("profile-form-columns__password", { is_confirm_opened: !passwordActive })}>
              <span className='password-message'>
              {!currentPasswordActive && (<FormattedMessage id="profile.form.password"/>)}
              </span>
              {!currentPasswordActive &&(
                <SimpleButton
                  customClass="btn-default change-password-btn"
                  type="button"
                  onClick={() => {
                    setCurrentPasswordActive(true);
                    setEmailActive(false);
                  }}
                >
                  <FormattedMessage id="profile.form.change.password" />
              </SimpleButton>)}
              {currentPasswordActive && renderPasswordSection()}
              {(passwordActive || currentPasswordActive) && renderValidations()}
            </div>
          </>
        )
      }
    }
  }

  return (
    <Form
      name="ProfileForm"
      form={form}
      onFinish={onFinish}
      initialValues={getInitialValues()}
    >
      <div className="profile-form-columns">
        <div className="form-input">
          <div className="form-input__message">
            <FormattedMessage id="profile.form.firstName" />
          </div>
          <Form.Item name="firstName">
            <Input
              className="simple-input"
              placeholder={intl.formatMessage({id: "profile.form.firstName"})}
            />
          </Form.Item>
        </div>
        <div className="form-input">
          <div className="form-input__message">
            <FormattedMessage id="profile.form.lastName" />
          </div>
          <Form.Item name="lastName">
            <Input
              className="simple-input"
              placeholder={intl.formatMessage({id: "profile.form.lastName"})}
            />
          </Form.Item>
        </div>
        {renderBottomFields()}
      </div>
      <SimpleButton
        customClass={classNames("profile-button", {"with-padding": isError })}
        htmlType="submit"
        disabled={!validateSubmit() || currentPasswordActive}
      >
        <FormattedMessage id="profile.saveProfile" />
      </SimpleButton>
    </Form>
  );
};

export default withPassword(injectIntl(ProfileForm));
