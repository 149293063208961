import React from "react";

export const IconMarkerFull = (props) => (
<svg width="106" height="34" viewBox="-2 -2 106 34" fill="none" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter id="filter0_d_2550_4586" x="0" y="0" width="124" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2550_4586"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2550_4586" result="shape"/>
    </filter>
  </defs>
  <g filter="url(#filter0_d_2550_4586)" transform="matrix(1, 0, 0, 1, -10.928036, -8.795735)">
    <path d="M11 25C11 16.1634 18.1634 9 27 9H97C105.837 9 113 16.1634 113 25C113 33.8366 105.837 41 97 41H27C18.1634 41 11 33.8366 11 25Z" fill="white"/>
  </g>
  <circle cx="16.072" cy="16.204" r="16" fill={`${props.color}`}/>
  <text wordSpacing="pre" fontSize="1.6px" fontFamily="Proxima Nova Regular" textAnchor="middle" fontWeight="bold" fill="white" x="26.8" y="24.429" transform="matrix(10.212226, 0, 0, 9.908119, -258.159363, -221.137283)">{props.score}</text>
  <text wordSpacing="pre" fontSize="1.6px" fontFamily="Proxima Nova Regular" fontWeight="bold"  fill="black" x="52.9" y="24.429" transform="matrix(7.589307, 0, 0, 9.138451, -361.399078, -201.449829)">${props.price}</text>
</svg>
);

export default IconMarkerFull;
