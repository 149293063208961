import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import _ from "lodash";
import { USER_KEY } from "constants/localStorage";
import { HOUSE_VIEWS } from "constants/houses";
import { HOUSE_DETAILS_LOCATION } from "constants/paths";
import { handleFavoritesListMapView } from "utils/helpers/favoritesHelper";
import EmptyPage from "./EmptyPage";
import { AccountButtonView, CardView, ListView, MapView } from './Views';
import { SWITCHER_VIEW } from "./constants";
import Switcher from "./Views/Switcher";
import "./styles.scss";

const HOUSES_COMPONENTS_MAP = {
  [SWITCHER_VIEW.list]: ListView,
  [SWITCHER_VIEW.map]: MapView,
  [SWITCHER_VIEW.card]: CardView,
};

const Houses = observer(({ criteriaStore, housesStore, view, handleGetHousesData }) => {
  const [activeView, setActiveView] = useState(SWITCHER_VIEW.list);
  const [resultsExist, checkResultsExistence] = useState(true);
  const housesData = housesStore.retrieveHousesData();
  const isNonAuthUser = _.isNull(localStorage.getItem(USER_KEY));

  useEffect(() => () => housesStore.setHouses([]), []);

  const getHousesData = (handleSuccess, isMapView) => {
    const criteriaData = criteriaStore.retrieveCriteriaData();
    if (_.isEmpty(criteriaData)) {
      criteriaStore.getCriteriaRequest(null, responseData => handleGetHousesData(responseData, isMapView, handleSuccess));
    } else if (!(isNonAuthUser && view === HOUSE_VIEWS.favorites)) {
      handleGetHousesData(criteriaData, isMapView, handleSuccess);
    }
  };

  const handleOnClickHouse = (id) => {
    window.open(HOUSE_DETAILS_LOCATION(id), "_blank");
  }

  const handleActiveView = currentSwitcherValue => {
    housesStore.setHouses({});
    setActiveView(currentSwitcherValue);
  };

  const renderHeader = () => (
    <div className="main-header houses-header">
      {isNonAuthUser && (
        <div className="main-header__left">
          <AccountButtonView />
        </div>
      )}
      <div className="main-header__right">
        <Switcher
          handleActiveView={handleActiveView}
          activeView={activeView}
        />
      </div>
    </div>
  );

  const renderContent = () => {
    const HousesComponent = HOUSES_COMPONENTS_MAP[activeView];
    return (
      <HousesComponent
        checkResultsExistence={checkResultsExistence}
        criteriaStore={criteriaStore}
        getHousesData={getHousesData}
        housesStore={housesStore}
        handleFavorites={handleFavoritesListMapView}
        handleOnClickHouse={handleOnClickHouse}
      />
    );
  };

  // TODO: add check if houseData downloaded
  if (((isNonAuthUser && _.isEmpty(housesData) && view === HOUSE_VIEWS.favorites) || _.get(housesData, "total_results") === 0) &&
    activeView !== "map") {
    return <EmptyPage view={view} />;
  }

  return (
    <div className="houses-main-wrapper">
      <div className={classNames("main houses", { "list-content": activeView === SWITCHER_VIEW.list })}>
        {resultsExist || activeView === SWITCHER_VIEW.map ? (
          <>
            {renderHeader()}
            <div className="main-content house-content">
              {renderContent()}
            </div>
          </>
        ) : (
          // check if this is still needed and remove if not
          <div className="main-content house-content">
            <EmptyPage view={housesStore.view} />
          </div>
        )}
      </div>
    </div>
  );
});

export default inject("housesStore", "criteriaStore")(Houses);
