import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import _, { get as lodashGet } from "lodash";
import { CRITERIA_MAP } from "containers/Dashboard/constants";
import {
  IconView,
  PlaceDescriptionView,
  PlaceIconView,
  PlaceRatingView
} from "containers/HouseDetails/Views";
import { getValueClassnameByScore } from "utils/helpers/housesHelper/common";
import { getHouseParameterValue } from "utils/helpers/housesHelper/getter";
import {
  CRITERIA_CARDS_DATA,
  DISTANCE_CRITERIA,
  GROCERY_AND_PARKS,
  PLACES_CARDS_DATA,
  PLACES_MOCK_DATA
} from "./constants";
import "./styles.scss";

const NeighborhoodInfoSection = ({ houseDetails }) => {
  const checkIsPlacesExist = placesData => (
    !_.isNull(placesData) &&
    _.isArray(placesData) &&
    !_.isEmpty(placesData) &&
    _.has(placesData[0], "name")
  );

  const checkIsLongTitle = key => [CRITERIA_MAP.transit, CRITERIA_MAP.custom_distance].includes(key);

  const renderCriteriaPill = item => {
    const isDistanceCriteria = DISTANCE_CRITERIA.includes(item.key);
    const isParksOrGrocery = GROCERY_AND_PARKS.includes(item.key)
    const pillClassName = getValueClassnameByScore(_.has(houseDetails, item.key) ? houseDetails[item.key].score : -1);  // temporary
    const value = isParksOrGrocery ?
      ~~(lodashGet(houseDetails, item.valueKey) / 60) :
      lodashGet(houseDetails, item.valueKey);
    return (
      <div className={classNames("criteria-card__pill", pillClassName)}>
        {isDistanceCriteria && value ? (
          <FormattedMessage id="houseDetails.min" values={{ value }} />
        ) : (
          getHouseParameterValue(value)
        )}
      </div>
    );
  };

  const renderCriteriaInfo = () => (
    <div className="neighborhood-info-criteria">
      {CRITERIA_CARDS_DATA.map(item => (
        <div className="criteria-card-wrapper">
          <div className="criteria-card">
            <IconView
              customIconClass="criteria-card__icon"
              customWrapperClass="criteria-card__icon-wrapper"
              icon={item.icon}
            />
            <div className={classNames("criteria-card__title", { is_long_title: checkIsLongTitle(item.key) })}>
              <FormattedMessage id={item.title} />
            </div>
            {renderCriteriaPill(item)}
          </div>
          <div className="criteria-card-shadow-wrapper">
            <div className="criteria-card-shadow" />
          </div>
        </div>
      ))}
    </div>
  );

  const renderPlaceCard = (isDataExist, item, placesData) => (
    <div className="places-card-container">
      <div className={classNames("places-card", { noData: !isDataExist } )}>
        <div className="places-card__title">
          <FormattedMessage id={item.title}/>
        </div>
        <div className="places-card__description">
          {placesData.slice(0, 3).map(placeItem => (
            <div className="place-info">
              <div className="place-info__left">
                <div className="place-info__left__icon">
                  <PlaceIconView icon={item.icon} />
                </div>
                <PlaceDescriptionView kind={item.kind} isDataExist={isDataExist} item={placeItem} />
              </div>
              <PlaceRatingView kind={item.kind} isMap={false} item={placeItem} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderPlacesInfo = () => (
    <div className="neighborhood-info-places">
      {PLACES_CARDS_DATA.map(item => {
        let placesData = lodashGet(houseDetails, item.valueKey || item.key, null);
        const isDataExist = checkIsPlacesExist(placesData);
        placesData = isDataExist ? placesData : PLACES_MOCK_DATA[item.key];
        return (
          <div className="places-card-wrapper">
            {renderPlaceCard(isDataExist, item, placesData)}
            <div className="places-card-shadow-wrapper">
              <div className="places-card-shadow"/>
            </div>
            {!isDataExist && (
              <div className="places-card-no-data">
                <FormattedMessage
                  id="houseDetails.dataComingSoon"
                  values={{ value: <FormattedMessage id={item.title} /> }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="neighborhood-info-container">
      <div className="neighborhood-info-title">
        <FormattedMessage id="houseDetails.neighborhoodDetailsTitle" />
      </div>
      {renderCriteriaInfo()}
      {renderPlacesInfo()}
    </div>
  );
};

NeighborhoodInfoSection.propTypes = {
  houseDetails: PropTypes.object,
};

export default NeighborhoodInfoSection;
