import _ from "lodash";

const localStorageStrToObjectOrNull = key => {
  const data = localStorage.getItem(key);
  if (_.isNull(data)) {
    return null;
  }
  return JSON.parse(data);
};

const localStorageStrToNumberOrNull = key => {
  const data = localStorage.getItem(key);
  if (_.isNull(data)) {
    return null;
  }
  const stepNumber = parseInt(data);
  return _.isNumber(stepNumber) ? stepNumber : null;
};

const setObjectToLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export { localStorageStrToObjectOrNull, localStorageStrToNumberOrNull, setObjectToLocalStorage };
