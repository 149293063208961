import React from "react";
import HelmetIntl from "components/Meta/HelmetIntl";
import Onboarding from "containers/Onboarding";

const OnboardingPage = () => (
  <>
    <HelmetIntl id="helmet.onboardingTitle" />
    <Onboarding />
  </>
);

export default OnboardingPage;
