import React from "react";

const ImageSignUpStart = () => (
  <svg width="1408" height="874" viewBox="0 0 1408 874" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath={"url(#clip0_1614_3501)"}>
  <path d="M1041.11 385.782L1348.26 388.013L1447.23 509.771H943.254L1041.11 385.782Z" fill="#E0E0E0"/>
  <path d="M965.127 884.018H1412.18V509.635L965.127 509.635V884.018Z" fill="#F5F5F5"/>
  <path d="M1199.61 440.62L1175.99 479.843H1069.57V440.62L1115.9 356.412L1175.99 439.876L1199.61 440.62Z" fill="#F5F5F5"/>
  <path d="M926.317 883.986H943.246V751.818H926.317V883.986Z" fill="#E0E0E0"/>
  <path d="M1064.85 883.982H1111.36V774.121H1064.85V883.982Z" fill="#E0E0E0"/>
  <path d="M1132.39 438.022C1139.14 431.27 1139.14 420.324 1132.39 413.573C1125.63 406.822 1114.67 406.822 1107.92 413.573C1101.16 420.324 1101.16 431.27 1107.92 438.022C1114.67 444.773 1125.63 444.773 1132.39 438.022Z" fill="white"/>
  <path d="M1121.77 883.986H1138.7V751.818H1121.77V883.986Z" fill="#E0E0E0"/>
  <path d="M1070.13 835.265C1070.13 836.566 1071.25 837.681 1072.55 837.681C1073.85 837.681 1074.97 836.566 1074.97 835.265C1074.97 833.964 1073.85 832.848 1072.55 832.848C1071.25 832.848 1070.13 833.964 1070.13 835.265Z" fill="#FAFAFA"/>
  <path d="M965.207 672.796C965.207 672.982 1065.11 673.168 1188.08 673.168C1311.24 673.168 1410.95 672.982 1410.95 672.796C1410.95 672.61 1311.24 672.424 1188.08 672.424C1065.11 672.424 965.207 672.61 965.207 672.796Z" fill="#E0E0E0"/>
  <path d="M965.207 686.181C965.207 686.367 1065.11 686.553 1188.08 686.553C1311.24 686.553 1410.95 686.367 1410.95 686.181C1410.95 685.995 1311.24 685.809 1188.08 685.809C1065.11 685.623 965.207 685.809 965.207 686.181Z" fill="#E0E0E0"/>
  <path d="M965.207 699.379C965.207 699.565 1065.11 699.751 1188.08 699.751C1311.24 699.751 1410.95 699.565 1410.95 699.379C1410.95 699.193 1311.24 699.007 1188.08 699.007C1065.11 698.821 965.207 699.007 965.207 699.379Z" fill="#E0E0E0"/>
  <path d="M965.207 712.577C965.207 712.763 1065.11 712.949 1188.08 712.949C1311.24 712.949 1410.95 712.763 1410.95 712.577C1410.95 712.391 1311.24 712.205 1188.08 712.205C1065.11 712.019 965.207 712.205 965.207 712.577Z" fill="#E0E0E0"/>
  <path d="M965.207 725.775C965.207 725.961 1065.11 726.147 1188.08 726.147C1311.24 726.147 1410.95 725.961 1410.95 725.775C1410.95 725.589 1311.24 725.403 1188.08 725.403C1065.11 725.403 965.207 725.589 965.207 725.775Z" fill="#E0E0E0"/>
  <path d="M965.207 738.973C965.207 739.159 1065.11 739.345 1188.08 739.345C1311.24 739.345 1410.95 739.159 1410.95 738.973C1410.95 738.787 1311.24 738.602 1188.08 738.602C1065.11 738.602 965.207 738.787 965.207 738.973Z" fill="#E0E0E0"/>
  <path d="M965.207 752.172C965.207 752.357 1065.11 752.543 1188.08 752.543C1311.24 752.543 1410.95 752.357 1410.95 752.172C1410.95 751.986 1311.24 751.8 1188.08 751.8C1065.11 751.8 965.207 751.986 965.207 752.172Z" fill="#E0E0E0"/>
  <path d="M965.207 765.37C965.207 765.556 1065.11 765.742 1188.08 765.742C1311.24 765.742 1410.95 765.556 1410.95 765.37C1410.95 765.184 1311.24 764.998 1188.08 764.998C1065.11 764.998 965.207 765.184 965.207 765.37Z" fill="#E0E0E0"/>
  <path d="M1057.68 651.284H1169.48V535.474H1057.68V651.284Z" fill="white"/>
  <path d="M1057.67 651.233C1057.67 651.233 1057.67 651.047 1057.67 650.675C1057.67 650.304 1057.67 649.746 1057.67 649.002C1057.67 647.515 1057.67 645.47 1057.67 642.682C1057.67 637.291 1057.67 629.298 1057.85 619.074C1057.85 598.812 1058.04 569.813 1058.04 535.238L1057.67 535.609C1090.22 535.609 1128.55 535.609 1169.47 535.609L1169.1 535.238C1169.1 577.806 1169.1 617.587 1169.1 651.047L1169.47 650.675C1135.99 650.675 1108.08 650.861 1088.55 650.861C1078.69 650.861 1071.06 650.861 1065.85 651.047C1063.25 651.047 1061.2 651.047 1059.9 651.047C1059.15 651.047 1058.78 651.047 1058.41 651.047C1057.85 651.233 1057.67 651.233 1057.67 651.233C1057.67 651.233 1057.85 651.233 1058.22 651.233C1058.6 651.233 1059.15 651.233 1059.71 651.233C1061.01 651.233 1063.06 651.233 1065.66 651.233C1070.87 651.233 1078.5 651.233 1088.36 651.419C1107.9 651.419 1135.99 651.605 1169.47 651.605H1169.85V651.233C1169.85 617.587 1169.85 577.806 1169.85 535.424V535.052H1169.47C1128.55 535.052 1090.22 535.052 1057.67 535.052H1057.29V535.424C1057.29 570.185 1057.48 599.184 1057.48 619.446C1057.48 629.484 1057.48 637.477 1057.67 643.054C1057.67 645.656 1057.67 647.887 1057.67 649.188C1057.67 649.932 1057.67 650.304 1057.67 650.675C1057.67 651.047 1057.67 651.233 1057.67 651.233Z" fill="#E0E0E0"/>
  <path d="M1111.43 649.746C1111.62 649.746 1111.8 624.279 1111.8 593.05C1111.8 561.634 1111.62 536.353 1111.43 536.353C1111.24 536.353 1111.06 561.82 1111.06 593.05C1111.06 624.279 1111.24 649.746 1111.43 649.746Z" fill="#E0E0E0"/>
  <path d="M1058.97 597.14C1058.97 597.325 1083.53 597.511 1113.66 597.511C1143.8 597.511 1168.36 597.325 1168.36 597.14C1168.36 596.954 1143.8 596.768 1113.66 596.768C1083.34 596.582 1058.97 596.768 1058.97 597.14Z" fill="#E0E0E0"/>
  <path d="M993.599 627.753H1028.02V560.089H993.599V627.753Z" fill="#FAFAFA"/>
  <path d="M993.481 627.811C993.481 627.811 993.481 627.625 993.481 627.439C993.481 627.253 993.481 626.882 993.481 626.51C993.481 625.58 993.481 624.279 993.481 622.792C993.481 619.446 993.481 614.799 993.667 608.85C993.667 596.953 993.854 580.037 993.854 560.333L993.481 560.705C1004.09 560.705 1015.81 560.705 1027.9 560.705C1028.08 560.705 1027.15 559.961 1027.53 560.333V560.519V560.891V561.448V562.749V565.166C1027.53 566.839 1027.53 568.326 1027.53 569.999C1027.53 573.159 1027.53 576.319 1027.53 579.48C1027.53 585.614 1027.53 591.562 1027.53 597.325C1027.53 608.664 1027.53 619.074 1027.53 627.997L1027.9 627.625C1017.29 627.625 1008.74 627.811 1002.78 627.811C999.807 627.811 997.574 627.811 995.9 627.811C995.156 627.811 994.598 627.811 994.04 627.811C993.667 627.811 993.481 627.811 993.481 627.811C993.481 627.811 993.667 627.811 994.04 627.811C994.412 627.811 995.156 627.811 995.714 627.811C997.388 627.811 999.621 627.811 1002.6 627.811C1008.55 627.811 1017.29 627.997 1027.9 627.997H1028.27V627.625C1028.27 618.702 1028.27 608.293 1028.27 596.953C1028.27 591.191 1028.27 585.242 1028.27 579.108C1028.27 575.948 1028.27 572.973 1028.27 569.627C1028.27 567.954 1028.27 566.467 1028.27 564.794V562.378V561.076V560.519V560.147V559.961C1028.64 560.333 1027.71 559.403 1027.9 559.589C1015.81 559.589 1004.09 559.589 993.481 559.589H993.109V559.961C993.109 579.665 993.295 596.581 993.295 608.664C993.295 614.613 993.295 619.26 993.481 622.606C993.481 624.093 993.481 625.394 993.481 626.324C993.481 626.696 993.481 627.067 993.481 627.253C993.481 627.811 993.481 627.811 993.481 627.811Z" fill="#E0E0E0"/>
  <path d="M1010.6 627.811C1010.78 627.811 1010.97 612.568 1010.97 593.793C1010.97 575.018 1010.78 559.775 1010.6 559.775C1010.41 559.775 1010.23 575.018 1010.23 593.793C1010.23 612.568 1010.41 627.811 1010.6 627.811Z" fill="#E0E0E0"/>
  <path d="M993.855 596.396C993.855 596.582 1001.67 596.767 1011.34 596.767C1021.02 596.767 1028.83 596.582 1028.83 596.396C1028.83 596.21 1021.02 596.024 1011.34 596.024C1001.67 596.024 993.855 596.21 993.855 596.396Z" fill="#E0E0E0"/>
  <path d="M1201.15 627.753H1235.56V560.089H1201.15V627.753Z" fill="white"/>
  <path d="M1200.92 627.811C1200.92 627.811 1200.92 627.625 1200.92 627.439C1200.92 627.253 1200.92 626.882 1200.92 626.51C1200.92 625.58 1200.92 624.279 1200.92 622.792C1200.92 619.446 1200.92 614.799 1201.1 608.85C1201.1 596.953 1201.29 580.037 1201.29 560.333L1200.92 560.705C1211.52 560.705 1223.24 560.705 1235.33 560.705C1235.52 560.705 1234.59 559.961 1234.96 560.333V560.519V560.891V561.448V562.749V565.166C1234.96 566.839 1234.96 568.326 1234.96 569.999C1234.96 573.159 1234.96 576.319 1234.96 579.48C1234.96 585.614 1234.96 591.562 1234.96 597.325C1234.96 608.664 1234.96 619.074 1234.96 627.997L1235.33 627.625C1224.73 627.625 1216.17 627.811 1210.22 627.811C1207.24 627.811 1205.01 627.811 1203.33 627.811C1202.59 627.811 1202.03 627.811 1201.47 627.811C1201.29 627.811 1200.92 627.811 1200.92 627.811C1200.92 627.811 1201.1 627.811 1201.47 627.811C1201.85 627.811 1202.59 627.811 1203.15 627.811C1204.82 627.811 1207.05 627.811 1210.03 627.811C1215.98 627.811 1224.73 627.997 1235.33 627.997H1235.7V627.625C1235.7 618.702 1235.7 608.293 1235.7 596.953C1235.7 591.191 1235.7 585.242 1235.7 579.108C1235.7 575.948 1235.7 572.973 1235.7 569.627C1235.7 567.954 1235.7 566.467 1235.7 564.794V562.378V561.076V560.519V560.147V559.961C1236.08 560.333 1235.15 559.403 1235.33 559.589C1223.24 559.589 1211.52 559.589 1200.92 559.589H1200.54V559.961C1200.54 579.665 1200.73 596.581 1200.73 608.664C1200.73 614.613 1200.73 619.26 1200.92 622.606C1200.92 624.093 1200.92 625.394 1200.92 626.324C1200.92 626.696 1200.92 627.067 1200.92 627.253C1200.92 627.811 1200.92 627.811 1200.92 627.811Z" fill="#E0E0E0"/>
  <path d="M1218.03 627.811C1218.21 627.811 1218.4 612.568 1218.4 593.793C1218.4 575.018 1218.21 559.775 1218.03 559.775C1217.84 559.775 1217.66 575.018 1217.66 593.793C1217.66 612.568 1217.84 627.811 1218.03 627.811Z" fill="#E0E0E0"/>
  <path d="M1201.47 596.396C1201.47 596.582 1209.29 596.767 1218.96 596.767C1228.63 596.767 1236.45 596.582 1236.45 596.396C1236.45 596.21 1228.63 596.024 1218.96 596.024C1209.1 596.024 1201.47 596.21 1201.47 596.396Z" fill="#E0E0E0"/>
  <path d="M1152.4 844.534H1186.82V776.87H1152.4V844.534Z" fill="#FAFAFA"/>
  <path d="M1152.36 844.558C1152.36 844.558 1152.36 844.372 1152.36 844.187C1152.36 844.001 1152.36 843.629 1152.36 843.257C1152.36 842.328 1152.36 841.026 1152.36 839.539C1152.36 836.193 1152.36 831.546 1152.55 825.598C1152.55 813.701 1152.73 796.785 1152.73 777.08L1152.36 777.452C1162.96 777.452 1174.68 777.452 1186.78 777.452C1186.96 777.452 1186.03 776.708 1186.41 777.08V777.266V777.638V778.196V779.497V781.913C1186.41 783.586 1186.41 785.074 1186.41 786.747C1186.41 789.907 1186.41 793.067 1186.41 796.227C1186.41 802.361 1186.41 808.31 1186.41 814.072C1186.41 825.412 1186.41 835.821 1186.41 844.744L1186.78 844.372C1176.17 844.372 1167.62 844.558 1161.66 844.558C1158.69 844.558 1156.45 844.558 1154.78 844.558C1154.03 844.558 1153.48 844.558 1152.92 844.558C1152.55 844.558 1152.36 844.558 1152.36 844.558C1152.36 844.558 1152.55 844.558 1152.92 844.558C1153.29 844.558 1154.03 844.558 1154.59 844.558C1156.27 844.558 1158.5 844.558 1161.48 844.558C1167.43 844.558 1176.17 844.744 1186.78 844.744H1187.15V844.372C1187.15 835.45 1187.15 825.04 1187.15 813.701C1187.15 807.938 1187.15 801.989 1187.15 795.855C1187.15 792.695 1187.15 789.721 1187.15 786.375C1187.15 784.702 1187.15 783.215 1187.15 781.542V779.125V777.824V777.266V777.08C1187.52 777.452 1186.59 776.523 1186.78 776.708C1174.68 776.708 1162.96 776.708 1152.36 776.708H1151.99V777.08C1151.99 796.785 1152.17 813.701 1152.17 825.783C1152.17 831.732 1152.17 836.379 1152.36 839.725C1152.36 841.212 1152.36 842.514 1152.36 843.443C1152.36 843.815 1152.36 844.187 1152.36 844.372C1152.36 844.558 1152.36 844.558 1152.36 844.558Z" fill="#E0E0E0"/>
  <path d="M1169.48 844.559C1169.66 844.559 1169.85 829.316 1169.85 810.541C1169.85 791.766 1169.66 776.523 1169.48 776.523C1169.29 776.523 1169.11 791.766 1169.11 810.541C1169.11 829.316 1169.29 844.559 1169.48 844.559Z" fill="#E0E0E0"/>
  <path d="M1152.73 813.143C1152.73 813.329 1160.55 813.515 1170.22 813.515C1179.9 813.515 1187.71 813.329 1187.71 813.143C1187.71 812.957 1179.9 812.771 1170.22 812.771C1160.55 812.771 1152.73 812.957 1152.73 813.143Z" fill="#E0E0E0"/>
  <path d="M1004.08 844.535H1038.5V776.871H1004.08V844.535Z" fill="#FAFAFA"/>
  <path d="M1004.09 844.559C1004.09 844.559 1004.09 844.373 1004.09 844.187C1004.09 844.001 1004.09 843.629 1004.09 843.258C1004.09 842.328 1004.09 841.027 1004.09 839.54C1004.09 836.194 1004.09 831.547 1004.27 825.598C1004.27 813.701 1004.46 796.785 1004.46 777.081L1004.09 777.453C1014.69 777.453 1026.41 777.453 1038.5 777.453C1038.69 777.453 1037.76 776.709 1038.13 777.081V777.267V777.638V778.196V779.497V781.914C1038.13 783.587 1038.13 785.074 1038.13 786.747C1038.13 789.907 1038.13 793.067 1038.13 796.227C1038.13 802.362 1038.13 808.31 1038.13 814.073C1038.13 825.412 1038.13 835.822 1038.13 844.745L1038.5 844.373C1027.9 844.373 1019.34 844.559 1013.39 844.559C1010.41 844.559 1008.18 844.559 1006.51 844.559C1005.76 844.559 1005.2 844.559 1004.65 844.559C1004.27 844.559 1004.09 844.559 1004.09 844.559C1004.09 844.559 1004.27 844.559 1004.65 844.559C1005.02 844.559 1005.76 844.559 1006.32 844.559C1007.99 844.559 1010.23 844.559 1013.2 844.559C1019.16 844.559 1027.9 844.745 1038.5 844.745H1038.88V844.373C1038.88 835.45 1038.88 825.04 1038.88 813.701C1038.88 807.939 1038.88 801.99 1038.88 795.856C1038.88 792.695 1038.88 789.721 1038.88 786.375C1038.88 784.702 1038.88 783.215 1038.88 781.542V779.126V777.824V777.267V777.081C1039.25 777.452 1038.32 776.523 1038.5 776.709C1026.41 776.709 1014.69 776.709 1004.09 776.709H1003.71V777.081C1003.71 796.785 1003.9 813.701 1003.9 825.784C1003.9 831.732 1003.9 836.38 1004.09 839.726C1004.09 841.213 1004.09 842.514 1004.09 843.444C1004.09 843.815 1004.09 844.187 1004.09 844.373C1004.09 844.559 1004.09 844.559 1004.09 844.559Z" fill="#E0E0E0"/>
  <path d="M1021.2 844.559C1021.39 844.559 1021.58 829.316 1021.58 810.541C1021.58 791.766 1021.39 776.523 1021.2 776.523C1021.02 776.523 1020.83 791.766 1020.83 810.541C1020.83 829.316 1021.02 844.559 1021.2 844.559Z" fill="#E0E0E0"/>
  <path d="M1004.46 813.144C1004.46 813.33 1012.27 813.516 1021.95 813.516C1031.62 813.516 1039.44 813.33 1039.44 813.144C1039.44 812.958 1031.62 812.772 1021.95 812.772C1012.27 812.772 1004.46 812.958 1004.46 813.144Z" fill="#E0E0E0"/>
  <path d="M1271.98 883.968C1272.17 883.968 1272.35 853.296 1272.35 815.374C1272.35 777.453 1272.17 746.781 1271.98 746.781C1271.8 746.781 1271.61 777.453 1271.61 815.374C1271.61 853.296 1271.8 883.968 1271.98 883.968Z" fill="#E0E0E0"/>
  <path d="M1249.84 649.746C1249.84 649.746 1249.84 649.374 1249.84 648.817C1249.84 648.073 1249.84 647.329 1249.84 646.214C1249.84 643.983 1249.84 640.823 1250.03 636.92L1249.66 637.291C1258.21 637.291 1270.68 637.477 1284.45 637.477C1284.82 637.849 1283.89 636.92 1284.07 637.105V637.291V637.477V637.849V638.778C1284.07 639.336 1284.07 639.894 1284.07 640.451C1284.07 641.567 1284.07 642.682 1284.07 643.798C1284.07 646.028 1284.07 648.073 1284.07 650.118L1284.45 649.746C1274.59 649.746 1265.84 649.932 1259.7 649.932C1256.73 649.932 1254.31 649.932 1252.45 650.118C1251.7 650.118 1250.96 650.118 1250.4 650.118C1250.03 649.746 1249.84 649.746 1249.84 649.746C1249.84 649.746 1250.03 649.746 1250.4 649.746C1250.96 649.746 1251.52 649.746 1252.26 649.746C1254.12 649.746 1256.54 649.746 1259.52 649.932C1265.66 649.932 1274.4 650.118 1284.45 650.118H1284.82V649.746C1284.82 647.701 1284.82 645.656 1284.82 643.426C1284.82 642.31 1284.82 641.195 1284.82 640.08C1284.82 639.522 1284.82 638.964 1284.82 638.407V637.477V637.105V636.92V636.734C1284.82 636.734 1284.07 635.99 1284.45 636.362C1270.68 636.362 1258.4 636.362 1249.66 636.548H1249.29V636.92C1249.29 640.823 1249.47 644.169 1249.47 646.4C1249.47 647.515 1249.47 648.259 1249.47 649.002C1249.66 649.374 1249.66 649.746 1249.84 649.746Z" fill="#E0E0E0"/>
  <path d="M1032.74 546.949C1032.74 546.949 1032.74 546.577 1032.74 546.019C1032.74 545.276 1032.74 544.532 1032.74 543.603C1032.74 541.372 1032.93 538.584 1032.93 534.866L1032.55 535.237C1039.25 535.237 1047.99 535.237 1057.67 535.423C1058.04 535.795 1057.11 534.866 1057.3 535.052V535.237V535.423V535.795V536.539C1057.3 537.096 1057.3 537.654 1057.3 538.026C1057.3 539.141 1057.3 540.071 1057.3 541C1057.3 543.045 1057.3 544.904 1057.3 546.763L1057.67 546.391C1050.41 546.391 1044.27 546.577 1039.81 546.577C1037.76 546.577 1035.9 546.577 1034.6 546.763C1033.3 546.949 1032.74 546.949 1032.74 546.949C1032.74 546.949 1033.3 546.949 1034.6 547.135C1035.9 547.135 1037.58 547.134 1039.81 547.32C1044.27 547.32 1050.6 547.506 1057.85 547.506H1058.23V547.135C1058.23 545.276 1058.23 543.417 1058.23 541.372C1058.23 540.442 1058.23 539.327 1058.23 538.398C1058.23 537.84 1058.23 537.282 1058.23 536.911V536.167V535.795V535.609V535.423C1058.23 535.423 1057.48 534.68 1057.85 535.052C1048.18 535.052 1039.44 535.052 1032.74 535.237H1032.37V535.609C1032.37 539.141 1032.55 542.301 1032.55 544.532C1032.55 545.461 1032.55 546.205 1032.55 546.949C1032.55 546.763 1032.55 546.949 1032.74 546.949Z" fill="#E0E0E0"/>
  <path d="M1227.7 661.894H1261.19V649.625H1227.7V661.894Z" fill="#E0E0E0"/>
  <path d="M1186.36 559.849H1219.85V547.58H1186.36V559.849Z" fill="#E0E0E0"/>
  <path d="M1024.44 650.756H1057.93V638.488H1024.44V650.756Z" fill="#E0E0E0"/>
  <path d="M965.239 605.537H993.145V593.268H965.239V605.537Z" fill="#E0E0E0"/>
  <path d="M1271.98 752.543H904L983.996 686.552H1183.24L1271.98 752.543Z" fill="#E0E0E0"/>
  <path d="M1199.61 440.62H1218.78L1152.17 348.046L1110.13 348.604L1175.99 439.876L1199.61 440.62Z" fill="#E0E0E0"/>
  <path d="M1069.57 440.62H1056.74L1110.13 348.604L1115.9 356.411L1069.57 440.62Z" fill="#EBEBEB"/>
  <path d="M1175.99 479.842V439.876H1199.62L1175.99 479.842Z" fill="#EBEBEB"/>
  <path d="M1183.24 686.552L1113.48 752.543H1272.54L1183.24 686.552Z" fill="#EBEBEB"/>
  </g>
  <g clipPath="url(#clip1_1614_3501)">
  <path d="M928.752 902.185H777.984V702.589H928.752V902.185Z" fill="#F5F5F5"/>
  <path d="M858.059 902.185H928.749V702.589H858.059V902.185Z" fill="#ECECEC"/>
  <path d="M823.154 762.669H809.598V729.613H823.154V762.669Z" fill="#ECECEC"/>
  <path d="M823.154 892.051H809.598V858.995H823.154V892.051Z" fill="#ECECEC"/>
  <path d="M858.062 775.645C858.062 775.93 840.134 776.161 818.024 776.161C795.91 776.161 777.984 775.93 777.984 775.645C777.984 775.358 795.91 775.127 818.024 775.127C840.134 775.127 858.062 775.358 858.062 775.645Z" fill="#ECECEC"/>
  <path d="M823.154 827.853H809.598V794.794H823.154V827.853Z" fill="#ECECEC"/>
  <path d="M858.062 840.826C858.062 841.11 840.134 841.341 818.024 841.341C795.91 841.341 777.984 841.11 777.984 840.826C777.984 840.538 795.91 840.307 818.024 840.307C840.134 840.307 858.062 840.538 858.062 840.826Z" fill="#ECECEC"/>
  <path d="M886.628 762.669H873.074V729.613H886.628V762.669Z" fill="#E1E1E1"/>
  <path d="M916.041 762.669H902.484V729.613H916.041V762.669Z" fill="#E1E1E1"/>
  <path d="M886.628 825.396H873.074V792.339H886.628V825.396Z" fill="#E1E1E1"/>
  <path d="M916.041 825.396H902.484V792.339H916.041V825.396Z" fill="#E1E1E1"/>
  <path d="M886.628 888.125H873.074V855.069H886.628V888.125Z" fill="#E1E1E1"/>
  <path d="M916.041 888.125H902.484V855.069H916.041V888.125Z" fill="#E1E1E1"/>
  <path d="M940.087 702.837H766.648V695.643H940.087V702.837Z" fill="#E1E1E1"/>
  <path d="M929.802 703.517C929.802 703.802 896.105 704.032 854.548 704.032C812.97 704.032 779.281 703.802 779.281 703.517C779.281 703.232 812.97 703.002 854.548 703.002C896.105 703.002 929.802 703.232 929.802 703.517Z" fill="#F5F5F5"/>
  <path d="M610.624 904.548H341.832V198.389H610.624V904.548Z" fill="#ECECEC"/>
  <path d="M610.624 904.548H476.227V198.389H610.624V904.548Z" fill="#E1E1E1"/>
  <path d="M467.438 198.389H372.52V114.45H467.438V198.389Z" fill="#ECECEC"/>
  <path d="M467.442 198.389H444.816V114.45H467.442V198.389Z" fill="#E1E1E1"/>
  <path d="M618.486 198.389H319.43V193.491H618.486V198.389Z" fill="#E1E1E1"/>
  <path d="M417.64 283.15H394.887V234.998H417.64V283.15Z" fill="white"/>
  <path d="M476.226 302.05C476.226 302.556 446.14 302.969 409.033 302.969C371.915 302.969 341.832 302.556 341.832 302.05C341.832 301.543 371.915 301.13 409.033 301.13C446.14 301.13 476.226 301.543 476.226 302.05Z" fill="#E1E1E1"/>
  <path d="M553.202 283.15H530.449V234.998H553.202V283.15Z" fill="#F5F5F5"/>
  <path d="M611.793 302.05C611.793 302.556 581.707 302.969 544.6 302.969C507.479 302.969 477.398 302.556 477.398 302.05C477.398 301.543 507.479 301.13 544.6 301.13C581.707 301.13 611.793 301.543 611.793 302.05Z" fill="#ECECEC"/>
  <path d="M553.202 378.751H530.449V330.599H553.202V378.751Z" fill="#F5F5F5"/>
  <path d="M611.793 397.651C611.793 398.157 581.707 398.57 544.6 398.57C507.479 398.57 477.398 398.157 477.398 397.651C477.398 397.144 507.479 396.731 544.6 396.731C581.707 396.731 611.793 397.144 611.793 397.651Z" fill="#ECECEC"/>
  <path d="M553.202 477.239H530.449V429.087H553.202V477.239Z" fill="#F5F5F5"/>
  <path d="M611.793 496.138C611.793 496.645 581.707 497.055 544.6 497.055C507.479 497.055 477.398 496.645 477.398 496.138C477.398 495.628 507.479 495.218 544.6 495.218C581.707 495.218 611.793 495.628 611.793 496.138Z" fill="#ECECEC"/>
  <path d="M553.202 575.724H530.449V527.572H553.202V575.724Z" fill="#F5F5F5"/>
  <path d="M611.793 594.622C611.793 595.132 581.707 595.542 544.6 595.542C507.479 595.542 477.398 595.132 477.398 594.622C477.398 594.116 507.479 593.706 544.6 593.706C581.707 593.706 611.793 594.116 611.793 594.622Z" fill="#ECECEC"/>
  <path d="M553.202 671.396H530.449V623.244H553.202V671.396Z" fill="#F5F5F5"/>
  <path d="M611.793 690.293C611.793 690.8 581.707 691.213 544.6 691.213C507.479 691.213 477.398 690.8 477.398 690.293C477.398 689.784 507.479 689.374 544.6 689.374C581.707 689.374 611.793 689.784 611.793 690.293Z" fill="#ECECEC"/>
  <path d="M553.202 767.449H530.449V719.297H553.202V767.449Z" fill="#F5F5F5"/>
  <path d="M553.202 864.764H530.449V816.612H553.202V864.764Z" fill="#F5F5F5"/>
  <path d="M611.793 786.347C611.793 786.857 581.707 787.267 544.6 787.267C507.479 787.267 477.398 786.857 477.398 786.347C477.398 785.841 507.479 785.428 544.6 785.428C581.707 785.428 611.793 785.841 611.793 786.347Z" fill="#ECECEC"/>
  <path d="M417.64 380.212H394.887V332.06H417.64V380.212Z" fill="white"/>
  <path d="M476.226 399.111C476.226 399.617 446.14 400.03 409.033 400.03C371.915 400.03 341.832 399.617 341.832 399.111C341.832 398.601 371.915 398.191 409.033 398.191C446.14 398.191 476.226 398.601 476.226 399.111Z" fill="#E1E1E1"/>
  <path d="M417.64 477.273H394.887V429.121H417.64V477.273Z" fill="white"/>
  <path d="M476.226 496.172C476.226 496.678 446.14 497.088 409.033 497.088C371.915 497.088 341.832 496.678 341.832 496.172C341.832 495.662 371.915 495.252 409.033 495.252C446.14 495.252 476.226 495.662 476.226 496.172Z" fill="#E1E1E1"/>
  <path d="M417.64 574.334H394.887V526.182H417.64V574.334Z" fill="white"/>
  <path d="M476.226 593.233C476.226 593.742 446.14 594.149 409.033 594.149C371.915 594.149 341.832 593.742 341.832 593.233C341.832 592.723 371.915 592.313 409.033 592.313C446.14 592.313 476.226 592.723 476.226 593.233Z" fill="#E1E1E1"/>
  <path d="M417.64 671.396H394.887V623.244H417.64V671.396Z" fill="white"/>
  <path d="M476.226 690.293C476.226 690.8 446.14 691.213 409.033 691.213C371.915 691.213 341.832 690.8 341.832 690.293C341.832 689.784 371.915 689.374 409.033 689.374C446.14 689.374 476.226 689.784 476.226 690.293Z" fill="#E1E1E1"/>
  <path d="M417.64 768.454H394.887V720.302H417.64V768.454Z" fill="white"/>
  <path d="M476.226 787.354C476.226 787.861 446.14 788.271 409.033 788.271C371.915 788.271 341.832 787.861 341.832 787.354C341.832 786.845 371.915 786.435 409.033 786.435C446.14 786.435 476.226 786.845 476.226 787.354Z" fill="#E1E1E1"/>
  <path d="M417.64 865.516H394.887V817.364H417.64V865.516Z" fill="white"/>
  <path d="M236.055 903.324H29.9375V630.453H236.055V903.324Z" fill="#ECECEC"/>
  <path d="M139.41 903.324H236.054V630.453H139.41V903.324Z" fill="#E1E1E1"/>
  <path d="M91.6924 712.589H73.1602V667.396H91.6924V712.589Z" fill="#F5F5F5"/>
  <path d="M91.6924 889.463H73.1602V844.272H91.6924V889.463Z" fill="#F5F5F5"/>
  <path d="M139.411 730.327C139.411 730.834 114.902 731.244 84.6773 731.244C54.4409 731.244 29.9375 730.834 29.9375 730.327C29.9375 729.817 54.4409 729.407 84.6773 729.407C114.902 729.407 139.411 729.817 139.411 730.327Z" fill="#F5F5F5"/>
  <path d="M91.6924 801.698H73.1602V756.504H91.6924V801.698Z" fill="#F5F5F5"/>
  <path d="M139.411 819.435C139.411 819.942 114.902 820.355 84.6773 820.355C54.4409 820.355 29.9375 819.942 29.9375 819.435C29.9375 818.926 54.4409 818.516 84.6773 818.516C114.902 818.516 139.411 818.926 139.411 819.435Z" fill="#F5F5F5"/>
  <path d="M178.466 712.589H159.934V667.396H178.466V712.589Z" fill="#F5F5F5"/>
  <path d="M218.673 712.589H200.141V667.396H218.673V712.589Z" fill="#F5F5F5"/>
  <path d="M178.466 798.341H159.934V753.15H178.466V798.341Z" fill="#F5F5F5"/>
  <path d="M218.673 798.341H200.141V753.15H218.673V798.341Z" fill="#F5F5F5"/>
  <path d="M178.466 884.099H159.934V838.908H178.466V884.099Z" fill="#F5F5F5"/>
  <path d="M218.673 884.099H200.141V838.908H218.673V884.099Z" fill="#F5F5F5"/>
  <path d="M252 631H-15V621H252V631Z" fill="#E1E1E1"/>
  <path d="M237.495 631.719C237.495 632.229 191.427 632.639 134.611 632.639C77.7744 632.639 31.7148 632.229 31.7148 631.719C31.7148 631.213 77.7744 630.803 134.611 630.803C191.427 630.803 237.495 631.213 237.495 631.719Z" fill="#ECECEC"/>
  <path d="M675.305 292.101L857.988 292.864C857.988 292.864 840.685 253.14 803.722 253.12C775.531 253.103 774.28 270.724 757.368 275.752C740.452 280.783 704.747 249.976 675.305 292.101Z" fill="#ECECEC"/>
  <path d="M-113.059 96.9379L208.073 98.2817C208.073 98.2817 177.652 28.4511 112.675 28.4141C63.1234 28.3856 60.9221 59.3604 31.1906 68.2038C1.46195 77.0444 -61.3029 22.8877 -113.059 96.9379Z" fill="#F5F5F5"/>
  <path d="M821.781 901.926H664.602V488.998H821.781V901.926Z" fill="#3E4E83"/>
  <path d="M821.779 901.926H743.191V488.998H821.779V901.926Z" fill="#1B235E"/>
  <path d="M738.054 488.998H682.551V439.912H738.054V488.998Z" fill="#3E4E83"/>
  <path d="M738.055 488.998H724.828V439.912H738.055V488.998Z" fill="#1B235E"/>
  <path d="M826.378 488.998H651.504V486.133H826.378V488.998Z" fill="#3E4E83"/>
  <path d="M708.933 538.562H695.629V510.403H708.933V538.562Z" fill="#1B235E"/>
  <path d="M743.192 549.612C743.192 549.908 725.599 550.147 703.898 550.147C682.192 550.147 664.602 549.908 664.602 549.612C664.602 549.316 682.192 549.077 703.898 549.077C725.599 549.077 743.192 549.316 743.192 549.612Z" fill="#1B235E"/>
  <path d="M788.203 538.562H774.898V510.404H788.203V538.562Z" fill="#3E4E83"/>
  <path d="M822.463 549.612C822.463 549.908 804.87 550.147 783.169 550.147C761.463 550.147 743.875 549.908 743.875 549.612C743.875 549.316 761.463 549.077 783.169 549.077C804.87 549.077 822.463 549.316 822.463 549.612Z" fill="#3E4E83"/>
  <path d="M788.203 594.464H774.898V566.308H788.203V594.464Z" fill="#3E4E83"/>
  <path d="M822.463 605.516C822.463 605.813 804.87 606.052 783.169 606.052C761.463 606.052 743.875 605.813 743.875 605.516C743.875 605.22 761.463 604.981 783.169 604.981C804.87 604.981 822.463 605.22 822.463 605.516Z" fill="#3E4E83"/>
  <path d="M788.203 652.058H774.898V623.899H788.203V652.058Z" fill="#3E4E83"/>
  <path d="M822.463 663.106C822.463 663.402 804.87 663.644 783.169 663.644C761.463 663.644 743.875 663.402 743.875 663.106C743.875 662.81 761.463 662.571 783.169 662.571C804.87 662.571 822.463 662.81 822.463 663.106Z" fill="#3E4E83"/>
  <path d="M788.203 709.646H774.898V681.486H788.203V709.646Z" fill="#3E4E83"/>
  <path d="M822.463 720.698C822.463 720.994 804.87 721.233 783.169 721.233C761.463 721.233 743.875 720.994 743.875 720.698C743.875 720.402 761.463 720.16 783.169 720.16C804.87 720.16 822.463 720.402 822.463 720.698Z" fill="#3E4E83"/>
  <path d="M788.203 765.59H774.898V737.431H788.203V765.59Z" fill="#3E4E83"/>
  <path d="M822.463 776.641C822.463 776.938 804.87 777.177 783.169 777.177C761.463 777.177 743.875 776.938 743.875 776.641C743.875 776.345 761.463 776.103 783.169 776.103C804.87 776.103 822.463 776.345 822.463 776.641Z" fill="#3E4E83"/>
  <path d="M788.203 821.757H774.898V793.601H788.203V821.757Z" fill="#3E4E83"/>
  <path d="M788.203 878.661H774.898V850.502H788.203V878.661Z" fill="#3E4E83"/>
  <path d="M822.463 832.809C822.463 833.106 804.87 833.345 783.169 833.345C761.463 833.345 743.875 833.106 743.875 832.809C743.875 832.513 761.463 832.274 783.169 832.274C804.87 832.274 822.463 832.513 822.463 832.809Z" fill="#3E4E83"/>
  <path d="M708.933 595.318H695.629V567.159H708.933V595.318Z" fill="#1B235E"/>
  <path d="M743.192 606.37C743.192 606.666 725.599 606.905 703.898 606.905C682.192 606.905 664.602 606.666 664.602 606.37C664.602 606.074 682.192 605.832 703.898 605.832C725.599 605.832 743.192 606.074 743.192 606.37Z" fill="#1B235E"/>
  <path d="M708.933 652.075H695.629V623.916H708.933V652.075Z" fill="#1B235E"/>
  <path d="M743.192 663.125C743.192 663.421 725.599 663.663 703.898 663.663C682.192 663.663 664.602 663.421 664.602 663.125C664.602 662.829 682.192 662.59 703.898 662.59C725.599 662.59 743.192 662.829 743.192 663.125Z" fill="#1B235E"/>
  <path d="M708.933 708.834H695.629V680.675H708.933V708.834Z" fill="#1B235E"/>
  <path d="M743.192 719.883C743.192 720.179 725.599 720.418 703.898 720.418C682.192 720.418 664.602 720.179 664.602 719.883C664.602 719.587 682.192 719.348 703.898 719.348C725.599 719.348 743.192 719.587 743.192 719.883Z" fill="#1B235E"/>
  <path d="M708.933 765.59H695.629V737.431H708.933V765.59Z" fill="#1B235E"/>
  <path d="M743.192 776.641C743.192 776.938 725.599 777.177 703.898 777.177C682.192 777.177 664.602 776.938 664.602 776.641C664.602 776.345 682.192 776.103 703.898 776.103C725.599 776.103 743.192 776.345 743.192 776.641Z" fill="#1B235E"/>
  <path d="M708.933 822.347H695.629V794.188H708.933V822.347Z" fill="#1B235E"/>
  <path d="M743.192 833.396C743.192 833.692 725.599 833.934 703.898 833.934C682.192 833.934 664.602 833.692 664.602 833.396C664.602 833.1 682.192 832.861 703.898 832.861C725.599 832.861 743.192 833.1 743.192 833.396Z" fill="#1B235E"/>
  <path d="M708.933 879.105H695.629V850.947H708.933V879.105Z" fill="#1B235E"/>
  <path d="M395.848 869.134H379.863V835.307H395.848V869.134Z" fill="#3E4E83"/>
  <path d="M426.438 821.838C426.438 822.393 409.163 822.846 387.859 822.846C366.55 822.846 349.277 822.393 349.277 821.838C349.277 821.283 366.55 820.83 387.859 820.83C409.163 820.83 426.438 821.283 426.438 821.838Z" fill="#3E4E83"/>
  <path d="M466.991 902.29H382.199V707H466.991V902.29Z" fill="#939DF4"/>
  <g opacity="0.199997">
  <path opacity="0.199997" d="M466.991 902.29H382.199V707H466.991V902.29Z" fill="black"/>
  </g>
  <path d="M362.367 706.2H571.58L610.672 650.489L651.646 707.772L699.796 707L647.893 636.848L414.625 637.819L362.367 706.2Z" fill="#939DF4"/>
  <g opacity="0.199997">
  <g opacity="0.199997">
  <path opacity="0.199997" d="M362.367 706.2H571.58L610.672 650.489L651.646 707.772L699.796 707L647.893 636.848L414.625 637.819L362.367 706.2Z" fill="white"/>
  </g>
  </g>
  <path d="M466.992 707.001V902.291H675.907V707.001H651.959L610.671 650.487L571.448 706.144L466.992 707.001Z" fill="#939DF4"/>
  <g opacity="0.199997">
  <g opacity="0.199997">
  <path opacity="0.199997" d="M496.508 902.291V817.623H545.376V902.291" fill="black"/>
  </g>
  </g>
  <g opacity="0.699997">
  <path opacity="0.699997" d="M651.644 863.101H574.664V815.239H651.644V863.101Z" fill="white"/>
  </g>
  <g opacity="0.699997">
  <path opacity="0.699997" d="M651.644 767.307H574.664V719.445H651.644V767.307Z" fill="white"/>
  </g>
  <g opacity="0.699997">
  <path opacity="0.699997" d="M540.278 766.014H491.73V719.878H540.278V766.014Z" fill="white"/>
  </g>
  <path d="M596.895 815.24V863.545" stroke="#939DF4" strokeWidth="0.333333" strokeMiterlimit="10"/>
  <path d="M628.902 815.24V863.545" stroke="#939DF4" strokeWidth="0.333333" strokeMiterlimit="10"/>
  <path d="M596.895 840.851H628.904" stroke="#939DF4" strokeWidth="0.333333" strokeMiterlimit="10"/>
  <path d="M596.895 719.447V767.752" stroke="#939DF4" strokeWidth="0.333333" strokeMiterlimit="10"/>
  <path d="M628.902 719.447V767.752" stroke="#939DF4" strokeWidth="0.333333" strokeMiterlimit="10"/>
  <path d="M596.895 745.058H628.904" stroke="#939DF4" strokeWidth="0.333333" strokeMiterlimit="10"/>
  <path d="M516.004 719.878V766.014" stroke="#939DF4" strokeWidth="0.333333" strokeMiterlimit="10"/>
  <path d="M491.73 744.082H540.278" stroke="#939DF4" strokeWidth="0.333333" strokeMiterlimit="10"/>
  <g opacity="0.699997">
  <g opacity="0.699997">
  <path opacity="0.699997" d="M620.847 687.768C620.847 693.477 616.24 698.101 610.552 698.101C604.867 698.101 600.258 693.477 600.258 687.768C600.258 682.063 604.867 677.436 610.552 677.436C616.24 677.436 620.847 682.063 620.847 687.768Z" fill="white"/>
  </g>
  </g>
  <path d="M537.125 675.095L550.117 659.014V620.328H522.91V675.394L537.125 675.095Z" fill="#939DF4"/>
  <g opacity="0.199997">
  <g opacity="0.199997">
  <path opacity="0.199997" d="M537.125 675.095V620.329H550.117V659.014L537.125 675.095Z" fill="black"/>
  </g>
  </g>
  <path d="M551.955 620.328H519.883V614.634H551.955V620.328Z" fill="#939DF4"/>
  <path d="M469.419 902.29H309.219V792.431H469.419V902.29Z" fill="#939DF4"/>
  <g opacity="0.199997">
  <path opacity="0.199997" d="M408 902.29H309.223V792.431H408V902.29Z" fill="black"/>
  </g>
  <path d="M387.19 902.099C387.142 902.099 387.1 901.609 387.054 900.672C387.02 899.582 386.978 898.221 386.924 896.547C386.87 892.751 386.79 887.549 386.697 881.103C386.623 867.727 386.524 849.232 386.408 827.846L387.19 828.635C378.051 828.646 368.173 828.658 357.947 828.669C347.715 828.658 337.838 828.646 328.695 828.635L329.478 827.846C329.361 849.237 329.259 867.73 329.188 881.106C329.092 887.549 329.015 892.751 328.959 896.547C328.905 898.221 328.862 899.582 328.831 900.672C328.786 901.609 328.74 902.099 328.695 902.099C328.647 902.099 328.601 901.609 328.556 900.672C328.522 899.582 328.482 898.221 328.428 896.547C328.374 892.751 328.295 887.552 328.198 881.106C328.128 867.73 328.025 849.237 327.909 827.846L327.906 827.06H328.695C337.838 827.049 347.715 827.038 357.947 827.026C368.173 827.038 378.051 827.049 387.19 827.06H387.979L387.976 827.846C387.857 849.232 387.758 867.727 387.684 881.103C387.59 887.549 387.511 892.751 387.457 896.547C387.403 898.221 387.363 899.582 387.327 900.672C387.281 901.609 387.239 902.099 387.19 902.099Z" fill="#1B235E"/>
  <g opacity="0.199997">
  <path opacity="0.199997" d="M461.97 835.666H420.355V825.031H461.97V835.666Z" fill="black"/>
  </g>
  <path d="M362.367 706.2V711.852H571.449V706.143L362.367 706.2Z" fill="#939DF4"/>
  <path d="M571.449 711.853L610.553 656.76L610.672 650.487L571.58 706.201L571.449 711.853Z" fill="#939DF4"/>
  <path d="M651.957 710.65H698.788V707.003H651.957V710.65Z" fill="#939DF4"/>
  <path d="M528.708 811.242H511.43V804.648H528.708V811.242Z" fill="#1B235E"/>
  <g opacity="0.300003">
  <g opacity="0.300003">
  <path opacity="0.300003" d="M362.367 706.2V711.852H571.449V706.143L362.367 706.2Z" fill="black"/>
  </g>
  </g>
  <g opacity="0.300003">
  <g opacity="0.300003">
  <path opacity="0.300003" d="M571.449 711.853L610.553 656.76L610.672 650.487L571.58 706.201L571.449 711.853Z" fill="black"/>
  </g>
  </g>
  <g opacity="0.300003">
  <path opacity="0.300003" d="M651.957 710.65H698.788V707.003H651.957V710.65Z" fill="black"/>
  </g>
  <path d="M541.44 860.342C541.275 860.387 541.312 859.248 540.146 858.425C539.59 858.041 538.722 857.853 537.914 858.178C537.108 858.482 536.427 859.342 536.436 860.342C536.427 861.341 537.108 862.201 537.914 862.505C538.722 862.83 539.59 862.645 540.146 862.258C541.312 861.435 541.275 860.296 541.44 860.342C541.522 860.259 541.925 861.552 540.566 862.81C539.914 863.388 538.762 863.755 537.602 863.36C536.459 863.001 535.44 861.779 535.457 860.342C535.443 858.904 536.459 857.682 537.602 857.323C538.762 856.928 539.914 857.295 540.566 857.873C541.925 859.131 541.522 860.424 541.44 860.342Z" fill="#1B235E"/>
  <path d="M529.879 576.016C529.879 576.016 485.89 744.05 426.73 775.563C387.746 796.33 353.353 771.543 330.217 732.237L246.821 597.513C242.489 581.568 251.19 564.98 266.737 559.537L336.174 535.227L405.472 659.152L494.096 550.927" fill="#FF9A6C"/>
  <path d="M96.9668 587.719C98.5014 598.649 125.941 679.029 125.941 679.029C125.941 679.029 250.407 643.701 249.091 637.479C248.03 632.466 228.553 552.391 202.589 507.262C192.521 489.766 175.087 477.449 155.082 475.149C149.582 474.517 137.356 479.633 133.989 481.324C107.488 494.626 85.1037 503.273 96.9668 587.719Z" fill="#3E4E83"/>
  <path d="M292.139 469.83C339.943 483.186 342.087 504.97 366.018 543.461L403.729 612.361L340.913 652.518L329.413 678.442L323.189 901.245L70.1211 899.992L115.616 719.627L97.4187 601.089C95.7507 582.388 90.3865 547.499 96.128 529.476C114.079 473.152 181.036 467.133 222.236 458.606C230.735 456.846 238.933 459.554 245.861 461.638" fill="#3E4E83"/>
  <path d="M328.793 689.76C328.671 689.757 328.617 687.701 328.62 683.997C328.671 679.994 328.733 674.709 328.807 668.323L328.81 668.184L328.884 668.044C331.51 663.073 334.67 657.273 337.133 650.514C339.592 643.814 341.132 635.842 339.226 627.901C337.172 619.394 332.461 612.188 329.332 605.098C326.118 597.994 324.43 591.104 323.681 585.279C322.938 579.439 323.057 574.661 323.318 571.401C323.465 569.767 323.616 568.503 323.772 567.655C323.919 566.806 324.021 566.365 324.078 566.37C324.212 566.388 324.041 568.187 323.953 571.433C323.888 574.676 323.948 579.388 324.83 585.114C325.712 590.825 327.482 597.539 330.705 604.463C333.839 611.402 338.653 618.619 340.82 627.528C342.829 635.891 341.147 644.236 338.565 651.041C335.975 657.928 332.693 663.716 329.947 668.633L330.024 668.357C329.729 674.741 329.482 680.019 329.298 684.014C329.088 687.715 328.912 689.765 328.793 689.76Z" fill="#1B235E"/>
  <path d="M294.126 461.869C294.183 461.858 294.319 462.45 294.532 463.589C294.792 464.722 294.983 466.422 295.113 468.629C295.346 473.022 294.818 479.536 292.041 486.985C289.199 494.322 283.999 502.675 275.469 508.919C267.03 515.235 255.198 518.52 243.139 517.333C237.12 516.709 231.361 515.084 226.178 512.695C221.004 510.275 216.451 507.037 212.661 503.424C205.031 496.167 200.466 487.614 197.811 480.214C195.199 472.737 194.413 466.328 194.254 461.938C194.189 459.734 194.203 458.028 194.311 456.872C194.385 455.716 194.45 455.113 194.507 455.116C194.685 455.121 194.532 457.55 194.892 461.898C195.241 466.234 196.197 472.524 198.901 479.815C201.647 487.033 206.222 495.327 213.694 502.32C217.41 505.805 221.827 508.908 226.853 511.234C231.883 513.532 237.466 515.089 243.301 515.696C254.982 516.843 266.4 513.714 274.587 507.692C282.876 501.727 288.05 493.675 290.963 486.56C293.811 479.34 294.512 472.976 294.475 468.64C294.463 464.287 293.922 461.901 294.126 461.869Z" fill="#1B235E"/>
  <path d="M392.268 591.423C392.376 591.628 389.489 593.367 384.785 596.101C380.085 598.834 373.583 602.584 366.497 606.88C359.408 611.177 353.074 615.208 348.476 618.113C343.877 621.02 341.001 622.776 340.868 622.58C340.743 622.4 343.389 620.305 347.832 617.144C352.274 613.99 358.532 609.787 365.649 605.471C372.766 601.16 379.381 597.556 384.232 595.076C389.086 592.601 392.166 591.229 392.268 591.423Z" fill="#1B235E"/>
  <path d="M104.729 594.395C104.763 594.387 104.925 594.896 105.209 595.896C105.509 596.989 105.912 598.464 106.423 600.34C107.472 604.346 108.967 610.066 110.871 617.338C114.655 632.149 120.045 653.255 126.586 678.866L125.749 678.393C158.422 668.149 198.973 655.439 242.817 641.696C243.821 641.34 244.823 640.984 245.747 640.654L245.223 641.59C239.166 613.608 231.175 587.425 222.166 564.374C213.114 541.372 202.706 521.407 190.826 507.026C184.906 499.854 178.594 494.156 172.311 490.344C166.059 486.495 159.875 484.493 154.726 484.131C153.438 484.037 152.227 483.75 151.084 483.838C149.941 483.898 148.871 483.958 147.873 484.012C146.88 484.086 145.949 484.069 145.121 484.2C144.307 484.373 143.561 484.536 142.883 484.681C141.609 484.943 140.605 485.148 139.833 485.307C139.144 485.438 138.784 485.492 138.775 485.467C138.767 485.438 139.11 485.33 139.788 485.145C140.551 484.948 141.552 484.687 142.815 484.359C143.496 484.188 144.242 484.003 145.059 483.795C145.893 483.642 146.829 483.63 147.833 483.531C148.843 483.451 149.929 483.365 151.084 483.277C152.244 483.166 153.478 483.428 154.786 483.499C160.031 483.77 166.374 485.72 172.776 489.561C179.213 493.368 185.672 499.085 191.708 506.288C203.827 520.726 214.396 540.757 223.564 563.819C232.687 586.941 240.738 613.141 246.828 641.266L246.979 641.961L246.301 642.203C245.373 642.53 244.369 642.889 243.362 643.247C199.492 657.21 158.831 669.539 126.141 679.663L125.468 679.871L125.301 679.191C118.989 653.523 113.787 632.368 110.136 617.52C108.386 610.214 107.007 604.463 106.04 600.434C105.609 598.538 105.265 597.052 105.016 595.947C104.794 594.928 104.695 594.404 104.729 594.395Z" fill="#1B235E"/>
  <path d="M241.602 625.684C241.721 626.119 214.713 633.969 181.288 643.208C147.849 652.453 120.651 659.591 120.532 659.153C120.413 658.717 147.412 650.87 180.851 641.625C214.279 632.386 241.483 625.248 241.602 625.684Z" fill="#1B235E"/>
  <path d="M231.291 261.202C223.55 244.341 202.246 236.42 184.292 240.887C166.339 245.354 152.141 260.052 144.505 276.961C136.868 293.871 135.016 312.833 134.962 331.397C134.931 341.22 135.368 351.148 133.396 360.772C131.428 370.392 126.687 379.902 118.478 385.252C112.005 389.469 104.062 390.713 97.1177 394.093C90.1734 397.472 83.7766 404.602 85.4985 412.155C87.5608 421.178 98.8395 423.889 108.013 424.843C108.308 435.915 115.375 446.53 125.456 451.037C135.538 455.544 148.124 453.719 156.524 446.533C153.659 457.813 163.698 469.66 175.218 471.007C186.738 472.357 198.045 465.15 204.305 455.35C210.563 445.55 212.546 433.598 213.017 421.967C213.488 410.336 212.591 398.651 213.735 387.066" fill="#939DF4"/>
  <path d="M362.002 282.417C362.002 282.417 370.37 225.188 292.057 224.143C204.27 222.973 188.446 298.057 203.492 337.767L247.109 294.364L362.002 282.417Z" fill="#939DF4"/>
  <path d="M293.165 469.825C294.033 454.584 294.688 442.168 294.688 442.168C294.688 442.168 339.616 443.241 346.231 404.132C352.846 365.017 360.375 273.983 360.375 273.983C320.23 247.945 268.397 248.802 229.126 276.152L225.45 278.712L197.054 459.481C192.916 485.832 210.724 510.6 236.944 514.962C265.322 519.68 291.522 498.65 293.165 469.825Z" fill="#FFBF9D"/>
  <path d="M344.385 343.268C344.085 346.052 341.648 348.063 338.944 347.766C336.247 347.473 334.298 344.982 334.601 342.197C334.905 339.413 337.342 337.397 340.039 337.693C342.74 337.989 344.686 340.483 344.385 343.268Z" fill="#1B235E"/>
  <path d="M348.003 338.573C347.302 339.154 344.037 335.595 338.798 334.729C333.581 333.781 329.329 336.036 328.858 335.25C328.619 334.897 329.411 333.767 331.272 332.736C333.101 331.705 336.091 330.908 339.351 331.472C342.607 332.041 345.166 333.795 346.553 335.384C347.974 336.987 348.343 338.317 348.003 338.573Z" fill="#1B235E"/>
  <path d="M291.713 338.2C291.413 340.985 288.976 342.998 286.273 342.702C283.575 342.405 281.626 339.914 281.93 337.13C282.233 334.345 284.67 332.329 287.368 332.625C290.071 332.921 292.017 335.416 291.713 338.2Z" fill="#1B235E"/>
  <path d="M294.329 333.754C293.628 334.335 290.358 330.776 285.124 329.908C279.907 328.962 275.652 331.217 275.181 330.431C274.946 330.078 275.732 328.948 277.598 327.917C279.428 326.887 282.415 326.089 285.674 326.653C288.931 327.223 291.492 328.976 292.879 330.565C294.295 332.168 294.666 333.498 294.329 333.754Z" fill="#1B235E"/>
  <path d="M301.098 374.48C301.149 374.161 304.48 374.42 309.816 375.095C311.16 375.3 312.465 375.351 312.919 374.446C313.475 373.506 313.293 371.832 313.052 370.018C312.647 366.263 312.221 362.328 311.773 358.205C310.085 341.424 309.226 327.769 309.855 327.692C310.485 327.621 312.36 341.159 314.048 357.941C314.434 362.072 314.805 366.01 315.151 369.774C315.259 371.513 315.673 373.566 314.496 375.508C313.889 376.47 312.743 376.98 311.847 376.997C310.939 377.048 310.199 376.866 309.543 376.735C304.273 375.642 301.042 374.802 301.098 374.48Z" fill="#1B235E"/>
  <path d="M294.823 442.058C294.823 442.058 269.057 439.536 246.105 418.899C246.105 418.899 255.628 449.996 294.128 452.562L294.823 442.058Z" fill="#FF9A6C"/>
  <path d="M295.955 389.997C295.773 386.597 293.768 383.351 290.815 381.674C288.767 380.513 286.222 380.105 284.021 380.937C281.822 381.771 280.103 383.972 280.166 386.332C280.234 388.989 282.458 391.204 284.96 392.084C287.459 392.963 290.191 392.784 292.829 392.548C293.558 392.479 294.309 392.402 294.956 392.055C295.6 391.708 296.122 391.019 296.046 390.287" fill="#FF9A6C"/>
  <path d="M284.505 375.81C284.122 375.708 283.271 377.12 283.127 379.717C282.962 382.262 283.734 386.032 286.321 389.198C288.919 392.358 292.457 393.842 294.976 394.172C297.543 394.528 299.086 393.961 299.06 393.566C299.14 392.703 292.984 392.37 288.752 387.194C284.505 382.026 285.365 375.902 284.505 375.81Z" fill="#1B235E"/>
  <path d="M220.088 329.597C219.487 329.156 197.741 319.755 191.503 345.442C185.268 371.133 215.042 368.533 215.269 367.807C215.493 367.081 220.088 329.597 220.088 329.597Z" fill="#FFBF9D"/>
  <path d="M209.053 357.003C208.951 356.895 208.497 357.214 207.607 357.39C206.738 357.558 205.326 357.504 204.018 356.698C201.36 355.084 199.964 350.258 200.957 345.6C201.454 343.245 202.517 341.13 203.851 339.476C205.144 337.776 206.761 336.74 208.279 336.805C209.808 336.799 210.642 337.839 210.803 338.633C210.996 339.43 210.699 339.906 210.832 339.98C210.891 340.059 211.524 339.675 211.561 338.568C211.572 338.032 211.396 337.357 210.872 336.728C210.335 336.085 209.431 335.624 208.387 335.512C206.239 335.196 203.89 336.614 202.43 338.431C200.844 340.233 199.593 342.596 199.034 345.238C197.95 350.452 199.607 355.898 203.312 357.817C205.127 358.7 206.869 358.498 207.85 358.068C208.855 357.612 209.138 357.069 209.053 357.003Z" fill="#FF9A6C"/>
  <path d="M351.498 255.845C360.61 262.684 364.97 274.495 361.552 285.388C357.697 297.671 346.245 311.076 314.491 310.569L321.707 302.511C321.707 302.511 300.041 320.913 270.837 311.457L279.741 294.137C279.741 294.137 239.556 336.897 216.996 338.799L219.983 293.127C225.407 250.122 243.777 237.225 271.552 234.924C281.517 234.101 292.14 234.047 302.509 235.462C324.587 238.477 341.34 248.22 351.498 255.845Z" fill="#939DF4"/>
  <path d="M194.44 218.615C194.516 218.441 196.885 219.224 200.374 221.226C203.858 223.216 208.385 226.561 212.515 231.14C216.634 235.735 219.488 240.598 221.102 244.28C222.727 247.97 223.263 250.413 223.082 250.47C222.594 250.7 219.425 241.165 211.298 232.245C203.29 223.222 194.165 219.079 194.44 218.615Z" fill="#939DF4"/>
  <path d="M113.571 364.267C113.5 364.338 113.009 363.715 111.789 362.929C110.587 362.16 108.522 361.315 105.787 361.403C103.129 361.482 99.6058 362.547 97.2456 365.588C94.9082 368.606 94.1394 373.449 95.8613 377.971C97.4527 382.555 102.108 386.233 107.719 387.144C113.33 388.212 119.636 386.299 124.56 382.145C129.502 378.045 133.363 371.992 134.812 365.121C135.527 361.693 135.595 358.074 134.804 354.538C134.066 350.988 132.616 347.537 131.019 344.095C129.417 340.647 127.706 337.244 126.373 333.702C125.088 330.135 124.211 326.465 123.854 322.806C123.142 315.48 124.248 308.359 126.259 301.97C130.341 289.078 138.245 279.429 145.461 272.536C152.76 265.586 159.537 261.033 164.209 257.964C166.56 256.443 168.419 255.316 169.695 254.578C170.969 253.838 171.655 253.474 171.684 253.522C171.715 253.573 171.088 254.029 169.871 254.857C168.523 255.78 166.759 256.99 164.563 258.496C160.016 261.716 153.378 266.394 146.267 273.37C139.226 280.3 131.592 289.838 127.698 302.429C125.777 308.667 124.742 315.583 125.448 322.647C125.806 326.174 126.654 329.688 127.899 333.124C129.195 336.558 130.883 339.915 132.503 343.4C134.117 346.879 135.621 350.447 136.398 354.202C137.232 357.946 137.152 361.836 136.384 365.454C134.821 372.718 130.753 379.018 125.513 383.318C120.311 387.668 113.489 389.701 107.48 388.485C101.487 387.469 96.4287 383.386 94.7777 378.378C92.9906 373.469 93.9068 368.262 96.5223 365.027C99.1973 361.744 102.979 360.756 105.778 360.762C108.661 360.771 110.788 361.767 111.977 362.656C113.177 363.561 113.571 364.267 113.571 364.267Z" fill="#939DF4"/>
  <path d="M216.336 278.428C216.157 278.485 215.314 276.632 214.756 273.4C214.191 270.183 214.086 265.565 215.221 260.668C216.375 255.77 218.514 251.679 220.446 249.051C222.378 246.406 223.955 245.122 224.088 245.253C224.52 245.555 218.948 251.551 216.815 261.043C214.523 270.49 216.855 278.349 216.336 278.428Z" fill="#1B235E"/>
  <path d="M192.897 361.606C193.112 361.848 190.65 362.907 189.51 366.429C188.988 368.126 188.905 370.413 190.017 372.428C191.09 374.439 193.487 375.879 196.159 376.235C198.839 376.574 201.517 375.808 203.143 374.196C204.796 372.588 205.443 370.395 205.449 368.619C205.449 364.912 203.358 363.201 203.628 363.033C203.682 362.97 204.249 363.26 204.961 364.134C205.67 364.994 206.459 366.526 206.606 368.585C206.754 370.606 206.164 373.228 204.206 375.267C202.255 377.326 199.055 378.265 195.949 377.864C192.851 377.459 189.961 375.708 188.687 373.143C187.399 370.6 187.68 367.916 188.42 366.039C189.195 364.129 190.392 362.91 191.314 362.289C192.241 361.652 192.865 361.532 192.897 361.606Z" fill="#3E4E83"/>
  <path d="M368.52 295.527C368.211 295.461 369.473 291.136 369.493 284.01C369.552 276.952 367.921 266.935 362.421 257.596C356.909 248.26 348.944 242.008 342.748 238.665C336.516 235.254 332.131 234.283 332.224 233.982C332.247 233.885 333.356 234.036 335.308 234.551C337.259 235.061 340.036 236.029 343.284 237.635C349.758 240.784 358.123 247.093 363.831 256.759C369.527 266.428 371.013 276.824 370.65 284.033C370.491 287.666 369.998 290.576 369.504 292.54C369.016 294.505 368.616 295.553 368.52 295.527Z" fill="#939DF4"/>
  <path d="M296.853 323.743C296.669 323.917 295.599 322.909 293.642 321.705C291.705 320.5 288.777 319.179 285.348 318.82C281.915 318.464 278.778 319.153 276.636 319.934C274.475 320.705 273.221 321.471 273.076 321.263C272.954 321.107 273.992 319.976 276.179 318.863C278.338 317.753 281.739 316.79 285.515 317.183C289.291 317.579 292.425 319.219 294.309 320.754C296.218 322.294 297.004 323.615 296.853 323.743Z" fill="#939DF4"/>
  <path d="M346.872 328.551C346.838 329.003 342.297 329.043 336.728 328.642C331.157 328.24 326.669 327.546 326.703 327.093C326.735 326.64 331.276 326.6 336.847 327.002C342.416 327.406 346.903 328.098 346.872 328.551Z" fill="#939DF4"/>
  <path d="M529.879 576.016C529.879 576.016 545.968 537.661 524.293 541.801C490.965 548.168 495.921 580.72 495.921 580.72L529.879 576.016Z" fill="#FF9A6C"/>
  <path d="M560.855 404.001C558.036 402.976 555.114 402.454 552.141 402.321C570.248 415.811 575.141 450.538 562.481 485.616C550.11 519.891 525.087 543.095 502.941 542.947L501.18 542.48C483.564 528.674 478.897 494.365 491.416 459.683C504.005 424.797 529.711 401.318 552.141 402.321C549.852 400.615 547.381 399.197 544.68 398.215C520.588 389.454 489.498 414.384 475.238 453.895C461.383 492.287 468.58 530.212 491.101 540.365L490.658 540.299L446.287 654.068C444.394 658.925 446.618 664.418 451.353 666.57C456.442 668.885 462.425 666.448 464.47 661.229L509.219 547.079L509.165 547.064L509.168 547.059C509.185 547.064 509.202 547.073 509.219 547.079C533.311 555.842 564.398 530.912 578.659 491.401C592.919 451.891 584.948 412.759 560.855 404.001Z" fill="#1B235E"/>
  <path d="M540.83 507.002C540.728 506.951 541.218 505.723 542.18 503.54C543.3 501.051 544.682 497.976 546.319 494.34C549.745 486.576 554.525 475.762 557.274 463.132C560.02 450.507 559.41 438.586 557.271 430.432C555.212 422.218 552.267 417.728 552.576 417.574C552.653 417.525 553.442 418.593 554.602 420.697C555.773 422.79 557.192 425.993 558.392 430.133C560.868 438.381 561.677 450.61 558.877 463.476C556.074 476.334 551.07 487.171 547.368 494.836C545.49 498.668 543.89 501.726 542.753 503.824C541.613 505.92 540.932 507.053 540.83 507.002Z" fill="#1B235E"/>
  <path d="M536.168 516.127C536.489 516.449 535.11 518.351 533.093 520.375C531.076 522.402 529.179 523.783 528.858 523.464C528.54 523.143 529.916 521.241 531.936 519.214C533.953 517.189 535.848 515.808 536.168 516.127Z" fill="#1B235E"/>
  <path d="M487.785 558.885C484.335 552.365 480.344 543.371 474.296 539.163C468.251 534.96 459.872 530.063 452.766 536.393C461.69 549.162 469.959 562.393 477.536 576.017L487.785 558.885Z" fill="#FF9A6C"/>
  <path d="M535.036 547.358C535.036 547.358 533.705 539.773 522.654 543.098C511.605 546.426 476.926 553.695 483.615 561.104C490.301 568.518 502.124 565.172 502.124 565.172C502.124 565.172 482.877 569.346 484.718 573.412C486.559 577.478 489.779 584.442 497.977 580.066C506.178 575.687 528.917 568.236 528.917 568.236L535.036 547.358Z" fill="#FF9A6C"/>
  <path d="M533.785 564.255C533.643 564.209 533.918 563.537 533.861 562.367C533.847 561.254 533.101 559.451 531.246 558.811C529.362 558.15 526.858 559.167 524.015 559.912C521.105 560.695 518.027 561.524 514.796 562.392C508.24 564.106 502.286 565.573 497.952 566.552C493.617 567.532 490.908 568.03 490.849 567.811C490.792 567.591 493.393 566.697 497.648 565.43C501.906 564.163 507.815 562.521 514.368 560.804C517.613 559.981 520.702 559.195 523.621 558.449C526.392 557.84 529.17 556.746 531.637 557.714C534.071 558.759 534.678 561.114 534.5 562.381C534.344 563.716 533.847 564.292 533.785 564.255Z" fill="#1B235E"/>
  <path d="M237.969 644.872L271.445 707.155L437.639 623.173C437.639 623.173 462.088 576.018 466.145 576.624C470.201 577.231 488.178 584.978 488.178 584.978C488.178 584.978 513.821 571.141 519.214 574.907C524.606 578.674 532.149 624.224 527.313 638.312C522.479 652.4 472.031 680.787 472.031 680.787C472.031 680.787 335.844 770.553 276.681 802.066C237.699 822.834 203.307 798.046 180.171 758.74L140.852 675.121" fill="#FFBF9D"/>
  </g>
  <defs>
  <clipPath id="clip0_1614_3501">
  <rect width="1582" height="789" fill="white" transform="translate(-174 86)"/>
  </clipPath>
  <clipPath id="clip1_1614_3501">
  <rect width="1053" height="901" fill="white" transform="translate(-113)"/>
  </clipPath>
  </defs>
  </svg>
);

export default ImageSignUpStart;
