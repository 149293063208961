import React, {useEffect, useRef, useState} from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import _ from "lodash";
import classNames from "classnames";
import { CustomSlider } from "components/Controls/Sliders";
import { NumberInput } from "components/Controls/Inputs";
import { normalizeMinMax, normalizeIdeal, parseNumberToInt, BATH_BED_SLIDER_DIFF , absValue, normalizeBathroomIdeal} from "utils/helpers/criteriaHelper";
import RangeHistogram from "../../DataDisplay/Histogram";
import "../styles.scss";
import {CRITERIA_MAP} from "../../../containers/Dashboard/constants";
import {Tooltip} from "antd";
import {IconQuestion} from "../../Svgs";

const DEFAULT_BOTTOM_VALUES = ['0','1','2','3','4','5','6','6+'];
const TOOLTIP_MIN_MESSAGES = {
  [CRITERIA_MAP.bathrooms]: "dashboard.form.bathroomsTooltipMinMessage",
  [CRITERIA_MAP.bedrooms]: "dashboard.form.bedroomsTooltipMinMessage",
}
const TOOLTIP_IDEAL_MESSAGES = {
  [CRITERIA_MAP.bathrooms]: "dashboard.form.bathroomsTooltipIdealMessage",
  [CRITERIA_MAP.bedrooms]: "dashboard.form.bedroomsTooltipIdealMessage",
}


const SliderMinIdealInputField = (props) => {
  const {
    dataKind,
    form,
    value,
    min,
    max,
    step,
    kind,
    histogramSteps,
    minMessage,
    idealMessage,
    maxInputValue,
    withoutHistogram,
    invertTrack,
    isFloat,
    bottomValues,
  } = props;
  const [sliderValue, setSliderValue] = useState([value.min, value.ideal]);
  const [minValue, setMinValue] = useState(value.min,);
  const [idealValue, setIdealValue] = useState(value.ideal);
  const sliderTrackRef = useRef(null);

  useEffect(() => {
    const sliderTrack = sliderTrackRef.current.children[0].children[0].children[1];
    if (invertTrack) {
      // add a color stripe to the left before the slider
      const rightTrack = sliderTrackRef.current.children[0].children[0].children[3];
      sliderTrack.style.left = `0%`
      sliderTrack.style.width = rightTrack.style.left
    } else {
      // add a colored stripe on the right to the end of the track
      sliderTrack.style.width = `${100 - parseFloat(sliderTrack.style.left)}%`
    }
  }, [sliderValue])

  const getValue = inputValue => {
    inputValue = absValue(inputValue)
    if (isFloat && !_.isInteger(inputValue)) {
      const floatValue = parseFloat(inputValue);
      const fixedValue = parseFloat(floatValue.toFixed(0));
      return fixedValue - floatValue === 0.5 ? floatValue : Math.round(floatValue / 0.5) * 0.5;
    }
    return parseInt(inputValue) || 0;
  }

  const onChangeMinInput = (inputValue) => {
    const value = getValue(inputValue);
    let [min, ideal,max] = [value, idealValue, idealValue + BATH_BED_SLIDER_DIFF];

    [min, ideal,] = normalizeMinMax(value, idealValue, max, BATH_BED_SLIDER_DIFF);
    setSliderValue([min, ideal]);
  };

  const onBlurMinInput = (inputValue) => {
    const value = getValue(inputValue);
    const [min, ideal,] = normalizeMinMax(value, idealValue, idealValue + BATH_BED_SLIDER_DIFF, BATH_BED_SLIDER_DIFF);
    changeFormValue(min, ideal);
  };

  const onChangeIdealInput = (inputValue) => {
    const value = getValue(inputValue);
    let [min, ideal, max] = [minValue, value, value + BATH_BED_SLIDER_DIFF];

    [min, ideal, ] = normalizeIdeal(minValue, value, max, BATH_BED_SLIDER_DIFF);
    setSliderValue([min, ideal]);
  };

  const onBlurIdealInput = (inputValue) => {
    const value = getValue(inputValue);
    const normalizeMethod = kind === 'bathrooms' ? normalizeBathroomIdeal : normalizeIdeal;
    const [min, ideal,] = normalizeMethod(minValue, value, value + BATH_BED_SLIDER_DIFF, BATH_BED_SLIDER_DIFF);
    changeFormValue(min, ideal);
  };

  const changeFormValue = (min, ideal) => {
    setSliderValue([min, ideal]);
    form.setFieldsValue({
      range: { min, ideal },
    });
    setMinValue(min);
    setIdealValue(ideal);
  };

  const onChangeSlider = (_, value) => {
    setMinValue(value[0]);
    setIdealValue(value[1]);
    setSliderValue(value);
    form.setFieldsValue({
      range: { min: value[0], ideal: value[1] },
    });
  };

  const renderHistogram = () => (
    <div className="select-range__histogram">
      <RangeHistogram
        dataKind={dataKind}
        kind={kind}
        steps={histogramSteps}
        minValue={value.min}
        maxValue={invertTrack ? value.ideal : max}
        min={min}
        max={max}
        invertHistogram={invertTrack}
        bottomValues={bottomValues || DEFAULT_BOTTOM_VALUES}
      />
    </div>
  )

  const renderIconWithTooltip = (tooltipMessageId) => {
    if ([CRITERIA_MAP.bathrooms, CRITERIA_MAP.bedrooms].includes(kind) && tooltipMessageId) {
      return (
        <Tooltip title={<FormattedMessage id={tooltipMessageId}/>} placement="rightTop">
          <div className="input-number-icon">
            <IconQuestion/>
          </div>
        </Tooltip>
      )
    }
  }

  const renderCustomInput = (message, currentValue, defaultValue, onBlur, onChange, tooltipMessageId, isStar) => (
    <>
      <div className="input-container__title">
        {message}
        {renderIconWithTooltip(tooltipMessageId)}
      </div>
      <div className={classNames("input-container__field", { "input-star": isStar })}>
        <NumberInput
          value={currentValue}
          defaultValue={defaultValue}
          onChange={(value) => onChange(value)}
          onBlur={event => onBlur(parseNumberToInt(event.target.value, maxInputValue, isFloat))}
          maxInputValue={maxInputValue}
          isFloat={isFloat}
        />
      </div>
    </>
  );

  return (
    <div className="select-range">
      {!withoutHistogram && renderHistogram()}
      <div className="select-range-field">
        <div ref={sliderTrackRef}>
          <CustomSlider
            track
            step={step}
            min={min}
            max={max}
            defaultValue={[value.min, value.ideal]}
            value={sliderValue}
            onChange={onChangeSlider}
            withPadding
            invertedSlider={invertTrack}
          />
        </div>
        <div className="select-range-field__inputs">
          <div className="input-container">
          {renderCustomInput(minMessage, minValue, value.min, onBlurMinInput, onChangeMinInput, TOOLTIP_MIN_MESSAGES[kind], false)}
          </div>
          <span className="dash">&ndash;</span>
          <div className="input-container">
          {renderCustomInput(idealMessage, idealValue, value.ideal, onBlurIdealInput, onChangeIdealInput, TOOLTIP_IDEAL_MESSAGES[kind], true)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(SliderMinIdealInputField)
