import React from "react";
import { Button } from "antd";
import "../styles.scss";
import classNames from "classnames";

const SwitchButton = props => {
  const { icon, ...otherProps } = props;

  return (
    <Button
        className={classNames("switch-button", {"active": props.isActive })}
        icon={icon}
        {...otherProps}
    >
      <span className="button__text">{props.children}</span>
    </Button>
  );
};

export default SwitchButton;