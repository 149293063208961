import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SimpleButton } from "components/Controls/Buttons";
import { DASHBOARD_PATH } from "constants/paths";
import { HOUSE_VIEWS } from "constants/houses";
import { USER_KEY } from "constants/localStorage";
import { emptyFavorites, emptyFavoritesNonAuth, emptyTopMatches } from "images";
import { AccountButtonView } from "../Views";
import "./styles.scss";

const EMPTY_PAGE_DATA_MAP = {
  [HOUSE_VIEWS.favorites]: {
    auth: {
      description: "houses.list.emptyFavoritesDescription",
      image: emptyFavorites,
      imageAlt: "Empty Favorites for authenticated users",
      message: "houses.list.emptyFavoritesMessage",
      title: "houses.favoritesTitle",
    },
    non_auth: {
      description: "houses.list.nonAuthEmptyFavoritesDescription",
      image: emptyFavoritesNonAuth,
      imageAlt: "Empty Favorites for non-auth users",
      message: "houses.list.nonAuthEmptyFavoritesTitle",
      title: "houses.favoritesTitle",
    },
  },
  [HOUSE_VIEWS.topMatches]: {
    description: "houses.list.emptyPageDescription",
    image: emptyTopMatches,
    imageAlt: "Empty Top matches results",
    message: "general.oopsWeSorry",
    title: "houses.topHomeMatchesTitle",
  },
};

const EmptyPage = ({ view }) => {
  const userType = _.isNull(localStorage.getItem(USER_KEY)) ? "non_auth" : "auth";
  const data = view === HOUSE_VIEWS.favorites ? EMPTY_PAGE_DATA_MAP[view][userType] : EMPTY_PAGE_DATA_MAP[view];
  const history = useHistory();
  const isShowBottomButton = view === HOUSE_VIEWS.favorites && userType === "non_auth";

  const renderButton = () => view === HOUSE_VIEWS.topMatches  && (
    <SimpleButton
      customClass="empty-button"
      onClick={(e) => {
        e.preventDefault();
        history.push(DASHBOARD_PATH);
      }}
    >
      <FormattedMessage id="general.EditCriteriaMessage" />
    </SimpleButton>
  );

  return (
    <div className="list-view-wrapper">
      <div className="favorites-title main-header__title fs-24 bold">
        <FormattedMessage id={data.title} />
      </div>
      <div className="empty-top-matches">
        <div className="empty-top-matches__messages">
          {data.message && (
            <div className="empty-top-matches__messages-title">
              <FormattedMessage id={data.message} />
            </div>
          )}
          <div className="empty-top-matches__messages-description">
            <FormattedMessage id={data.description} />
            {renderButton()}
          </div>
        </div>
        <div className={classNames("empty-top-matches__image", { withBottomSpace: isShowBottomButton })}>
          <img src={data.image} alt={data.imageAlt} />
        </div>
        {isShowBottomButton && <AccountButtonView maxSize />}
      </div>
    </div>
  );
};

EmptyPage.propTypes = {
  view: PropTypes.string,
};

export default EmptyPage;
