import _ from "lodash";
import { CRITERIA_MAP } from "containers/Dashboard/constants";
import { getUpdatedData } from "./criteriaHelper";
import {LAST_SAVED_ONBOARDING_STEP_KEY, ONBOARDING_DATA_KEY} from "../../constants/localStorage";
import {localStorageStrToNumberOrNull, localStorageStrToObjectOrNull} from "./common";

const BASIC_CRITERIA_KEYS = [
  CRITERIA_MAP.bathrooms,
  CRITERIA_MAP.bedrooms,
  CRITERIA_MAP.price,
  CRITERIA_MAP.prop_type,
];

const OTHER_CRITERIA_KEYS = [
  CRITERIA_MAP.commute,
  CRITERIA_MAP.custom_distance,
  CRITERIA_MAP.grocery,
  CRITERIA_MAP.parks,
  CRITERIA_MAP.school_quality,
  CRITERIA_MAP.crime,
  CRITERIA_MAP.transit,
  CRITERIA_MAP.walkability,
  CRITERIA_MAP.quietness,
  CRITERIA_MAP.garage,
  CRITERIA_MAP.parking,
  CRITERIA_MAP.central_ac,
  CRITERIA_MAP.basement,
  CRITERIA_MAP.bikeability,
  CRITERIA_MAP.single_story,
  CRITERIA_MAP.solar_power,
  CRITERIA_MAP.newly_built,
  CRITERIA_MAP.building_size,
  CRITERIA_MAP.lot_size,
];

const calculateBasicCriteriaSubStep = props => {
  const { initialSubStepNumber, mode, onboardingData } = props;
  let subStepNumber = initialSubStepNumber;
  if (mode === "required") {
    const numberOfRequiredCriteria =
      Object.keys(onboardingData).filter(key => BASIC_CRITERIA_KEYS.includes(key)).length;
    subStepNumber += numberOfRequiredCriteria;
    return subStepNumber;
  }
  if (mode === "additional") {
    const isAnyCriteriaFilled = Object.keys(onboardingData).some(key => OTHER_CRITERIA_KEYS.includes(key));
    if (isAnyCriteriaFilled) {
      return initialSubStepNumber + 1;
    }
  }
  return subStepNumber;
};

const calculatePriorityCriteriaSubStep = props => {
  const { onboardingData } = props;
  const criteriaWithFilledPriority =
      Object.keys(onboardingData).filter(key => _.has(onboardingData[key], "priority") && onboardingData[key].priority > 0);
  return criteriaWithFilledPriority.length;
};

const calculateProgressSubStepNumber = props => {
  const method = PROGRESS_SUB_STEP_METHOD[props.currentStepNumber];
  return method ? method(props) : props.initialSubStepNumber;
};

const checkIsButtonFilled = (criteria, stepData) => {
  if (criteria.key === CRITERIA_MAP.prop_type) {
    return (
      !_.isNil(stepData[criteria.key]) &&
      !_.isEmpty(stepData[criteria.key]) &&
      !_.isEmpty(stepData.prop_type.values)
    );
  }
  return !_.isNil(stepData[criteria.key]);
};

const DATA_KEYS_BY_STEP = {
  0: ["first_name", "last_name", "email"],
  1: ["timeline"],
  2: ["locations"],
  3: BASIC_CRITERIA_KEYS,
  4: OTHER_CRITERIA_KEYS,
  6: _.concat(BASIC_CRITERIA_KEYS, OTHER_CRITERIA_KEYS),
};

const formatOnboardingData = (newData, previousData) => {
  const data = _.cloneDeep(previousData);
  let kind;
  if (_.isPlainObject(newData)) {
    if (_.has(newData, "kind")) {
      kind = newData.kind;
    } else {
      return _.assign(data, newData);
    }
  }
  if (_.isArray(newData) && !_.isEmpty(newData)) {
    kind = _.has(newData[0], "kind") ? newData[0].kind : null;
  }
  if (kind) {
    data[kind] = getUpdatedData(previousData, newData);
    return data;
  }
  return {};
};

const formatStringValue = value => _.isNil(value) ? '' : value.trim();

const getOnboardingDataFromStorage = () => {
  let onboardingData = localStorageStrToObjectOrNull(ONBOARDING_DATA_KEY);
  onboardingData = onboardingData || {};
  onboardingData.step = localStorageStrToNumberOrNull(LAST_SAVED_ONBOARDING_STEP_KEY);
  return onboardingData;
};

const getPriorityCriteria = data => _.filter(data, item => item.kind && item.kind !== "prop_type");

const getStepsNumber = (onboardingData, step) => {
  if (step === 2) {
    const stepData = getPriorityCriteria(onboardingData);
    return stepData.length;
  }
  return 1;
};

const handleActiveCriteria = (activeCriteria, criteriaKey, setActiveCriteria) => {
  const newActiveCriteriaValue = criteriaKey === activeCriteria ? null : criteriaKey;
  setActiveCriteria(newActiveCriteriaValue, criteriaKey);
};

const isDisabledBasicCriteriaStep = data => {
  let isDisabled = !_.isObject(data) || _.isEmpty(data);
  if (isDisabled) {
    return isDisabled;
  }
  isDisabled = ["bathrooms", "bedrooms", "price", "prop_type"].some(key => {
    const criteriaData = data[key];
    return _.isNil(criteriaData) || !_.isObject(criteriaData) || _.isEmpty(criteriaData);
  });
  return isDisabled;
};

const isDisabledLocationsStep = data => (
  !_.isObject(data) ||
  _.isEmpty(data) ||
  !_.isObject(data.locations) ||
  _.isEmpty(data.locations)
);

const isDisabledPriorityStep = data => {
  data = _.filter(data, item => item.kind && item.kind !== "prop_type");
  let isDisabled = !_.isObject(data) || _.isEmpty(data);
  if (isDisabled) {
    return isDisabled;
  }
  for (let criteria in data) {
    if (!data[criteria].priority) {
      isDisabled = true;
    }
  }
  return isDisabled;
};

let regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const isDisabledWelcomeStep = data => {
  let isDisabled = true;
  if (_.isObject(data) && !_.isEmpty(data)) {
    const requiredFields = ["first_name", "last_name", "email"];
    isDisabled = requiredFields.some(field => formatStringValue(data[field]) === '');    
    const emailIsValid = regex.test(data['email'])
    isDisabled = isDisabled || !emailIsValid
  }
  return isDisabled;
};

const isDisabledTimelineStep = data => {
  let isDisabled = true;
  if (_.isObject(data) && !_.isEmpty(data)) {
    const requiredFields = ["timeline"];
    isDisabled = requiredFields.some(field => formatStringValue(data[field]) === '');
  }
  return isDisabled;
}

const PROGRESS_SUB_STEP_METHOD = {
  1: calculateBasicCriteriaSubStep,
  2: calculatePriorityCriteriaSubStep,
};

const processDataFromStorageToSave = (allData, stepDataToProcess, dataKeysByStep) => {
  let onboardingData = _.cloneDeep(allData);
  const stepData = stepDataToProcess.data;
  onboardingData = _.omit(onboardingData, dataKeysByStep[stepDataToProcess.step]);
  _.assign(onboardingData, stepData);
  const data = {
    data: onboardingData,
    is_finished: stepDataToProcess.step === 6,
    step: stepDataToProcess.step,
  };
  localStorage.setItem(ONBOARDING_DATA_KEY, JSON.stringify(data))
  let relevantOnboardingStep = localStorageStrToNumberOrNull(LAST_SAVED_ONBOARDING_STEP_KEY) || 0;
  relevantOnboardingStep = stepDataToProcess.step > relevantOnboardingStep ? stepDataToProcess.step : relevantOnboardingStep;
  localStorage.setItem(LAST_SAVED_ONBOARDING_STEP_KEY, relevantOnboardingStep.toString());
  return data;
};

export {
  BASIC_CRITERIA_KEYS,
  DATA_KEYS_BY_STEP,
  OTHER_CRITERIA_KEYS,
  calculateProgressSubStepNumber,
  checkIsButtonFilled,
  formatOnboardingData,
  getOnboardingDataFromStorage,
  getPriorityCriteria,
  getStepsNumber,
  handleActiveCriteria,
  isDisabledBasicCriteriaStep,
  isDisabledLocationsStep,
  isDisabledPriorityStep,
  isDisabledWelcomeStep,
  isDisabledTimelineStep,
  processDataFromStorageToSave,
};
