// [Common]
export const HELP = '/help/';
export const NOT_FOUND_PATH = '*';

// [House properties]
export const DASHBOARD_PATH = '/';
export const FAVORITES = '/favorites/';
export const HOUSE_PATH = '/houses/:id/';
export const RECENTLY_ADDED = '/recently_added/';
export const TOP_MATCHES = '/top_matches/';
export const SUCCESS = '/success/';

export const HOUSE_DETAILS_LOCATION = id => `/houses/${id}/`;

// [Onboarding]
export const ONBOARDING_PATH = '/';

// [Users]
export const EMAIL_CONFIRM = '/email_confirm/';
export const LOGIN_PATH = '/login/';
export const SIGN_UP = '/sign_up/';
export const PROFILE = '/profile/';
